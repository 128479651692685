import { useSelector, useDispatch } from 'react-redux';
import { clearNotification, resetNotifications } from '../../../../redux/notificationSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { sendMessage } from './../../../../Services/firebaseService';
import { useAuth } from '../../../../Services/context/AuthContext';
import { db } from './../../../../firebase/firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import './NotificationPopup.css';

const NotificationPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const notifications = useSelector((state) => state.notifications.notifications);
  const [isMinimized, setIsMinimized] = useState(location.pathname === '/map'); // Минимизируем на карте
  const [messageInputs, setMessageInputs] = useState({});
  const [chatHistories, setChatHistories] = useState({});

  useEffect(() => {
    const unsubscribers = notifications.map((notification) => {
      const messagesRef = collection(db, 'messages', notification.chatId, 'chatMessages');
      const q = query(messagesRef, orderBy("createdAt"));
      return onSnapshot(q, (snapshot) => {
        const history = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChatHistories(prev => ({
          ...prev,
          [notification.chatId]: history,
        }));
      }, (error) => {
        console.error(`Ошибка загрузки истории чата ${notification.chatId}:`, error);
      });
    });

    return () => unsubscribers.forEach(unsub => unsub && unsub());
  }, [notifications]);

  const handleNotificationClick = (notification) => {
    navigate('/chat');
    dispatch(clearNotification(notification.id));
  };

  const handleSendMessage = (chatId, notificationId) => {
    const text = messageInputs[notificationId] || '';
    if (text.trim() && currentUser) {
      sendMessage(chatId, text, currentUser.uid).then(() => {
        setMessageInputs(prev => ({ ...prev, [notificationId]: '' }));
      });
    }
  };

  const handleInputChange = (notificationId, value) => {
    setMessageInputs(prev => ({ ...prev, [notificationId]: value }));
  };

  if (!notifications.length) return null;

  const isMapPage = location.pathname === '/map';

  return (
    <div
      className={`notification-popup-container ${isMapPage ? 'map-mode' : ''}`}
      onClick={(e) => e.stopPropagation()} // Предотвращаем перехват кликов
    >
      {!isMinimized && notifications.map((notification) => (
        <div key={notification.id} className="notification-popup">
          <div className="notification-content" onClick={() => handleNotificationClick(notification)}>
            <p><strong>{notification.senderName || notification.sender}</strong>: {notification.text}</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                dispatch(clearNotification(notification.id));
              }}
              className="notification-close"
            >
              ✕
            </button>
          </div>
          <div className="notification-history">
            {(chatHistories[notification.chatId] || []).map((msg) => (
              <div
                key={msg.id}
                className={`mini-message ${msg.sender === currentUser?.uid ? 'sent' : 'received'}`}
              >
                <p>{msg.text}</p>
              </div>
            ))}
          </div>
          <div className="notification-input">
            <input
              type="text"
              value={messageInputs[notification.id] || ''}
              onChange={(e) => handleInputChange(notification.id, e.target.value)}
              placeholder="Ответить..."
              onClick={(e) => e.stopPropagation()}
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleSendMessage(notification.chatId, notification.id);
              }}
            >
              Отправить
            </button>
          </div>
        </div>
      ))}
      {notifications.length > 0 && (
        <div className="notification-controls">
          <button onClick={() => setIsMinimized(!isMinimized)}>
            {isMinimized ? 'Развернуть' : 'Свернуть'}
          </button>
          <button onClick={() => dispatch(resetNotifications())}>
            Закрыть все
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;