import { useState, useEffect } from "react";
import { db, auth } from '../../../firebase/firebase';
import { collection, query, onSnapshot, doc, updateDoc, getDocs, orderBy } from "firebase/firestore";
import Avatar from "../Shared/Avatar";
import "../css/ChatHistory.css";

const ChatHistory = ({ onSelectChat, chats }) => {
  const [loading, setLoading] = useState(true);
  const [userProfiles, setUserProfiles] = useState({});
  const [lastMessages, setLastMessages] = useState({});
  const [messageStats, setMessageStats] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [activeChats, setActiveChats] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (!auth.currentUser) {
      setLoading(false);
      return;
    }

    const fetchUserProfiles = async () => {
      try {
        const usersRef = collection(db, "users");
        const snapshot = await getDocs(usersRef);
        const profiles = {};
        const usersList = [];
        snapshot.docs.forEach(doc => {
          if (doc.id !== auth.currentUser.uid) {
            profiles[doc.id] = {
              name: doc.data().name || "Неизвестный пользователь",
              avatars: doc.data().avatars || [],
              selectedAvatarIndex: doc.data().selectedAvatarIndex || 0,
              avatar: doc.data().avatar || null,
            };
            usersList.push({
              uid: doc.id,
              name: profiles[doc.id].name,
              avatar: profiles[doc.id].avatars?.[profiles[doc.id].selectedAvatarIndex] || profiles[doc.id].avatar,
            });
          }
        });
        setUserProfiles(profiles);
        setAllUsers(usersList);
      } catch (error) {
        console.error("Ошибка загрузки профилей пользователей:", error);
      }
    };

    const chatList = chats || [];
    const unsubscribeMessages = chatList.map(chat => {
      const messagesRef = collection(db, "messages", chat.id, "chatMessages");
      return onSnapshot(query(messagesRef, orderBy("createdAt", "desc")), (msgSnapshot) => {
        const messages = msgSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (messages.length > 0) {
          setActiveChats(prev => {
            if (!prev.some(c => c.id === chat.id)) {
              return [...prev, chat];
            }
            return prev;
          });

          const lastMessage = messages[0] || { text: "Нет сообщений", createdAt: null };
          setLastMessages(prev => ({
            ...prev,
            [chat.id]: lastMessage,
          }));

          const totalMessages = messages.length;
          const unreadCount = messages.filter(msg => !msg.isRead && msg.sender !== auth.currentUser.uid).length;

          setMessageStats(prev => ({
            ...prev,
            [chat.id]: { total: totalMessages, unread: unreadCount },
          }));

          if (!chat.pinned) {
            updateDoc(doc(db, "chats", chat.id), { pinned: true });
          }
        } else {
          setMessageStats(prev => ({
            ...prev,
            [chat.id]: { total: 0, unread: 0 },
          }));
        }
      }, (error) => {
        console.error(`Ошибка подписки на сообщения чата ${chat.id}:`, error);
      });
    });

    fetchUserProfiles();
    setLoading(false);

    return () => unsubscribeMessages.forEach(unsub => unsub());
  }, [chats]);

  const filteredUsers = allUsers.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedChats = [...activeChats].sort((a, b) => {
    if (a.pinned && !b.pinned) return -1;
    if (!a.pinned && b.pinned) return 1;
    const aLastMsg = lastMessages[a.id];
    const bLastMsg = lastMessages[b.id];
    const aTime = aLastMsg?.createdAt?.toDate() || new Date(0);
    const bTime = bLastMsg?.createdAt?.toDate() || new Date(0);
    return bTime - aTime;
  });

  if (loading) return <div>Загрузка...</div>;

  return (
    <div className="chat-history">
      <div className="user-search">
        <input
          type="text"
          placeholder="Поиск пользователей..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {searchQuery === "" ? (
        <>
          <h3>Чаты</h3>
          <ul>
            {sortedChats.map(({ id: chatId, users, pinned }) => {
              const otherUserId = users.find(uid => uid !== auth.currentUser.uid);
              const userProfile = userProfiles[otherUserId] || {};
              const userName = userProfile.name || otherUserId;
              const stats = messageStats[chatId] || { total: 0, unread: 0 };
              const lastMessage = lastMessages[chatId] || { text: "Нет сообщений" };
              const avatarUrl = userProfile.avatars?.[userProfile.selectedAvatarIndex] || userProfile.avatar;

              return (
                <li
                  key={chatId}
                  className={`chat-history-item ${pinned ? 'pinned' : ''}`}
                  onClick={() => onSelectChat({ uid: otherUserId, name: userName, avatar: avatarUrl }, chatId)}
                >
                  <Avatar avatar={avatarUrl} userId={otherUserId} />
                  <div className="chat-info">
                    <span className="chat-name">{userName}</span>
                    <span className="last-message">{lastMessage.text}</span>
                    {stats.unread > 0 && (
                      <span className="unread-count">{stats.unread}</span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <>
          <h3>Пользователи</h3>
          <ul>
            {filteredUsers.map(user => {
              const existingChat = sortedChats.find(chat =>
                chat.users.includes(user.uid) && chat.users.includes(auth.currentUser.uid)
              );
              const chatId = existingChat ? existingChat.id : null;
              const stats = chatId ? messageStats[chatId] : { total: 0, unread: 0 };
              const lastMessage = chatId ? lastMessages[chatId] : { text: "Начать чат" };

              return (
                <li
                  key={user.uid}
                  className={`chat-history-item ${existingChat?.pinned ? 'pinned' : ''}`}
                  onClick={() => onSelectChat({ uid: user.uid, name: user.name, avatar: user.avatar }, chatId)}
                >
                  <Avatar avatar={user.avatar} userId={user.uid} />
                  <div className="chat-info">
                    <span className="chat-name">{user.name}</span>
                    <span className="last-message">{lastMessage.text}</span>
                    {stats.unread > 0 && (
                      <span className="unread-count">{stats.unread}</span>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default ChatHistory;