import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase/firebase'; // Исправленный путь
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { updateChats } from '../../redux/chatSlice';
import debounce from 'lodash/debounce';

const useChatData = (userId) => {
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chat.chats);

  useEffect(() => {
    if (!userId) return;

    const chatsRef = collection(db, 'chats');
    const q = query(chatsRef, where('users', 'array-contains', userId));

    const debouncedUpdate = debounce((snapshot) => {
      const chatData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(updateChats(chatData));
    }, 300);

    const unsubscribe = onSnapshot(q, debouncedUpdate, (error) => {
      console.error('Ошибка загрузки чатов:', error);
    });

    return () => {
      unsubscribe();
      debouncedUpdate.cancel();
    };
  }, [userId, dispatch]);

  return useMemo(() => ({ chats }), [chats]);
};

export default useChatData;