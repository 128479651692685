import './css/UserProfileBase.css';

const ProfileInfo = ({ profileData }) => {
  return (
    <div className="user-profile-info">
      <p>{profileData.bio || 'О себе не указано'}</p>
    </div>
  );
};

export default ProfileInfo;