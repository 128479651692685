import React, {useContext, useState} from 'react';
import DatePicker from '../../ProductListFlat/DatePicker/DatePickerFlat';
import Slider from 'react-slick'; // Импортируем слайдер
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../ProductListCars/DatePicker/DatePicker.css';
import './FlatCard.css';
import calendarsvg from '../../ProductListCars/ProductListCarsImg/calendar-days (1) 1.svg';
import rooms from "../../ProductListFlat/ProductListCarsImg/rooms.svg";
import floor from "../../ProductListFlat/ProductListCarsImg/floor.svg";
import bedroom from "../../ProductListFlat/ProductListCarsImg/bedrooms.svg";
import square from "../../ProductListFlat/ProductListCarsImg/square.svg";
import bathrooms from "../../ProductListFlat/ProductListCarsImg/bathrooms.svg";
import elevator from "../../ProductListFlat/ProductListCarsImg/elevator.svg";
import gas from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import heating from "../../ProductListFlat/ProductListCarsImg/heating.svg";
import pets from "../../ProductListFlat/ProductListCarsImg/pets.svg";
import balcony from "../../ProductListFlat/ProductListCarsImg/gas.svg";
import {LocalizationContext} from "../../../Services/localization/LocalizationContext";
import bluet from "../../ProductListCars/ProductListCarsImg/Frame 7.svg";
import view from "../../ProductListFlat/ProductListCarsImg/view.svg";
import DatePickerFlat from "../../ProductListFlat/DatePicker/DatePickerFlat";
import LikeComponent from "../../LikeComponent/LikeComponent";
import { useNavigate } from 'react-router-dom';


// Настройки для слайдера
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, isOpenCard) => {
    const isActive = feature === "Есть";
    const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };
    const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' };

    return (
        <div className="Bluetooth bluetflats">
            <img
                src={imgSrc}
                style={isActive ? activeStyle : inactiveStyle}
                alt={altText}
            />

        </div>
    );
};
const renderFeatureIconUp = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };

    return (
        <div className="Bluetooth bluetflats">
            <img
                src={imgSrc}
                style={activeStyle}
                alt={altText}
            />

        </div>
    );
};


const FlatCard = ({ flat, isDisableLoginFormTempFlag = false }) => {
    const { translations } = useContext(LocalizationContext);
    const [isOpenCard, setIsOpenCard] = useState(false);
    const flatCardClasses = `flat-card ${isOpenCard ? 'card-opened': ''}`;
    const navigate = useNavigate();
     const handleFlatClick = (flatId) => {
        navigate(`/flat/${flatId}`); // Переход на страницу с деталями квартиры
    };


    console.log("translations:", translations);

    return (
        <div key={flat.id} className={flatCardClasses}>
            <div className="company-name-overlay">
                <p>{flat.company}</p>
            </div>
            <div className={'LikesButton'}>
                <LikeComponent objectId={flat.id} like_collection='flats'/>
            </div>
            {!isOpenCard && flat.imageUrls?.length ? (
                <Slider {...sliderSettings}>
                    <div onClick={() => handleFlatClick(flat.id)}>
                        <img
                            src={flat.imageUrl}
                            alt={`${flat.city} ${flat.description}`}
                            className="flat-image"
                            loading="lazy"
                        />
                    </div>
                    {flat.imageUrls.map((url, index) => (
                        <div key={index}>
                            <img
                                src={url}
                                alt={`${flat.city} image ${index}`}
                                className="flat-image"
                                loading="lazy"
                            />
                        </div>
                    ))}
                </Slider>
            ) : !isOpenCard && (
                <img
                    src={flat.imageUrl}
                    alt={`${flat.city} ${flat.description}`}
                    className="car-image"
                />
            )}

            <div className="flat-card-details">
                {!isOpenCard && ( // Теперь блок заголовка отображается только если календарь закрыт
                    <div className="disFlex brandvolume flatCard-title-block">
                        <h3 className="right">{flat.title}</h3>
                        <p>{flat.dealType}</p>
                    </div>
                )}

                <div className="flat-details">
                    <div className="LeftRight"></div>

                    {!isOpenCard && (
                        <div className="disFlex elementsIcons IconGrid IconGridFlats">
                            <div className="disGrid flat-icon-item">
                                {renderFeatureIconUp(flat.rooms, '', rooms, "Rooms", isOpenCard)}
                                <span className="fuelUnit">{flat.rooms}</span>
                            </div>
                            <div className="disGrid flat-icon-item">
                                {renderFeatureIconUp(flat.floor, '', floor, "Floor", isOpenCard)}
                                <span className="fuelUnit">{flat.floor}</span>
                            </div>
                            <div className="disGrid flat-icon-item">
                                {renderFeatureIconUp(flat.bedrooms, flat.bedrooms, bedroom, "Bedrooms", isOpenCard)}
                                <span className="fuelUnit">{flat.bedrooms}</span>
                            </div>
                            <div className="disGrid flat-icon-item">
                                {renderFeatureIconUp(flat.bathrooms, '', bathrooms, "Bathrooms", isOpenCard)}
                                <span className="fuelUnit">{flat.bathrooms}</span>
                            </div>
                            <div className="disGrid flat-icon-item">
                                {renderFeatureIconUp(flat.view, '', view, "View", isOpenCard)}
                                <span className="fuelUnit">{flat.view}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {isOpenCard && (
                <DatePickerFlat
                    priceForMonth={flat.pricePerMonth}
                    price2to3days={flat.pricePer3Days}
                    price4to7days={flat.pricePerWeek}
                    price8to15days={flat.pricePer14Days}
                    price15to30days={flat.pricePerMonth}
                    objectID={flat.id}
                    carName={flat.description}
                    carModel={flat.description}
                    priceForSelling={flat.priceForSelling}
                    isDisableLoginFormTempFlag={isDisableLoginFormTempFlag}
                />
            )}

            <div className="price-details">
                <div className="price-item"></div>
            </div>

            <div className="button-container">
                <button
                    className={`details-button ${isOpenCard ? 'collapsed' : ''}`}
                    onClick={() => {
                        setIsOpenCard(!isOpenCard);
                    }}
                >
                    {!isOpenCard && (
                        <div className="flat-detail">
                            {flat.priceForSelling ? (
                                <div>{flat.priceForSelling}$</div>
                            ) : (
                                <div>{flat.pricePerMonth}$</div>
                            )}
                        </div>
                    )}

                    {!isOpenCard && <span className="separator"> | </span>}

                    <div className="flat-detail">
                        <div>
                            {isOpenCard ? (
                                <span className="arrow-up">
                                <svg className="custom-arrow" width="32" height="10" viewBox="0 0 32 10" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                          stroke="currentColor" strokeOpacity="1" strokeWidth="2"
                                          strokeLinecap="round"/>
                                </svg>
                            </span>
                            ) : (
                                <>{translations.productListCars.liveView}</>
                            )}
                        </div>
                    </div>
                </button>
            </div>

            {!isOpenCard && (
                <div className="date-icon">
                    <svg className="custom-arrow-down" width="32" height="10" viewBox="0 0 32 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                              stroke="currentColor" strokeOpacity="1" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
            )}
        </div>
    );


};

export default FlatCard;
