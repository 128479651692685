import React, { useState, useContext } from 'react';
import {Route, useNavigate} from 'react-router-dom'; // Импортируем useNavigate
import './Home.css';
import InteractiveMapWidget from './../InteractiveMapWidget/InteractiveMapWidget';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import PartnerForm from '../Form/PartnerForm/PartnerForm';
import Chat from "../Chat/chat/ChatContainer";
import Likespage from "../LikesPage/Likespage";

const categories = [
    { nameKey: "vehicles", className: "vehicles-card",imagePath: "/mainPage/FrameCars.png", imageClass: "vehicles-image" },
    { nameKey: "houses", className: "houses-card",imagePath: "/mainPage/Frame 12.png", imageClass: "house-image" },
    { nameKey: "waterRecreation", className: "water-recreation-card",imagePath: "/mainPage/new-town-banner.jpg", imageClass: "water-recreation-image" },
    { nameKey: "apartments", className: "apartments-card",imagePath: "/mainPage/frameFlats.png", imageClass: "apartments-image" },
    { nameKey: "hotel", className: "bike-card",imagePath: "/mainPage/hotel.webp", imageClass: "bike-image" },
    { nameKey: "restaurants", className: "restaurants-card",imagePath: "/mainPage/restorans.jpg", imageClass: "restaurants-image" },
    { nameKey: "villa", className: "health-card",imagePath: "mainPage/villaCategory.jpg", imageClass: "health-image" },
    { nameKey: "toursAdventures", className: "tours-adventures-card",imagePath: "/mainPage/Frame 9.png", imageClass: "tours-adventures-image" },
    { nameKey: "map", className: "map-card" },
    { nameKey: "placeholder", className: "placeholder-card" },
    { nameKey: "cash", className: "cash-card" },
    { nameKey: "events", className: "events-card" }

];

const Home = () => {
    const { translations } = useContext(LocalizationContext);
    const [activeTab, setActiveTab] = useState('map');
    const navigate = useNavigate(); // Инициализируем useNavigate
     const [isModalFormOpen, setModalFormOpen] = useState(false);

    const openModal = () => {
        setModalFormOpen(true);
    };
    const closeFormModal = () => {
        setModalFormOpen(false);
    };
    // Обработчик клика для перехода
    const handleCategoryClick = (categoryKey) => {
    if (categoryKey === "vehicles") {
        navigate("/product-list-cars"); // Переход на страницу product-list-cars
    } else if (categoryKey === "apartments") {
        navigate("/product-list-flats"); // Переход на страницу product-list-flats
    }
     else if (categoryKey === "houses") {
        navigate("/product-list-house"); // Переход на страницу product-list-flats
    }

        else if (categoryKey === "villa") {
        navigate("/product-list-villa", "_blank");
    }
         else if (categoryKey === "hotel") {
        navigate("/product-list-hotel","_blank");
    }

    else if (categoryKey === "restaurants") {
        window.open("https://europesquare.ge", "_blank"); //  c новой вкладкой
    }
    // else if (categoryKey === "toursAdventures") {
    //     openModal(); // Открываем модальное окно
    // }

       else if (categoryKey === "toursAdventures") {
      navigate("/chat","_blank");
    }



};


    const lastTwoCategories = categories.slice(-3, -1);
    const lastCategory = categories[categories.length - 1];
    const remainingCategories = categories.slice(0, -3);

    return (
        <div className="home-page">
            <div className="home-page-banner">
                <div className="home-banner-content-wrapper">
                    <div className="home-title-text">
                        <h1 className="home-main-title">Weeekend</h1>
                        <h2 className="home-beta-tag">BETA 1.1</h2>
                    </div>
                    <p className="home-banner-description">
                        We are a one-stop platform for booking travel, renting accommodations, cars, water activities,
                        and more.
                        In one place, you can easily find the best offers for car rentals, homes, yachts, jet skis, and
                        book
                        exciting activities like parasailing!
                    </p>
                </div>
            </div>
            {/*<div className="category-home-h2">*/}
            {/*    <h2 className="home-beta-tag2"> Основные категории </h2>*/}


            {/*</div>*/}


            <div className="home-category-grid">
                {remainingCategories.map((category, index) => (
                    category.nameKey !== "map" && ( // Убираем карту (map)
                        <div
                            key={index}
                            className={`home-category-card ${category.className}`}
                            onClick={() => handleCategoryClick(category.nameKey)}
                        >
                            <div className="inner-block">
                                <div className="image-block">
                                    {/* Отображение видео, если imagePath содержит видео */}
                                    {category.imagePath.endsWith('.mp4') ? (
                                        <video
                                            className={`category-video ${category.imageClass}`}
                                            src={category.imagePath}
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                        ></video>
                                    ) : (
                                        <img
                                            className={`category-image ${category.imageClass}`}
                                            src={category.imagePath}
                                            alt={category.nameKey}
                                        />
                                    )}
                                </div>
                            </div>
                            <span className="category-text">
                    {translations.homeCategories[category.nameKey]}
                </span>
                        </div>
                    )
                ))}

                <div className="bars-cash-wrapper">
                    {lastTwoCategories.map((category, index) => (
                        <div
                            key={index}
                            className={`home-category-card ${category.className}`}
                            onClick={() => handleCategoryClick(category.nameKey)}
                        >
                            <div className="inner-block"></div>
                            <span className="category-text">
                    <p> Weeekend Department Development || Georgia 2024 </p>
                </span>
                        </div>
                    ))}
                </div>

                <div
                    className={`home-category-card ${lastCategory.className}`}
                    onClick={() => handleCategoryClick(lastCategory.nameKey)}
                >
                    <div className="inner-block"></div>
                    <span className="category-text">
                        {translations.homeCategories[lastCategory.nameKey]}
        </span>
                </div>
            </div>
             {isModalFormOpen && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <button className="modal-close" onClick={closeFormModal}>✖</button>
                    <PartnerForm />
                </div>
            </div>
        )}
        </div>
    );
};

export default Home;
