import { useState } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Stories.css';

const Stories = ({ userId, stories, auth, handleStoryDelete, handleStoryEdit }) => {
  const [newStory, setNewStory] = useState('');
  const [editingStoryId, setEditingStoryId] = useState(null);
  const [editedText, setEditedText] = useState('');

  const handleAddStory = async () => {
    if (!newStory.trim() || auth.currentUser?.uid !== userId) return;
    await addDoc(collection(db, "users", userId, "stories"), {
      text: newStory,
      createdAt: new Date(),
      expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000), // 24 часа
    });
    setNewStory('');
  };

  const startEditing = (story) => {
    setEditingStoryId(story.id);
    setEditedText(story.text);
  };

  const submitEdit = (storyId) => {
    handleStoryEdit(storyId, editedText);
    setEditingStoryId(null);
    setEditedText('');
  };

  return (
      <div className="stories-container">
        <h3>Сторис</h3>
        {auth.currentUser?.uid === userId && (
            <div className="story-input">
              <input
                  type="text"
                  value={newStory}
                  onChange={(e) => setNewStory(e.target.value)}
                  placeholder="Добавить сторис..."
              />
              <button onClick={handleAddStory}>Опубликовать</button>
            </div>
        )}
        <div className="stories-list">
          {stories.map(story => (
              <div key={story.id} className="story">
                {editingStoryId === story.id ? (
                    <div className="edit-form">
                      <input
                          type="text"
                          value={editedText}
                          onChange={(e) => setEditedText(e.target.value)}
                          placeholder="Редактировать сторис..."
                      />
                      <button onClick={() => submitEdit(story.id)}>Сохранить</button>
                      <button onClick={() => setEditingStoryId(null)}>Отмена</button>
                    </div>
                ) : (
                    <>
                      <p>{story.text}</p>
                      <span>Истекает: {new Date(story.expiresAt.toDate()).toLocaleTimeString()}</span>
                      {auth.currentUser?.uid === userId && (
                          <div className="story-actions">
                            <button onClick={() => startEditing(story)}>Редактировать</button>
                            <button onClick={() => handleStoryDelete(story.id)}>Удалить</button>
                          </div>
                      )}
                    </>
                )}
              </div>
          ))}
        </div>
      </div>

      
  );
};

export default Stories;