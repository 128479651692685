import { useNavigate } from "react-router-dom";
import defaultAvatar from '../../img/defaultAvatar.gif';

const Avatar = ({ avatar, userId, isGroup = false, onClick }) => {
  const navigate = useNavigate();

  console.log("Avatar props:", { avatar, userId, isGroup }); // Отладка

  const avatarSrc = avatar && avatar.trim() !== "" ? avatar : defaultAvatar;

  const handleClick = (e) => {
    if (isGroup && onClick) {
      // Для групп используем переданный onClick (например, показ опций)
      onClick(e);
    } else if (!isGroup && userId) {
      // Для пользователей редиректим на профиль
      navigate(`/profile/${userId}`);
    }
  };

  return (
    <img
      src={avatarSrc}
      alt="Avatar"
      className="avatar"
      onClick={handleClick}
      onError={(e) => {
        console.log("Image load error for userId:", userId, "Using default");
        e.target.src = defaultAvatar;
      }}
    />
  );
};

export default Avatar;