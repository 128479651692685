import { db } from '../firebase/firebase';
import { collection, doc, setDoc, addDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { sendNotification } from './fcmService';

export const subscribeToMessages = (chatId, callback) => {
  const messagesRef = collection(db, 'messages', chatId, 'chatMessages');
  return onSnapshot(messagesRef, callback, (error) =>
    console.error('Ошибка подписки:', error)
  );
};

export const sendMessage = async (chatId, text, senderId) => {
  const messageRef = await addDoc(collection(db, 'messages', chatId, 'chatMessages'), {
    text,
    sender: senderId,
    createdAt: new Date(),
    sentAt: new Date(),
    deliveredAt: new Date(),
    readAt: null,
    isRead: false,
    type: 'text',
  });

  // Отправляем уведомления всем участникам чата, кроме отправителя
  const chatRef = doc(db, 'chats', chatId);
  const chatDoc = await getDoc(chatRef);
  const users = chatDoc.data().users.filter(uid => uid !== senderId);

  const senderDoc = await getDoc(doc(db, 'users', senderId));
  const senderName = senderDoc.data().name || senderId;

  users.forEach(async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const token = userDoc.data().fcmToken;
    if (token) {
      await sendNotification(token, chatId, senderId, senderName, text, 'message');
    }
  });

  return messageRef;
};

export const createChat = async (userIds) => {
  const chatId = userIds.sort().join('_');
  await setDoc(doc(db, 'chats', chatId), { users: userIds });
  return chatId;
};