import React, { useState, useContext } from 'react';
import './ToggleSwitch.css';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import { GlobalFilterContext } from '../../Services/context/GlobalFilterContext';

const ToggleSwitch = () => {
    const { toggleGlobalFilter, isRentType } = useContext(GlobalFilterContext);
    const isRentSelected = isRentType();
    const { translations } = useContext(LocalizationContext);

    const handleToggle = () => {
        toggleGlobalFilter();
    };

    return (
        <div className="toggle-switch" onClick={handleToggle}>
            <div className={`slider ${isRentSelected ? 'left' : 'right'}`}></div>
            <div className={`option ${isRentSelected ? 'selected' : ''}`}>{translations.toggleSwitch.rent}</div>
            <div className={`option ${!isRentSelected ? 'selected' : ''}`}>{translations.toggleSwitch.purchase}</div>
        </div>
    );
};

export default ToggleSwitch;