import { useState } from "react";
import { auth } from "../../../firebase/firebase";
import GroupHeader from "./GroupHeader";
import MessageList from "../chat/MessageList";
import MessageInput from "../chat/MessageInput";
import "../css/ChatStyles.css";

const GroupChat = ({ group, onBack }) => {
  const [chatId] = useState(`${auth.currentUser.uid}_${group.id}`);

  return (
    <div className="group-chat">
      <GroupHeader group={group} onBack={onBack} />
      <MessageList chatId={chatId} />
      <MessageInput chatId={chatId} />
    </div>
  );
};

export default GroupChat;