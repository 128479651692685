// src/Services/context/ChatNotificationContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const ChatNotificationContext = createContext();

export const useChatNotifications = () => useContext(ChatNotificationContext);

export const ChatNotificationProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!currentUser) {
      setUnreadCount(0);
      return;
    }

    const chatsRef = collection(db, "chats");
    const chatsQuery = query(chatsRef, where("users", "array-contains", currentUser.uid));

    const unsubscribeChats = onSnapshot(chatsQuery, (snapshot) => {
      const chatIds = snapshot.docs.map(doc => doc.id);
      let totalUnread = 0;
      const messageUnsubscribers = [];

      // Подписка на каждую коллекцию сообщений
      chatIds.forEach(chatId => {
        const messagesRef = collection(db, "messages", chatId, "chatMessages");
        const unreadQuery = query(
          messagesRef,
          where("isRead", "==", false),
          where("sender", "!=", currentUser.uid)
        );

        const unsub = onSnapshot(unreadQuery, (unreadSnapshot) => {
          totalUnread = chatIds.reduce((sum, cid) => {
            const messagesForChat = unreadSnapshot.docs.filter(doc => doc.ref.path.includes(cid));
            return sum + messagesForChat.length;
          }, totalUnread);
          console.log("ChatNotificationProvider: Обновление unreadCount =", totalUnread); // Отладка
          setUnreadCount(totalUnread);
        }, (error) => {
          console.error(`Ошибка подписки на сообщения чата ${chatId}:`, error);
        });
        messageUnsubscribers.push(unsub);
      });

      return () => messageUnsubscribers.forEach(unsub => unsub && unsub());
    }, (error) => {
      console.error("Ошибка подписки на чаты:", error);
      setUnreadCount(0);
    });

    return () => unsubscribeChats();
  }, [currentUser]);

  return (
    <ChatNotificationContext.Provider value={{ unreadCount }}>
      {children}
    </ChatNotificationContext.Provider>
  );
};