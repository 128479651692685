import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import CustomDatePickerFlats from "./CustomDatePicker/CustomDatePickerFlats";
import './FlatDetailCard.css';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';
import LikeComponent from "../../LikeComponent/LikeComponent";
import ShareComponent from '../../ShareComponent/ShareComponent';
import BackButton from "../../button/BackBotton/BackButton"; // Импортируем новый компонент

const FlatDetailCard = () => {
  const { flatId } = useParams();
  const navigate = useNavigate();
  const [flat, setFlat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullscreenIndex, setFullscreenIndex] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysCount, setDaysCount] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const galleryRef = useRef(null);
  const { translations } = useContext(LocalizationContext);

  useEffect(() => {
    const fetchFlat = async () => {
      try {
        const flatDoc = doc(db, 'flats', flatId);
        const flatSnapshot = await getDoc(flatDoc);
        if (flatSnapshot.exists()) {
          setFlat({ id: flatSnapshot.id, ...flatSnapshot.data() });
        } else {
          console.error('Flat not found');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching flat:', error);
        setLoading(false);
      }
    };
    fetchFlat();
  }, [flatId]);

  const openFullscreen = (index) => {
    setFullscreenIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenIndex(null);
  };

  const handlePrevImage = () => {
    const imageUrls = [flat?.imageUrl, ...(flat?.imageUrls || [])];
    setFullscreenIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
    setMainImageIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    const imageUrls = [flat?.imageUrl, ...(flat?.imageUrls || [])];
    setFullscreenIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
    setMainImageIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDaysCountChange = (count) => {
    setDaysCount(count);
  };

  const handleSwipeStart = (e) => {
    const touch = e.touches ? e.touches[0] : e;
    galleryRef.current.startX = touch.clientX;
  };

  const handleSwipeMove = (e) => {
    if (!galleryRef.current.startX) return;
    const touch = e.touches ? e.touches[0] : e;
    const diffX = galleryRef.current.startX - touch.clientX;
    if (Math.abs(diffX) > 50) {
      const imageUrls = [flat?.imageUrl, ...(flat?.imageUrls || [])];
      if (diffX > 0) {
        setMainImageIndex((prev) => (prev === imageUrls.length - 1 ? 0 : prev + 1));
      } else {
        setMainImageIndex((prev) => (prev === 0 ? imageUrls.length - 1 : prev - 1));
      }
      galleryRef.current.startX = null;
    }
  };

  const handleSwipeEnd = () => {
    galleryRef.current.startX = null;
  };

  if (loading) {
    return <div className="cdc-loading">Loading...</div>;
  }

  if (!flat) {
    return <div className="cdc-not-found">Apartment not found</div>;
  }

  const imageUrls = [flat.imageUrl, ...(flat.imageUrls || [])];

  return (
    <div className="cdc-app-container">
      <BackButton navigate={navigate} />
      <main className="cdc-main">

        <div className="cdc-gallery-container">
          <h1 className="cdc-title">{flat.title}</h1>
          <div
            className="cdc-main-image-container"
            ref={galleryRef}
            onMouseDown={handleSwipeStart}
            onMouseMove={handleSwipeMove}
            onMouseUp={handleSwipeEnd}
            onTouchStart={handleSwipeStart}
            onTouchMove={handleSwipeMove}
            onTouchEnd={handleSwipeEnd}
          >
            <img
              src={imageUrls[mainImageIndex]}
              alt={`${flat.title} main image`}
              className="cdc-main-image"
              onClick={() => openFullscreen(mainImageIndex)}
            />
            <div className={'LikesButton'}>
              <LikeComponent objectId={flat.id} like_collection='flats'/>
            </div>
          </div>
          <div className="cdc-thumbnail-carousel">
            {imageUrls.map((url, index) => (
              <div
                key={index}
                className={`cdc-thumbnail-item ${index === mainImageIndex ? 'active' : ''}`}
                onClick={() => setMainImageIndex(index)}
              >
                <img
                  src={url}
                  alt={`${flat.title} thumbnail ${index}`}
                  className="cdc-thumbnail-img"
                />
              </div>
            ))}
          </div>
        </div>

        <section className="cdc-details">
          <ShareComponent title={translations.share || 'Share'} />

          <h2>{translations.carDetail.details || 'Apartment Details'}</h2>
          <div className="cdc-info-p">
            <p><strong>{translations.carDetail.company || 'Company'}:</strong> {flat.company}</p>
            <p><strong>{translations.flatDetails?.area || 'Area'}:</strong> {flat.area} m²</p>
            <p><strong>{translations.flatDetails?.rooms || 'Rooms'}:</strong> {flat.rooms}</p>
            <p><strong>{translations.flatDetails?.bedrooms || 'Bedrooms'}:</strong> {flat.bedrooms}</p>
            <p><strong>{translations.flatDetails?.bathrooms || 'Bathrooms'}:</strong> {flat.bathrooms}</p>
            <p><strong>{translations.flatDetails?.floor || 'Floor'}:</strong> {flat.floor}</p>
            <p><strong>{translations.flatDetails?.city || 'City'}:</strong> {flat.city}</p>
            <p><strong>{translations.flatDetails?.address || 'Address'}:</strong> {flat.address}</p>
            <p><strong>{translations.flatDetails?.propertyType || 'Property Type'}:</strong> {flat.propertyType}</p>
          </div>
          <div className="vehicle-description">
            <h2>{translations.carDetail.description || 'Description'}</h2>
            <p>{flat.description || translations.carDetail.noDescriptionAvailable || 'No description available'}</p>
          </div>
          <div className="cdc-info-p2">
            <p><strong>{translations.carDetail.deposit || 'Deposit'}:</strong> {flat.deposit || translations.carDetail.no}</p>
            <p><strong>{translations.carDetail.priceThirtyDay|| 'Price 30 days'}:</strong> ${flat.pricePerMonth || flat.price31days}</p>
            {flat.priceForSelling && (
              <p><strong>{translations.carDetail.priceForSelling || 'Price for Selling'}:</strong> ${flat.priceForSelling}</p>
            )}
          </div>
          <div className="cdc-booking-section">
            <h2>{translations.flatDetails.book || 'Book This Apartment'}</h2>
            <CustomDatePickerFlats
              onDatesChange={handleDatesChange}
              startDate={startDate}
              endDate={endDate}
              onDaysCountChange={handleDaysCountChange}
              price1day={flat.pricePerDay || flat.price1day}
              price2to3days={flat.pricePer3Days || flat.price2to3days || flat.price1day}
              price4to7days={flat.pricePerWeek || flat.price4to7days || flat.price1day}
              price8to15days={flat.pricePer14Days || flat.price8to15days || flat.price1day}
              price16to30days={flat.pricePerMonth || flat.price16to30days || flat.price1day}
              price31plusdays={flat.pricePerMonth || flat.price31plusdays || flat.price1day}
              objectID={flat.id}
              carName={flat.title}
              carModel={flat.propertyType}
              carCompany={flat.company}
              priceForSelling={flat.priceForSelling}
            />
          </div>
        </section>
      </main>
      {fullscreenIndex !== null && (
        <div className="cdc-fullscreen-overlay" onClick={closeFullscreen}>
          <button
            className="cdc-fullscreen-arrow cdc-fullscreen-arrow-left"
            onClick={(e) => { e.stopPropagation(); handlePrevImage(); }}
          >
            ‹
          </button>
          <img
            src={imageUrls[fullscreenIndex]}
            alt="Fullscreen view"
            className="cdc-fullscreen-img"
          />
          <button
            className="cdc-fullscreen-arrow cdc-fullscreen-arrow-right"
            onClick={(e) => { e.stopPropagation(); handleNextImage(); }}
          >
            ›
          </button>
          <button className="cdc-fullscreen-close-btn" onClick={closeFullscreen}>
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default FlatDetailCard;