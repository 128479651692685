import React, { useState, useContext, useEffect, useRef } from 'react';
import authImg from '../forlogin.jpeg';
import { LocalizationContext } from "../../../Services/localization/LocalizationContext";
import { Eye, EyeOff } from 'lucide-react'; // Иконки для глазика
import { auth, db, doc, setDoc } from '../../../firebase/firebase';
import { query, where, getDocs, collection } from "firebase/firestore";
import { createUserWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";

const SignupComponent = ({onClose}) => {
    const { translations } = useContext(LocalizationContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const inputContainerRef = useRef(null);

    const [theme, setTheme] = useState('day-mode');



    useEffect(() => {
        const handleThemeChange = (e) => {
            setTheme(e.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);
        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    const checkUserExists = async () => {
        const usersRef = collection(db, "users");

        const emailQuery = query(usersRef, where("email", "==", formData.email));
        const emailSnapshot = await getDocs(emailQuery);

        const loginQuery = query(usersRef, where("login", "==", formData.login));
        const loginSnapshot = await getDocs(loginQuery);

        if (!emailSnapshot.empty) {
            return translations.signUpForm.emailAlreadyTaken; // Возвращаем строку, а не JSX
        }

        if (!loginSnapshot.empty) {
            return translations.signUpForm.loginAlreadyTaken;
        }

        return null;
    };

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        login: '',
        password: '',
        confirmPassword: '',
        phone_num: '',
        messenger: {
            whatsapp: null,
            telegram: null,
            instagram: null
        }
    });


    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

        let validationErrors = { ...errors };

        if (id === 'confirmPassword' && formData.password !== value) {
            validationErrors.confirmPassword = translations.signUpForm.passwordsDoNotMatch;
        } else if (id === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            validationErrors.email = translations.signUpForm.invalidEmail;
        } else {
            delete validationErrors[id];
        }

        setErrors(validationErrors);
    };

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData({
            ...formData,
            messenger: {
                ...formData.messenger,
                [id]: checked ? "" : null  // если чекбокс отмечен — пустая строка (активное поле), иначе — null
            }
        });
    };
    const handleMessengerToggle = (id) => {
        setFormData({
            ...formData,
            messenger: {
                ...formData.messenger,
                [id]: formData.messenger[id] === null ? "" : null,
            },
        });
    };
    const handleMessengerChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            messenger: {
                ...formData.messenger,
                [id]: value
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrors({});

        let validationErrors = {};

        // Проверка совпадения паролей
        if (formData.password !== formData.confirmPassword) {
            validationErrors.confirmPassword = translations.signUpForm.passwordsDoNotMatch;
        }

        // Проверка правильности email
        const emailPattern = /\S+@\S+\.\S+/;
        if (!emailPattern.test(formData.email)) {
            validationErrors.email = translations.signUpForm.invalidEmail;
        }

        // Если есть ошибки, остановить выполнение
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);

        try {
            // Проверяем, существует ли пользователь в Firestore
            const usersRef = collection(db, "users");
            const userQuery = query(usersRef, where("email", "==", formData.email));
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
                setErrors({ general: translations.signUpForm.emailAlreadyTaken });
                setLoading(false);
                return;
            }

            // Регистрация пользователя в Firebase Auth
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            // Сохранение пользователя в Firestore
            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: formData.name,
                surname: formData.surname,
                email: formData.email,
                login: formData.login,
                phone_num: formData.phone_num,
                messenger: formData.messenger
            });

            setSuccessMessage(translations.signUpForm.successfulSignup);

            // Очистка формы
            setFormData({
                name: '',
                surname: '',
                email: '',
                login: '',
                password: '',
                confirmPassword: ''
            });

        } catch (error) {
            console.error("Error during sign-up: ", error);
            setErrors({ general: error.message });
        } finally {
            setLoading(false);
        }

        // Закрытие модалки после успешной регистрации
        if (onClose) {
            onClose();
        }
    };

    const handleGoogleSignUp = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Проверяем, есть ли пользователь в Firestore
            const usersRef = collection(db, "users");
            const userQuery = query(usersRef, where("email", "==", user.email));
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
                // Пользователь уже существует, просто входим
                setSuccessMessage("User logged in successfully!");
            } else {
                // Если пользователя нет — добавляем его в Firestore
                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    name: user.displayName || formData.name,
                    email: user.email,
                    login: user.email, // Можно выбрать другой способ генерации логина
                });

                setSuccessMessage("User signed up with Google successfully!");
            }
        } catch (error) {
            console.error("Google sign-up error: ", error);
            setErrorMessage("Failed to sign up with Google. Please try again.");
        }
    };


    const location = useLocation();

    return (
        <div className="LoginComponent">
            <div className={'signup-login-container'}>
                <div className={'signup-login-image'}>
                    <img src={authImg} alt="" />
                </div>

                <div className={'signup-login-details'}>

                    <h1 className={'signup-login-title'}>Create account</h1>
                    <button onClick={handleGoogleSignUp} className={'google-login-btn'}>
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 89.97 91.8">

                            <path className="cls-111"
                                  d="M120,76.1a57.41,57.41,0,0,0-.8-9.3H75.9V84.5h24.8a20.86,20.86,0,0,1-9.2,13.9l14.8,11.5C115,101.8,120,90,120,76.1Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-112"
                                  d="M75.9,120.9c12.4,0,22.8-4.1,30.4-11.1L91.5,98.4a27.35,27.35,0,0,1-15.6,4.4c-12,0-22.1-8.1-25.8-18.9L34.9,95.6A45.85,45.85,0,0,0,75.9,120.9Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-113"
                                  d="M50.1,83.8a27.88,27.88,0,0,1,0-17.6L34.9,54.4a46,46,0,0,0,0,41.2Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-114"
                                  d="M75.9,47.3a25,25,0,0,1,17.6,6.9L106.6,41A44.17,44.17,0,0,0,75.9,29.1a45.85,45.85,0,0,0-41,25.3L50.1,66.2A27.46,27.46,0,0,1,75.9,47.3Z"
                                  transform="translate(-30.03 -29.1)"/>
                        </svg>
                        Sign up with google
                    </button>
                    <div className="auth-page-phone-info">
                        {translations.loginForm.emailOrPhoneOrLogin}
                    </div>
                    <form onSubmit={handleSubmit} className={'signup-login-details-container input-container-auth-sign'}>


                        <input
                            className={'signup-login-input input-name'}
                            type="text"
                            id="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            placeholder={translations.signUpForm.name}
                        />

                        <input
                            className={'signup-login-input'}
                            type="text"
                            id="login"
                            value={formData.login}
                            onChange={handleInputChange}
                            required
                            placeholder={translations.signUpForm.login}
                        />
                        <input
                            className={'signup-login-input'}
                            type="email"
                            id="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            placeholder={translations.signUpForm.email}
                        />
                        <input
                            className={'signup-login-input'}
                            placeholder={'Phone number'}
                            type="text"
                            id="phone_num"
                            value={formData.phone_num}
                            onChange={handleInputChange}
                            required
                        />

                        <div className="form-group disFlex auth-checkbox-container">


                            <div className="form-group disFlex">
                                <label
                                    className={`messenger-label ${formData.messenger.whatsapp !== null ? "active" : ""}`}
                                    onClick={() => handleMessengerToggle("whatsapp")}
                                >
                                    WhatsApp
                                </label>

                            </div>

                            {/* Telegram */}
                            <div className="form-group disFlex">
                                <label
                                    className={`messenger-label ${formData.messenger.telegram !== null ? "active" : ""}`}
                                    onClick={() => handleMessengerToggle("telegram")}
                                >
                                    Telegram
                                </label>

                            </div>

                            {/* Instagram */}
                            <div className="form-group disFlex">
                                <label
                                    className={`messenger-label ${formData.messenger.instagram !== null ? "active" : ""}`}
                                    onClick={() => handleMessengerToggle("instagram")}
                                >
                                    Instagram
                                </label>

                            </div>
                        </div>
                        {formData.messenger.whatsapp !== null && (
                            <input
                                type="text"
                                id="whatsapp"
                                value={formData.messenger.whatsapp || ""}
                                className="authInputs"
                                onChange={handleMessengerChange}
                                placeholder="Enter WhatsApp number"
                            />
                        )}
                        {formData.messenger.telegram !== null && (
                            <input
                                type="text"
                                id="telegram"
                                value={formData.messenger.telegram || ""}
                                className="authInputs"
                                onChange={handleMessengerChange}
                                placeholder="Enter telegram number"
                            />
                        )}
                        {formData.messenger.instagram !== null && (
                            <input
                                type="text"
                                id="instagram"
                                value={formData.messenger.instagram || ""}
                                className="authInputs"
                                onChange={handleMessengerChange}
                                placeholder="Enter instagram"
                            />
                        )}
                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="signup-login-input"
                                id="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.loginForm.password}
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                            </button>
                        </div>

                        <div className="password-container">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="signup-login-input"
                                id="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.signUpForm.confirmPassword}
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                            </button>
                        </div>

                        {errors.general && <p className="error-message">{errors.general}</p>}
                        {errors.email && <p className="error-message">{errors.email}</p>}
                        {errors.login && <p className="error-message">{errors.login}</p>}
                        {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
                        <button type="submit" className="submit-button" disabled={loading}>
                            {loading ? "Signing up..." : translations.loginForm.signUpHere}
                        </button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignupComponent;