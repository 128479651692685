import React, { useState, useContext, useRef } from "react";
import { LocalizationContext } from "../../Services/localization/LocalizationContext";

function ImageUpload({ onSingleImageChange, onMultipleImagesChange }) {
  const { translations } = useContext(LocalizationContext);
  const [singleImagePreview, setSingleImagePreview] = useState(null);
  const [multipleImagesPreviews, setMultipleImagesPreviews] = useState([]);

  // Создаём рефы для доступа к скрытым input-элементам
  const singleInputRef = useRef(null);
  const multipleInputRef = useRef(null);

  // Функция для сжатия изображения
  const handleImageResize = (file, maxSizeKB = 200) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          const maxWidth = 1024; // Максимальная ширина изображения
          const maxHeight = 1024; // Максимальная высота изображения

          // Пропорциональное изменение размера
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Преобразуем canvas в blob
          canvas.toBlob((blob) => {
            // Если размер blob в KB меньше или равен лимиту, создаём новый файл
            if (blob.size / 1024 <= maxSizeKB) {
              const newFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(newFile);
            } else {
              // Если изображение слишком большое, пробуем сжать дополнительно
              const quality = 0.8; // Начальное качество
              canvas.toBlob(
                (resizedBlob) => {
                  const newFile = new File([resizedBlob], file.name, {
                    type: file.type,
                  });
                  if (resizedBlob.size / 1024 <= maxSizeKB) {
                    resolve(newFile);
                  } else {
                    reject(new Error("Unable to resize image within the size limit"));
                  }
                },
                file.type,
                quality
              );
            }
          }, file.type);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSingleImageChange = async (file) => {
    if (file) {
      try {
        const resizedImage = await handleImageResize(file); // Сжимаем изображение
        const reader = new FileReader();
        reader.onloadend = () => {
          setSingleImagePreview(reader.result);
        };
        reader.readAsDataURL(resizedImage);
        onSingleImageChange(resizedImage); // Отправляем сжатое изображение
      } catch (error) {
        console.error("Error resizing image:", error);
        // Если ошибка при сжатии, загружаем изображение без изменений
        const reader = new FileReader();
        reader.onloadend = () => {
          setSingleImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
        onSingleImageChange(file);
      }
    }
  };

  const handleMultipleImagesChange = async (files) => {
    const resizedImages = [];
    for (let file of files) {
      try {
        const resizedImage = await handleImageResize(file); // Сжимаем изображение
        resizedImages.push(resizedImage);
      } catch (error) {
        console.error("Error resizing image:", error);
        resizedImages.push(file); // Если ошибка — добавляем оригинал
      }
    }

    // Создаём превью для всех изображений
    const previews = await Promise.all(
      resizedImages.map((image) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(image);
        })
      )
    );

    setMultipleImagesPreviews(previews);
    onMultipleImagesChange(resizedImages); // Отправляем сжатые изображения
  };

  return (
    <div className="disFlex centring">
      <div>
        <label>{translations.imageUpload.uploadMainPhoto}</label>
        {/* Кнопка для выбора основного фото */}
        <button
          type="button"
          onClick={() => {
            if (singleInputRef.current) {
              singleInputRef.current.click();
            }
          }}
        >
          {translations.imageUpload.uploadMainPhotoButton || "Выбрать файл"}
        </button>
        {/* Скрытый input для основного фото */}
        <input
          ref={singleInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleSingleImageChange(e.target.files[0])}
        />
        {singleImagePreview && (
          <div>
            <p>{translations.imageUpload.singleImagePreview}</p>
            <img
              src={singleImagePreview}
              alt="Single Preview"
              style={{ width: "50px", height: "auto" }}
            />
          </div>
        )}
      </div>

      <div>
        <label>{translations.imageUpload.uploadOtherPhotos}</label>
        {/* Кнопка для выбора дополнительных фото */}
        <button
          type="button"
          onClick={() => {
            if (multipleInputRef.current) {
              multipleInputRef.current.click();
            }
          }}
        >
          {translations.imageUpload.uploadOtherPhotosButton || "Выбрать файлы"}
        </button>
        {/* Скрытый input для дополнительных фото */}
        <input
          ref={multipleInputRef}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => handleMultipleImagesChange(e.target.files)}
        />
        {multipleImagesPreviews.length > 0 && (
          <div>
            <p>{translations.imageUpload.multipleImagesPreview}</p>
            <div style={{ display: "flex", gap: "10px" }}>
              {multipleImagesPreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Preview ${index + 1}`}
                  style={{ width: "100px", height: "auto" }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
