import React, { useContext, useEffect, useState } from 'react';
import { LocalizationContext } from "../../../Services/localization/LocalizationContext";
import './BackButton.css'; // Импортируем стили из BackButton.css (предполагаемый путь)

const BackButton = ({ navigate }) => {
  const { translations } = useContext(LocalizationContext);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsVisible(false); // Исчезает при скролле вниз
      } else {
        setIsVisible(true); // Появляется при скролле вверх
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <div className={`back-button-container ${isVisible ? 'visible' : 'hidden'}`}>
      <button
        className="back-button"
        onClick={() => navigate(-1)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {/* Убираем "back" и оставляем только перевод, если нужен текст */}
        {translations.supportPage.backSupportButton}
      </button>
    </div>
  );
};

export default BackButton;