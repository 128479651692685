import React from "react";
import "./AppLoader.css";

const AppLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader-content">
                <img
                    className="app-logo"
                    src="/images/WeeekendVisitka.gif" // Укажите правильный путь к вашему логотипу
                    alt="App Logo"
                />

            </div>
        </div>
    );
};

export default AppLoader;
