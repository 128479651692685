import React from "react";
import Avatar from "../Shared/Avatar";
import "../css/MessageItem.css";

const VoiceMessage = ({ audioUrl }) => {
  const audioRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    if (audioUrl) {
      console.log("Audio URL:", audioUrl);
      audioRef.current = new Audio(audioUrl);
      audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    } else {
      console.error("Audio URL is undefined or empty");
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("ended", () => setIsPlaying(false));
      }
    };
  }, [audioUrl]);

  const togglePlay = (e) => {
    e.stopPropagation();
    const audio = audioRef.current;
    if (!audio) {
      console.error("Audio element is not initialized");
      return;
    }
    if (audio.paused) {
      audio.play()
        .then(() => {
          console.log("Audio playing");
          setIsPlaying(true);
        })
        .catch(error => console.error("Ошибка воспроизведения:", error));
    } else {
      audio.pause();
      console.log("Audio paused");
      setIsPlaying(false);
    }
  };

  if (!audioUrl) {
    return <div>Голосовое сообщение недоступно</div>;
  }

  return (
    <div className="voice-message-player">
      <button className="play-btn" onClick={togglePlay}>
        <svg viewBox="0 0 24 24" width="20" height="20">
          {isPlaying ? (
            <rect x="6" y="6" width="12" height="12" fill="currentColor" />
          ) : (
            <polygon points="5,5 19,12 5,19" fill="currentColor" />
          )}
        </svg>
      </button>
    </div>
  );
};

const MessageItem = ({
  msg,
  currentUserId,
  userData,
  editingMessageId,
  editedText,
  setEditingMessageId,
  setEditedText,
  likedMessageId,
  onContextMenu,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
  onDoubleClick,
  onEmojiClick,
  handleEdit,
  handleDelete,
  handlePin,
  handleForward,
}) => {
  const isSent = msg.sender === currentUserId;
  const isEditing = editingMessageId === msg.id;

  return (
    <div
      className={`message-item ${isSent ? "sent" : "received"} ${
        !msg.isRead && !isSent ? "unread" : ""
      } ${msg.pinned ? "pinned" : ""} ${likedMessageId === msg.id ? "double-clicked" : ""}`}
      onContextMenu={(e) => onContextMenu(e, msg)}
      onTouchStart={(e) => onTouchStart(e, msg)}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      onDoubleClick={() => onDoubleClick(msg)}
      onClick={(e) => onEmojiClick(e, msg)}
    >
      <Avatar userId={msg.sender} avatar={msg.senderAvatar} />
      <div className="message-content">
        <span className="message-sender">{msg.senderName}</span>
        {isEditing ? (
          <div className="edit-container">
            <input
              type="text"
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
            />
            <button onClick={() => handleEdit(msg.id, editedText)}>Сохранить</button>
            <button onClick={() => setEditingMessageId(null)}>Отмена</button>
          </div>
        ) : (
          <>
            {msg.type === "audio" ? (
              <VoiceMessage audioUrl={msg.audioUrl} />
            ) : (
              <p className="message-text">{msg.text}</p>
            )}
            <span className="message-time">
              {msg.createdAt ? new Date(msg.createdAt.toDate()).toLocaleTimeString() : ""}
              {isSent && (msg.isRead ? " ✓✓" : " ✓")}
              {msg.likedBy && msg.likedBy.length > 0 && ` 👍 ${msg.likedBy.length}`}
            </span>
            {msg.edited && <span className="edited-label"> (ред.)</span>}
            {msg.forwarded && <span className="forwarded-label">Переслано</span>}
          </>
        )}
        {isSent && !isEditing && (
          <div className="message-actions">
            <button onClick={() => handlePin(msg.id, msg.pinned)}>
              {msg.pinned ? "Открепить" : "Закрепить"}
            </button>
            <button
              onClick={() => {
                setEditingMessageId(msg.id);
                setEditedText(msg.text);
              }}
            >
              Редактировать
            </button>
            <button onClick={() => handleDelete(msg.id)}>Удалить</button>
            <button onClick={() => handleForward(msg)}>Переслать</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageItem;