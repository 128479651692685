import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { db, auth } from '../../../firebase/firebase';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import Avatar from "../Shared/Avatar";
import FollowModal from "./FollowModal";
import './css/MiniProfile.css';

const MiniProfile = () => {
  const [userData, setUserData] = useState({
    name: '',
    avatars: [],
    selectedAvatarIndex: 0,
    subscriptions: []
  });
  const [subscribers, setSubscribers] = useState([]); // Отдельное состояние для подписчиков
  const [notifications, setNotifications] = useState([]);
  const [showSubscribers, setShowSubscribers] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) return;

    // Подписка на данные пользователя
    const userDoc = doc(db, "users", auth.currentUser.uid);
    const unsubscribe = onSnapshot(userDoc, (snap) => {
      if (snap.exists()) {
        const data = snap.data();
        setUserData({
          name: data.name || "Я",
          avatars: data.avatars || [],
          selectedAvatarIndex: data.selectedAvatarIndex || 0,
          subscriptions: data.subscriptions || [],
        });
      }
    });

    // Подписка на уведомления
    const notificationsRef = collection(db, "users", auth.currentUser.uid, "notifications");
    const unsubscribeNotifications = onSnapshot(notificationsRef, (snap) => {
      const notificationsData = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData.filter(n => !n.isRead));
    });

    // Подписка на подписчиков
    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snap) => {
      const subscribersList = snap.docs
        .filter(doc => doc.data().subscriptions?.includes(auth.currentUser.uid))
        .map(doc => ({
          uid: doc.id,
          name: doc.data().name || doc.id,
          avatar: doc.data().avatars?.[doc.data().selectedAvatarIndex] || doc.data().avatar || 'https://placehold.co/50x50',
        }));
      setSubscribers(subscribersList); // Обновляем отдельное состояние
    });

    return () => {
      unsubscribe();
      unsubscribeNotifications();
      unsubscribeUsers();
    };
  }, []);

  const avatarUrl = userData.avatars[userData.selectedAvatarIndex] || '';

  const handleProfileClick = () => navigate(`/profile/${auth.currentUser.uid}`);
  const handleSubscriptionsClick = () => setShowSubscriptions(true);
  const handleSubscribersClick = () => setShowSubscribers(true);
  const handleNotificationsClick = () => {
    if (notifications.length > 0) {
      const latestNotification = notifications[0];
      if (latestNotification.type === 'like' || latestNotification.type === 'comment') {
        navigate(`/profile/${auth.currentUser.uid}`, { state: { postId: latestNotification.contentId } });
      }
    }
  };

  const closeModal = () => {
    setShowSubscribers(false);
    setShowSubscriptions(false);
  };

  return (
    <div className="mini-profile">
      <Avatar avatar={avatarUrl} userId={auth.currentUser.uid} />
      <div className="mini-profile-info">
        <span className="mini-profile-name" onClick={handleProfileClick}>{userData.name}</span>
        <div className="mini-profile-stats">
          <span onClick={handleSubscriptionsClick}>Подписки: {userData.subscriptions.length}</span>
          <span onClick={handleSubscribersClick}>Подписчики: {subscribers.length}</span>
          <span className="notifications" onClick={handleNotificationsClick}>
            🔔 {notifications.length > 0 && <span className="notification-count">{notifications.length}</span>}
          </span>
        </div>
      </div>
      {(showSubscribers || showSubscriptions) && (
        <FollowModal
          showSubscribers={showSubscribers}
          subscribers={subscribers}
          subscriptions={userData.subscriptions.map(uid => ({
            uid,
            name: uid,
            avatar: 'https://placehold.co/50x50',
          }))}
          closeModal={closeModal}
          navigate={navigate}
        />
      )}
    </div>
  );
};

export default MiniProfile;