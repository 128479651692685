import { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { useDispatch } from 'react-redux';
import { db } from '../../../firebase/firebase';
import { doc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import { useAuth } from "../../../Services/context/AuthContext";
import useChatData from "../../../Services/hooks/useChatData";
import { setActiveChat } from "../../../redux/chatSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ChatHistory from "../../Chat/chat/ChatHistory";
import ChatWindow from "../chat/ChatWindow";
import GroupChat from "../Group/GroupChat";
import GroupCreate from "../Group/GroupCreate";
import MiniProfile from "../Profile/MiniProfile";
import "../css/ChatStyles.css";

const GroupList = lazy(() => import("../Group/GroupList"));

const ChatContainer = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { chats } = useChatData(currentUser?.uid);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [unreadChats, setUnreadChats] = useState([]);
  const [showGroupCreate, setShowGroupCreate] = useState(false);
  const [showGroups, setShowGroups] = useState(false); // Состояние для отображения групп
  const [stats, setStats] = useState({ totalUsers: 0, onlineUsers: 0 });
  const [isMobileChatOpen, setIsMobileChatOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    const usersRef = collection(db, "users");
    const unsubscribe = onSnapshot(usersRef, (snap) => {
      const total = snap.size;
      const online = snap.docs.filter(doc => doc.data().online).length;
      setStats({ totalUsers: total, onlineUsers: online });
    });

    const { state } = location;
    if (state?.chatId) {
      if (state.selectedUser) {
        setChatId(state.chatId);
        setSelectedUser(state.selectedUser);
        setSelectedGroup(null);
        setIsMobileChatOpen(true);
        dispatch(setActiveChat(state.chatId));
      } else if (state.selectedGroup) {
        setChatId(state.chatId);
        setSelectedGroup(state.selectedGroup);
        setSelectedUser(null);
        setIsMobileChatOpen(true);
        dispatch(setActiveChat(state.chatId));
      }
    }

    return () => unsubscribe();
  }, [currentUser, location, dispatch]);

  const selectUser = useCallback(async (user, id) => {
    if (!currentUser || !user || !user.uid) return;
    let finalChatId = id;
    if (!id) {
      finalChatId = user.uid === "WeeekendAsistans"
        ? `${currentUser.uid}_WeeekendAsistans`
        : [currentUser.uid, user.uid].sort().join("_");
      const chatRef = doc(db, "chats", finalChatId);
      await setDoc(chatRef, { users: [currentUser.uid, user.uid], pinned: true }, { merge: true });
    }
    setChatId(finalChatId);
    setSelectedUser(user);
    setSelectedGroup(null);
    setIsMobileChatOpen(true);
    dispatch(setActiveChat(finalChatId));
    navigate(`/chat`, { state: { chatId: finalChatId, selectedUser: user } });
  }, [currentUser, dispatch, navigate]);

  const selectGroup = useCallback(async (group) => {
    if (!currentUser || !group || !group.id) return;
    const chatId = `${currentUser.uid}_${group.id}`;
    const chatRef = doc(db, "chats", chatId);
    await setDoc(chatRef, { users: group.members, isGroup: true, groupId: group.id, pinned: true }, { merge: true });
    setSelectedGroup(group);
    setSelectedUser(null);
    setChatId(chatId);
    setIsMobileChatOpen(true);
    dispatch(setActiveChat(chatId));
    navigate(`/chat`, { state: { chatId, selectedGroup: group } });
  }, [currentUser, dispatch, navigate]);

  const handleGroupCreated = useCallback((group) => {
    if (!group || !group.id) return;
    setSelectedGroup(group);
    setSelectedUser(null);
    setChatId(null);
    setShowGroupCreate(false);
  }, []);

  const handleBack = () => {
    setSelectedUser(null);
    setSelectedGroup(null);
    setChatId(null);
    setIsMobileChatOpen(false);
    navigate('/chat');
  };

  if (!currentUser) return null;

  return (
    <div className="chat-container">
      <div className={`sidebar ${isMobileChatOpen ? 'hidden-mobile' : ''}`}>
        <MiniProfile />
        <div className="chat-stats">
          <span>Всего: {stats.totalUsers}</span>
          <span>Онлайн: {stats.onlineUsers}</span>
        </div>
        <div className="sidebar-toggle">
          <button
            className={`toggle-btn ${!showGroups ? 'active' : ''}`}
            onClick={() => setShowGroups(false)}
          >
            Чаты
          </button>
          <button
            className={`toggle-btn ${showGroups ? 'active' : ''}`}
            onClick={() => setShowGroups(true)}
          >
            Группы
          </button>
        </div>
        {showGroups ? (
          <Suspense fallback={<div className="loading">Загрузка групп...</div>}>
            <GroupList onSelectGroup={selectGroup} />
          </Suspense>
        ) : (
          <ChatHistory
            onSelectChat={selectUser}
            chats={chats.filter(chat => !chat.isGroup)}
          />
        )}
        <button className="create-group-btn" onClick={() => setShowGroupCreate(true)}>
          +
        </button>
        {showGroupCreate && (
          <div className="modal-overlay sidebar-modal">
            <GroupCreate
              onGroupCreated={handleGroupCreated}
              onClose={() => setShowGroupCreate(false)}
            />
          </div>
        )}
      </div>
      <div className={`chat-content ${isMobileChatOpen ? '' : 'hidden-mobile'}`}>
        <Suspense fallback={<div className="loading">Загрузка...</div>}>
          {selectedGroup ? (
            <GroupChat group={selectedGroup} onBack={handleBack} />
          ) : selectedUser ? (
            <ChatWindow chatId={chatId} selectedUser={selectedUser} onBack={handleBack} chats={chats} />
          ) : (
            <div className="chat-placeholder">Выберите чат или группу</div>
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default ChatContainer;