import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './LikeStyles.css';

const LikeComponent = ({ objectId, like_collection }) => {
    const [liked, setLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null); // Храним пользователя

    useEffect(() => {
        // Подписка на изменения статуса авторизации
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe(); // Отписываемся при размонтировании
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const objectRef = doc(db, like_collection, objectId);
                const objectSnap = await getDoc(objectRef);

                if (objectSnap.exists()) {
                    setLikesCount(objectSnap.data().likesCount || 0);
                }

                if (user) {
                    const userRef = doc(db, 'users', user.uid);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userLikes = userSnap.data().likes || [];
                        setLiked(userLikes.includes(objectId));
                    }
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных лайков:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [objectId, user]); // Теперь следим за user

    const handleClick = async () => {
        if (!user) {
            alert('Вам нужно авторизоваться, чтобы поставить лайк.');
            return;
        }

        const userRef = doc(db, 'users', user.uid);
        const objectRef = doc(db, like_collection, objectId);

        const previousLiked = liked;
        const previousLikesCount = likesCount;

        setLiked(!liked);
        setLikesCount(liked ? likesCount - 1 : likesCount + 1);

        try {
            if (liked) {
                await updateDoc(userRef, { likes: arrayRemove(objectId) });
                await updateDoc(objectRef, { likesCount: Math.max(0, likesCount - 1) });
            } else {
                await updateDoc(userRef, { likes: arrayUnion(objectId) });
                await updateDoc(objectRef, { likesCount: likesCount + 1 });
            }
        } catch (error) {
            console.error('Ошибка при обновлении лайков:', error);
            setTimeout(() => {
                setLiked(previousLiked);
                setLikesCount(previousLikesCount);
            }, 300);
        }
    };

    return (
        <div className="like__container" onClick={handleClick}>
            <svg className={`hearth ${liked ? 'pulse' : ''}`} viewBox="0 0 24 24">
                <path
                    d="M16.5 4.5C19.54 4.5 22 6.95 22 9.96C22 13.54 19.86 16.82 15.05 20L12.27 21.82C12.11 21.93 11.89 21.93 11.73 21.82L8.95 20C4.14 16.82 2 13.54 2 9.96C2 6.95 4.46 4.5 7.5 4.5C9.32 4.5 10.99 5.39 12 6.82C13.01 5.39 14.68 4.5 16.5 4.5Z"
                    fill={liked ? 'red' : 'none'}
                />
            </svg>
            <div className={`like_count ${liked ? 'count-up' : 'count-down'}`}>{loading ? '...' : likesCount}</div>
        </div>
    );
};

export default LikeComponent;
