import { createContext, useState } from "react";

const filter = {
    RENT: "аренда",
    PURCHASE: "продажа"
};

export const GlobalFilterContext = createContext({
    dealTypeFilter: filter.RENT,
    toggleGlobalFilter: () => {},
    isRentType: () => {}
});

export const GlobalFilterProvider = ({ children }) => {
    const [dealTypeFilter, setDealTypeFilter] = useState(localStorage.getItem('weeekend_global_filter') || filter.RENT);

    const toggleGlobalFilter = () => {
        let newFilterType = dealTypeFilter;
        switch (dealTypeFilter) {
            case filter.RENT:
                newFilterType = filter.PURCHASE;
                break;
            case filter.PURCHASE:
                newFilterType = filter.RENT;
                break;
        }

        localStorage.setItem('weeekend_global_filter', newFilterType);
        setDealTypeFilter(newFilterType);
    };

    const isRentType = () => {
        return dealTypeFilter === filter.RENT;
    }

    return (
        <GlobalFilterContext.Provider value={{dealTypeFilter, toggleGlobalFilter, isRentType}}>
            {children}
        </GlobalFilterContext.Provider>
    )
};