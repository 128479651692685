import React, {useCallback, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import '../Form.css';
import partnerForm from "../../Form/PartnerForm/PartnerForm"; // Подключаем стили
import {auth,db,collection} from "../../../firebase/firebase";
import {doc,addDoc, getDoc,updateDoc, arrayUnion } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {LocalizationContext} from "../../../Services/localization/LocalizationContext";

const CarForm = ({ onClose, objectId, carModel, carName, daysCount, price, carCompany,startDate,endDate}) => { // Добавляем objectId как пропс
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState(false);
    const [telegram, setTelegram] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [instagramLogin, setInstagramLogin] = useState('');
    const [telegramLogin, setTelegramLogin] = useState('');
    const [whatsappLogin, setWhatsappLogin] = useState('');
    const { translations } = useContext(LocalizationContext);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false); // Для отображения анимации успеха
    const [isAutoSubmit, setIsAutoSubmit] = useState(false);
    // const navigate = useNavigate();


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('ru-RU', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }).format(date);
    };
    const chatId = '-1002135710194'; // Ваш chat_id
    const botToken = '7356584757:AAFMITZXblh8k-FsOJdUK4yr62sUmAxG4gw'; // Ваш токен бота
    const total =  daysCount * price;
    const [errorMessage, setErrorMessage] = useState('');
     const validateFields = () => {
        if (!phone && !instagramLogin && !telegramLogin && !whatsappLogin) {
            setErrorMessage('Укажите контактный номер телефона или логин (Telegram, Instagram, Whatsapp)');
            return false;
        }
        setErrorMessage('');
        return true;
    };
    // Функция для отправки данных в Telegram
    const onSendData = useCallback(() => {
         if (!validateFields()) return;
        const message = `
         🚗  Аренда авто:
         
         
   Info about client:
👤 Name: ${name}
📞 Phone: ${phone}
📸 Instagram: ${instagram ? `Yes (Login: ${instagramLogin})` : 'No'}
📱 Telegram: ${telegram ? `Yes (Login: ${telegramLogin})` : 'No'}
💬 Whatsapp: ${whatsapp ? `Yes (Login: ${whatsappLogin})` : 'No'}
 
 
 Info about car:
🚗 Car Name: ${carName}
🚙 Car Model: ${carModel}
🆔 Object ID: ${objectId}
🗓️ Day Count: ${daysCount}
🗓️ Start rent: ${formatDate(startDate)}
🗓️ End rent: ${formatDate(endDate)}
💵 Price Per Day: ${price}
💰 Total: ${total}

🏢Company: ${carCompany}
`;


console.log(message);

        axios
            .post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            chat_id: chatId,
            text: message,
        })
            .then(response => {
                console.log('chat sent successfully:', response);
                setIsSubmitted(true);
                setShowSuccess(true); // Показываем анимацию успеха

            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    }, [name, phone, instagram, telegram, whatsapp, instagramLogin, telegramLogin, whatsappLogin, botToken, chatId, objectId, onClose,carCompany]);

    // Обработчики изменений инпутов
    const onChangeName = (e) => setName(e.target.value);
    const onChangePhone = (e) => setPhone(e.target.value);
    const onChangeInstagram = (e) => setInstagram(e.target.checked);
    const onChangeTelegram = (e) => setTelegram(e.target.checked);
    const onChangeWhatsapp = (e) => setWhatsapp(e.target.checked);
    const onChangeInstagramLogin = (e) => setInstagramLogin(e.target.value);
    const onChangeTelegramLogin = (e) => setTelegramLogin(e.target.value);
    const onChangeWhatsappLogin = (e) => setWhatsappLogin(e.target.value);


    const fetchUserData = useCallback(async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        try {
            const userRef = doc(db, "users", currentUser.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.phone_num) setPhone(userData.phone_num);
                if (userData.messenger) {
                    setInstagramLogin(userData.messenger.instagram || '');
                    setTelegramLogin(userData.messenger.telegram || '');
                    setWhatsappLogin(userData.messenger.whatsapp || '');
                    setInstagram(!!userData.messenger.instagram);
                    setTelegram(!!userData.messenger.telegram);
                    setWhatsapp(!!userData.messenger.whatsapp);
                }

                // Если у пользователя уже есть контактные данные, вызываем handleSubmit
                if (userData.phone_num || userData.messenger) {
                    setIsAutoSubmit(true);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    // useEffect(() => {
    //     if (isAutoSubmit) {
    //         handleSubmit();
    //     }
    // }, [isAutoSubmit]);



    const sendObject = useCallback(async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const userRef = doc(db, "users", currentUser.uid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();

                    // Проверяем, существует ли поле UserOrders
                    if (userData.UserOrders) {
                        // Если существует, добавляем objectId в массив
                        await updateDoc(userRef, {
                            UserOrders: arrayUnion(objectId),
                        });
                    } else {
                        // Если отсутствует, создаем массив с objectId
                        await updateDoc(userRef, {
                            UserOrders: [objectId],
                        });
                    }

                    console.log("Object ID successfully added to UserOrders");
                } else {
                    console.error("User document does not exist");
                }
            } catch (error) {
                console.error("Error updating UserOrders:", error);
            }
        } else {
            console.error("No user is logged in");
        }
    }, [objectId]);
    const sendOrder = async (objectId) => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("No user is logged in");
            return;
        }

        try {
            const userRef = doc(db, "users", currentUser.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                // Обновляем или создаем поле UserOrders
                if (userData.UserOrders) {
                    await updateDoc(userRef, {
                        UserOrders: arrayUnion(objectId),
                    });
                } else {
                    await updateDoc(userRef, {
                        UserOrders: [objectId],
                    });
                }
                console.log("Object ID successfully added to UserOrders");

                // Создаем новый документ в коллекции "orders"
                const orderData = {
                    userId: currentUser.uid, // ID текущего пользователя
                    objectId: objectId, // ID объекта
                    orderStatus: "pending", // Статус заказа
                    orderDate: new Date(), // Время создания заказа
                    startDate: startDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }), // MM/DD
                    endDate: endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }),
                };

                const ordersCollectionRef = collection(db, "orders");
                await addDoc(ordersCollectionRef, orderData);

                console.log("New order successfully added to the 'orders' collection");
            } else {
                console.error("User document does not exist");
            }
        } catch (error) {
            console.error("Error updating UserOrders or adding new order:", error);
        }
    };


    const updateUserContactInfo = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("No user is logged in");
            return;
        }

        try {
            const userRef = doc(db, "users", currentUser.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                let updates = {};

                if (!userData.phone_num && phone) {
                    updates.phone_num = phone;
                }

                let messenger = userData.messenger || [];
                const updatedMessenger = {
                    instagram: instagram ? instagramLogin : "",
                    telegram: telegram ? telegramLogin : "",
                    whatsapp: whatsapp ? whatsappLogin : ""
                };

                if (!userData.messenger || JSON.stringify(userData.messenger) !== JSON.stringify(updatedMessenger)) {
                    updates.messenger = updatedMessenger;
                }

                if (Object.keys(updates).length > 0) {
                    await updateDoc(userRef, updates);
                    console.log("User contact info updated successfully");
                } else {
                    console.log("No updates needed for user contact info");
                }
            } else {
                console.error("User document does not exist");
            }
        } catch (error) {
            console.error("Error updating user contact info:", error);
        }
    };



    const handleSubmit = () => {
        onSendData();
        sendObject();
        sendOrder(objectId)

        updateUserContactInfo()

    };
    return (
        <div className="Form">
            <div className={isSubmitted ? 'hidden' : 'visible'}>
                <h2 className="h2">🏷️{translations.datePicker.book}</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Сообщение об ошибке */}

                <div className="input-group">
                    <input
                        className="input"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={onChangeName}
                        required
                    />
                </div>

                <div className="input-group">
                    <input
                        className="input"
                        type="text"
                        placeholder="Phone number"
                        value={phone}
                        onChange={onChangePhone}
                        required
                    />

                </div>


                <div className="input-group">
                    <label>
                        <input type="checkbox" checked={instagram} onChange={onChangeInstagram} />
                        Instagram
                    </label>
                    {instagram && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Instagram Login"
                            value={instagramLogin}
                            onChange={onChangeInstagramLogin}
                        />
                    )}
                </div>

                <div className="input-group">
                    <label>

                        <input type="checkbox" checked={telegram} onChange={onChangeTelegram} />
                        Telegram
                    </label>
                    {telegram && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Telegram Login"
                            value={telegramLogin}
                            onChange={onChangeTelegramLogin}
                        />
                    )}
                </div>

                <div className="input-group">
                    <label>
                        <input type="checkbox" checked={whatsapp} onChange={onChangeWhatsapp} />
                        Whatsapp
                    </label>
                    {whatsapp && (
                        <input
                            className="input"
                            type="text"
                            placeholder="Whatsapp Login"
                            value={whatsappLogin}
                            onChange={onChangeWhatsappLogin}
                        />
                    )}
                </div>

                <button className="send-button" onClick={handleSubmit}>
                    Send an application
                </button>
            </div>

            {/* Анимация успеха */}
            {showSuccess && (
                <div className="success-animation">
                    <div className="checkmark">
                        <span>&#10004;</span>
                    </div>
                    <h3>Благодарю, ваша заявка отправлена!</h3>
                    {/* Кнопка для закрытия уведомления */}
                    <div className={'user-info-container'}>
                        <div>mobile phone: {phone}</div>
                        <div>telegram: {telegramLogin}</div>
                        <div>whatsapp: {whatsappLogin}</div>
                        <div>instagram: {instagramLogin}</div>
                    </div>

                    <div className={'DisGrid'}>
                        <button
                            className="close-notification"
                            onClick={() => window.open('https://t.me/weeekendme', '_blank')}
                        >
                            {translations.profilePage.support}
                        </button>
                        {/*<button*/}
                        {/*    className="close-notification"*/}
                        {/*    // onClick={() => navigate('/orders')}*/}
                        {/*>*/}
                        {/*    Мои заказы*/}
                        {/*</button>*/}
                        {/*<button*/}
                        {/*    className="close-notification"*/}
                        {/*    // onClick={() => navigate('/profile')}*/}
                        {/*>*/}
                        {/*    Редактировать профиль*/}
                        {/*</button>*/}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CarForm;
