import React, { useState } from 'react';
import SignUpForm from './SignUpForm/SignUpForm';
import LoginForm from './LoginForm/LoginForm';
import './AuthPage.css';
import { useLocation } from 'react-router-dom';
import LoginComponent from "./LoginForm/LoginComponent";
import SignupComponent from "./SignUpForm/SignupComponent";
const AuthPage = ({ onClose }) => { // Принимаем onClose как пропс
    const [activeForm, setActiveForm] = useState('login'); // Управляет текущим отображаемым формуляром

    const handleFormSwitch = (form) => {
        setActiveForm(form);
    };
    const location = useLocation();
    console.log(location)// Получаем текущий маршрут
    return (
        <div className={'auth-page-wrapper'}>
            <div className="auth-page-container">
                <div className="auth-page-header">

                    <div className={'disFlex authpage-switch-container'}>
                        <h2
                        className={activeForm === 'login' ? 'active-link' : 'login-switch'}
                        onClick={() => handleFormSwitch('login')}
                    >
                        Login
                    </h2>
                        <h2
                            className={activeForm === 'signup' ? 'active-link' : 'login-switch'}
                            onClick={() => handleFormSwitch('signup')}
                        >
                            Sign Up
                        </h2>
                    </div>
                    {location.pathname !== '/profile' && (
                        <button className="auth-close-button" onClick={onClose}>×</button>
                    )}
                </div>

                <div className="auth-page-body">
                    {activeForm === 'signup' ? <SignupComponent onClose={onClose}/> :
                        <LoginComponent onClose={onClose}/>}
                </div>
            </div>
        </div>

    );
};

export default AuthPage;
