import { useState, useContext } from 'react';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import './ShareComponent.css'; // Предполагаю, что файл называется ShareButton.css

function ShareComponent({ title = 'Поделиться' }) {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const { translations } = useContext(LocalizationContext);

  const toggleShareMenu = () => {
    setIsShareOpen(!isShareOpen);
  };

  const shareToTwitter = () => {
    const text = translations.checkOutApartment || 'Check out this apartment!';
    const url = window.location.href;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`, '_blank');
    setIsShareOpen(false);
  };

  const shareToFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
    setIsShareOpen(false);
  };

  const shareToWhatsApp = () => {
    const text = `${translations.checkOutApartment || 'Check out this apartment:'} ${window.location.href}`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
    setIsShareOpen(false);
  };

  const shareToTelegram = () => {
    const text = `${translations.checkOutApartment || 'Check out this apartment:'} ${window.location.href}`;
    window.open(`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(text)}`, '_blank');
    setIsShareOpen(false);
  };

  const shareToInstagram = () => {
    const copyText = translations.linkCopied || 'Link copied to clipboard! Open Instagram to share.';
    navigator.clipboard.writeText(window.location.href);
    alert(copyText);
    setIsShareOpen(false);
  };

  return (
    <div className="cdc-share-container">
      <noscript>
        <div className="cdc-no-js">
          {translations.noJs || 'JavaScript is not available. Please enable JavaScript or switch to a supported browser.'}
        </div>
      </noscript>
      <button className="cdc-share-main-btn" onClick={toggleShareMenu}>
        <svg className="cdc-share-icon" viewBox="0 0 24 24" width="20" height="20">
          <path fill="white" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM6 13c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm12 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
        </svg>
        {translations.carDetail.share}
      </button>

      {isShareOpen && (
        <div className="cdc-share-overlay" onClick={toggleShareMenu}>
          <div className="cdc-share-menu" onClick={(e) => e.stopPropagation()}>
            <button className="cdc-share-btn cdc-share-twitter" onClick={shareToTwitter}>
              <svg className="cdc-share-icon" viewBox="0 0 24 24">
                <path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
              Twitter
            </button>

            <button className="cdc-share-btn cdc-share-facebook" onClick={shareToFacebook}>
              <svg className="cdc-share-icon" viewBox="0 0 24 24">
                <path fill="white" d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103v3.149h-1.808c-.844 0-1.235.48-1.235 1.677v2.629h2.99l-.498 3.667h-2.492v7.98H9.101z"/>
              </svg>
              Facebook
            </button>

            <button className="cdc-share-btn cdc-share-whatsapp" onClick={shareToWhatsApp}>
              <svg className="cdc-share-icon" viewBox="0 0 24 24">
                <path fill="white" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91c0 1.33.27 2.61.79 3.79l-1.01 3.66 3.76-1c1.13.65 2.41 1 3.87 1 5.46 0 9.91-4.45 9.91-9.91 0-2.64-1.03-5.13-2.9-7-1.87-1.87-4.36-2.9-7-2.9zm4.71 14.24c-.24.67-.73 1.2-1.37 1.53-.64.33-1.47.5-2.44.27-1.03-.24-1.91-.95-2.64-1.91-.73-.95-1.2-2.08-1.44-3.38-.24-1.3.06-2.41.84-3.17.54-.61 1.2-.91 1.91-.91.73 0 1.37.24 1.91.73.54.48.91 1.13 1.08 1.91.12.54-.06 1.13-.67 1.53l-.42.27c-.18.12-.42.06-.54-.12-.12-.18-.48-.61-.84-1.08-.36-.48-.73-.54-1.08-.36l-.42.27c-.48.3-.91.73-1.2 1.2-.3.48-.36.95-.18 1.44.18.48.67.91 1.37 1.2 1.62 1.08 3.5 1.62 5.45 1.62 1.37 0 2.56-.36 3.5-1.08.95-.73 1.44-1.74 1.37-2.95z"/>
              </svg>
              WhatsApp
            </button>

            <button className="cdc-share-btn cdc-share-telegram" onClick={shareToTelegram}>
              <svg className="cdc-share-icon" viewBox="0 0 24 24">
                <path fill="white" d="M11.944 1.012c-5.514 0-9.984 4.47-9.984 9.984 0 5.513 4.47 9.983 9.984 9.983 5.513 0 9.983-4.47 9.983-9.983 0-5.514-4.47-9.984-9.983-9.984zm4.664 7.52l-1.34 6.314c-.1.447-.62.58-.943.267l-2.34-1.723-1.126 1.087c-.125.12-.233.227-.476.227l.167-2.387 4.347-3.934c.19-.167-.042-.26-.293-.094L7.65 11.15l-2.26-.706c-.39-.123-.4-.447.082-.663l8.82-3.4c.32-.14.62.08.62.447z"/>
              </svg>
              Telegram
            </button>

            <button className="cdc-share-btn cdc-share-instagram" onClick={shareToInstagram}>
              <svg className="cdc-share-icon" viewBox="0 0 24 24">
                <path fill="white" d="M12 2.163c3.204 0 3.584.012 4.85.07 1.366.062 2.633.326 3.608 1.301.975.975 1.24 2.242 1.301 3.608.058 1.265.07 1.645.07 4.849 0 3.205-.012 3.584-.07 4.85-.062 1.366-.326 2.633-1.301 3.608-1.301.975-1.24 2.242-1.301 3.608-.058 1.265-.07 1.645-.07 4.849 0 3.204.012 3.584.07 4.85.062 1.366.326 2.633 1.301 3.608.975.975 1.24 2.242 1.301 3.608-.058 1.265-.07 1.645-.07 4.849 0zm0 2.163c-3.259 0-5.923 2.662-5.923 5.923 0 3.259 2.662 5.923 5.923 5.923 3.259 0 5.923-2.662 5.923-5.923 0-3.259-2.662-5.923-5.923-5.923zm0 9.721c-2.067 0-3.74-1.673-3.74-3.74 0-2.067 1.673-3.74 3.74-3.74 2.067 0 3.74 1.673 3.74 3.74 0 2.067-1.673 3.74-3.74 3.74zm6.236-9.236c0 .732-.598 1.33-1.33 1.33s-1.33-.598-1.33-1.33.598-1.33 1.33-1.33 1.33.598 1.33 1.33z"/>
              </svg>
              Instagram
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShareComponent;