import './css/UserProfileStoriesWall.css';
import { useState } from 'react';

const ProfileWall = ({
                       auth,
                       userId,
                       posts,
                       newPost,
                       setNewPost,
                       wallComments,
                       newWallComment,
                       setNewWallComment,
                       handlePostSubmit,
                       handleWallCommentSubmit,
                       handleCommentSubmit,
                       handleLike,
                       handlePostDelete = () => console.log('handlePostDelete not implemented'),    // Обработчик по умолчанию
                       handlePostEdit = () => console.log('handlePostEdit not implemented'),       // Обработчик по умолчанию
                       handleCommentDelete = () => console.log('handleCommentDelete not implemented'),  // Обработчик по умолчанию
                       handleWallCommentDelete = () => console.log('handleWallCommentDelete not implemented')  // Обработчик по умолчанию
                     }) => {
  const [editingPostId, setEditingPostId] = useState(null);
  const [editedPostText, setEditedPostText] = useState('');

  const startEditing = (post) => {
    setEditingPostId(post.id);
    setEditedPostText(post.text);
  };

  const submitEdit = (postId) => {
    handlePostEdit(postId, editedPostText);
    setEditingPostId(null);
    setEditedPostText('');
  };

  return (
      <div className="user-profile-wall">
        <h3>Стена</h3>
        {auth.currentUser?.uid === userId && (
            <div className="post-form">
              <textarea value={newPost} onChange={(e) => setNewPost(e.target.value)} placeholder="Что нового?" />
              <button onClick={handlePostSubmit}>Опубликовать</button>
            </div>
        )}
        {posts.map(post => (
            <div key={post.id} className="post">
              {editingPostId === post.id ? (
                  <div className="edit-form">
              <textarea
                  value={editedPostText}
                  onChange={(e) => setEditedPostText(e.target.value)}
              />
                    <button onClick={() => submitEdit(post.id)}>Сохранить</button>
                    <button onClick={() => setEditingPostId(null)}>Отмена</button>
                  </div>
              ) : (
                  <>
                    <p>{post.text}</p>
                    <span>{new Date(post.createdAt.toDate()).toLocaleString()}</span>
                    <div className="post-actions">
                      <button
                          onClick={() => handleLike(post.id)}
                          className={post.likes.includes(auth.currentUser?.uid) ? 'liked' : ''}
                      >
                        ♥ {post.likes.length}
                      </button>
                      {auth.currentUser?.uid === userId && (
                          <>
                            <button onClick={() => startEditing(post)}>Редактировать</button>
                            <button onClick={() => handlePostDelete(post.id)}>Удалить</button>
                          </>
                      )}
                    </div>
                  </>
              )}
              <div className="comments">
                {post.comments.map((comment, index) => (
                    <div key={index} className="comment">
                      <p><strong>{comment.author}</strong>: {comment.text}</p>
                      <span>{new Date(comment.createdAt.toDate()).toLocaleString()}</span>
                      {auth.currentUser?.uid === userId && (
                          <button onClick={() => handleCommentDelete(post.id, index)}>
                            Удалить
                          </button>
                      )}
                    </div>
                ))}
                <form onSubmit={(e) => {
                  e.preventDefault();
                  const commentText = e.target.comment.value;
                  handleCommentSubmit(post.id, commentText);
                  e.target.reset();
                }}>
                  <input type="text" name="comment" placeholder="Комментарий..." />
                  <button type="submit">Отправить</button>
                </form>
              </div>
            </div>
        ))}
        <div className="wall-comments-section">
          <h3>Комментарии</h3>
          <form onSubmit={(e) => { e.preventDefault(); handleWallCommentSubmit(); }}>
            <textarea value={newWallComment} onChange={(e) => setNewWallComment(e.target.value)} placeholder="Оставить комментарий..." />
            <button type="submit" disabled={!newWallComment.trim()}>Отправить</button>
          </form>
          <div className="wall-comments">
            {wallComments.map(comment => (
                <div key={comment.id} className="wall-comment">
                  <p><strong>{comment.author}</strong>: {comment.text}</p>
                  <span>{new Date(comment.createdAt.toDate()).toLocaleString()}</span>
                  {auth.currentUser?.uid === userId && (
                      <button onClick={() => handleWallCommentDelete(comment.id)}>
                        Удалить
                      </button>
                  )}
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default ProfileWall;