import React, { useRef, useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './map.css';
import './animatedPopup.css';
import icon from '../img/Point.svg';
import CategoryBarMap from '../CategoryBar/CategoryBarMap/CategoryBarMap';
import { CategoryContext } from '../../App';
import useMap from '../hooks/useMap';
import usePopups from '../hooks/usePopups';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import CarCard from '../Cards/CarCard/CarCard';
import FlatCard from '../Cards/FlatCard/FlatCard';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

function PopupPortal({ container, data = {}, activeCategories }) {
    const comp = activeCategories.includes("cars") ? <CarCard car={data} isDisableLoginFormTempFlag={true}/> :
        activeCategories.includes("flats") ? <FlatCard flat={data} isDisableLoginFormTempFlag={true}/> : <></>;

    return ReactDOM.createPortal(
        comp,
        container
    );
  }

const Map = () => {
    const { translations } = useContext(LocalizationContext);
    const mapContainerRef = useRef(null);
    const geocoderContainerRef = useRef(null);
    const { activeCategories, setActiveCategories } = useContext(CategoryContext);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'day-mode');
    const markersRef = useRef([]);
    const mapStyle = theme === 'night-mode'
        ? 'mapbox://styles/weeefamily/cm5jft998002r01s70tv9bq50'
        : 'mapbox://styles/weeefamily/cm5ikdy0c001x01s74x38fj18';
    const [is3D, setIs3D] = useState(true);
    // Using the custom hooks
    const { mapRef, markerRef } = useMap(mapContainerRef, geocoderContainerRef, icon, mapStyle);
    const popups = usePopups();
    const [popupContainers, setPopupContainers] = useState([]);

    useEffect(() => {
        if (mapRef.current) {
            const initialPitch = mapRef.current.getPitch();
            setIs3D(initialPitch > 0); // Set to 3D if the initial pitch is greater than 0
        }
    }, [mapRef]);

    useEffect(() => {
        const handleThemeChange = (event) => {
            setTheme(event.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);

        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    // Update the zoom level and adjust marker text visibility
    const handleZoom = () => {
        const map = mapRef.current;
        const currentZoom = map.getZoom();

        // Обновляем видимость 'marker-title' в зависимости от уровня зума
        markersRef.current.forEach(marker => {
            const markerElement = marker.getElement();
            const title = markerElement.querySelector('.marker-title');

            if (title) {
                // Скрываем title на зуме ниже 12
                if (currentZoom < 12) {
                    title.style.display = 'none';
                } else {  // Показываем title при зуме 12 и выше
                    title.style.display = 'block';
                }
            }

            const subtitle = markerElement.querySelector('.marker-subtitle');
            if (subtitle) {
                // Скрываем subtitle на зуме ниже 12
                if (currentZoom < 12) {
                    subtitle.style.display = 'none';
                } else {
                    subtitle.style.display = 'block';
                }
            }
        });
    };

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on('zoom', handleZoom); // Attach zoom event listener
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoom', handleZoom); // Clean up zoom event listener
            }
        };
    }, [mapRef]);

    useEffect(() => {
        // Set the initial zoom to the farthest distance (max zoom out)
        if (mapRef.current) {
            mapRef.current.setZoom(0);
            mapRef.current.setCenter([0, 0]); // Optionally set initial map center to global coordinates
        }

        // Start the zoom animation after 3 seconds
        setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.flyTo({
                    center: [41.613983383131284, 41.64136234085391], // Set to your desired starting coordinates (e.g. Moscow)
                    zoom: 16,  // Adjust the zoom level
                    speed: 1.2,
                    curve: 1,
                    easing(t) {
                        return t;
                    }
                });
            }
        }, 1500);
        setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.easeTo({
                    bearing: 138,  // Rotate to 180 degrees
                    pitch: 55,  // Tilt more to 60 degrees for a higher angle
                    duration: 2500,  // Duration of the final animation
                    easing(t) {
                        return t * (2 - t);  // Smooth out the transition at the end
                    }
                });
            }
        }, 10500);




    }, [mapRef]);

    useEffect(() => {
        console.log('Rendering popups', popups);

        // Удаляем старые маркеры
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];

        if (mapRef.current) {
            const newContainers = popups.map((popup, index) => {
                const popupNode = document.createElement('div');
                popupNode.className = 'popup-content';

                const mapPopup = new mapboxgl.Popup({
                    offset: 25,
                    closeButton: false,
                    closeOnClick: true,
                    className: 'custom-popup'
                }).setDOMContent(popupNode);

                // Создание маркера
                const markerElement = document.createElement('div');
                markerElement.className = 'custom-marker';

                if (activeCategories.includes("cars")) {
                    markerElement.innerHTML = `
                        <div class="marker-content">
                            <div class="marker-text">
                                <span class="marker-subtitle">${popup.model} ${popup.volume}</span>
                                <span class="marker-title">${(Math.random() * 0.3 + 4.7).toFixed(1)}</span> <!-- Сюда добавляем заголовок -->
                            </div>
                        </div>
                    `;
                } else if (activeCategories.includes("flats")) {
                    markerElement.innerHTML = `
                        <div class="marker-content">
                            <div class="marker-text">
                                <span class="marker-subtitle">${popup.title}</span>
                                <span class="marker-title">${(Math.random() * 0.3 + 4.7).toFixed(1)}</span> <!-- Сюда добавляем заголовок -->
                            </div>
                        </div>
                    `;
                }
                markerElement.style.backgroundImage = `url(${popup.imageUrl})`;

                const marker = new mapboxgl.Marker({ element: markerElement })
                    .setLngLat(popup.location)
                    .setPopup(mapPopup)
                    .addTo(mapRef.current);

                markerElement.addEventListener('click', (e) => {
                    e.stopPropagation();
                    if (mapPopup.isOpen()) {
                        mapPopup.remove();
                    } else {
                        mapPopup.addTo(mapRef.current);
                    }
                });

                markersRef.current.push(marker);

                return popupNode;
            });

            mapRef.current.on('click', (e) => {
                if (e.target instanceof HTMLElement) {
                    const isPopupClicked = e.target.closest('.custom-popup');
                    if (!isPopupClicked) {
                        markersRef.current.forEach(marker => {
                            const popup = marker.getPopup();
                            if (popup.isOpen()) {
                                popup.remove();
                            }
                        });
                    }
                }
            });

            setPopupContainers(newContainers);
        }
    }, [popups, mapRef, activeCategories]);

    useEffect(() => {
        if (mapRef.current && !geocoderContainerRef.current.geocoder) {
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
                placeholder: translations.map.enterAddress,
            });
            geocoderContainerRef.current.geocoder = geocoder;
            geocoder.addTo(geocoderContainerRef.current);

            geocoder.on('result', (event) => {
                const coordinates = event.result.geometry.coordinates;
                if (markerRef.current) {
                    markerRef.current.setLngLat(coordinates);
                } else {
                    markerRef.current = new mapboxgl.Marker().setLngLat(coordinates).addTo(mapRef.current);
                }
                mapRef.current.flyTo({
                    center: coordinates,
                    zoom: 15,
                });
            });
        }
    }, [mapRef]);

    const togglePitch = () => {
        const map = mapRef.current;
        const currentPitch = map.getPitch();
        const tg3dElement = document.getElementById('tg3d');

        if (currentPitch === 0) {
            map.easeTo({
                pitch: 60,
                duration: 500,
            });
            tg3dElement.classList.add('active');
            setIs3D(true);
        } else {
            map.easeTo({
                pitch: 0,
                duration: 500,
            });
            tg3dElement.classList.remove('active');
            setIs3D(false);
        }
    };

    const centerOnUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const map = mapRef.current;

                // Центрируем карту на координатах пользователя
                map.flyTo({
                    center: [longitude, latitude],
                    zoom: 15,
                    speed: 1.2,
                    curve: 1,
                    essential: true,
                });

                // Если маркер уже существует, обновляем его координаты
                if (markerRef.current) {
                    markerRef.current.setLngLat([longitude, latitude]);
                } else {
                    // Создаем кастомный элемент маркера
                    const customIcon = document.createElement('div');
                    customIcon.className = 'custom-marker';
                    customIcon.style.backgroundImage = `url(${icon})`; // Используем backgroundImage вместо innerHTML
                    customIcon.style.width = '40px';
                    customIcon.style.height = '40px';
                    customIcon.style.backgroundSize = 'contain';

                    // Создаем маркер
                    const marker = new mapboxgl.Marker({ element: customIcon })
                        .setLngLat([longitude, latitude])
                        .addTo(map);

                    markerRef.current = marker;
                }
            },
            (error) => {
                console.error('Error getting location:', error);
            },
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
    };

    return (
        <div className="map_container">
            <div className="search-and-gif-container">
                <div ref={geocoderContainerRef} className="geocoder-container"/>
            </div>
            <div className={`toggle3d fas ${is3D ? 'fa-cube' : 'fa-map'}`} id="tg3d" onClick={togglePitch}>
                <p>{is3D ? "3D" : "2D"}</p>
            </div>
            <div className="locate-button" onClick={centerOnUserLocation}>📍</div>
            <CategoryBarMap onCategoryChange={setActiveCategories} isMapView={true}/>
            <div ref={mapContainerRef} id="geocoder-container"/>
            {
                popupContainers.map((container, index) => (
                    <PopupPortal key={index} container={container} data={popups[index]} activeCategories={activeCategories} />
                ))
            }
        </div>
    );
};

export default Map;
