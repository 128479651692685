import React, { useEffect, useState, useContext } from 'react';
import { auth, db } from '../../firebase/firebase';
import { getDoc, doc } from 'firebase/firestore';
import { UserContext } from '../../Services/context/UserContext';
import './ProfileIcon.css';
import { profileIcon } from '../img/profileIcon';
import defaultAvatar from '../img/defaultAvatar.gif';

const ProfileIcon = ({ onClick }) => {
    const { userData } = useContext(UserContext);
    const [profileImage, setProfileImage] = useState(userData?.avatar || null);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setProfileImage(userData.avatar || defaultAvatar);
                }
            } else {
                setProfileImage(null);
            }
        };

        fetchUserData();
    }, [userData]);

    const renderProfileImage = () => {
        return profileImage ? (
            <img src={profileImage} alt="User Profile" className="profile-image" />
        ) : (
            <span
                dangerouslySetInnerHTML={{ __html: profileIcon }}
                className="default-user-icon"
            ></span>
        );
    };

    return (
        <div className="profile-icon" onClick={onClick}>
            {renderProfileImage()}
        </div>
    );
};

export default ProfileIcon;
