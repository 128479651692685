import { useEffect, useRef, useState, useCallback } from "react";
import { db, auth } from '../../../firebase/firebase';
import { collection, query, orderBy, updateDoc, doc, onSnapshot, addDoc, getDocs, getDoc } from "firebase/firestore";
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../redux/notificationSlice';
import MessageItem from "./MessageItem";
import "../css/ChatStyles.css";

const MessageList = ({ chatId, searchQuery = "", chats = [] }) => {
  const messagesRef = chatId ? collection(db, "messages", chatId, "chatMessages") : null;
  const q = messagesRef ? query(messagesRef, orderBy("createdAt")) : null;
  const messagesContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [contextMenu, setContextMenu] = useState(null);
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [showForwardList, setShowForwardList] = useState(false);
  const [forwardMessage, setForwardMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);
  const [likedMessageId, setLikedMessageId] = useState(null);
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();

  const emojiList = ["👍", "❤️", "😂", "😊", "😢", "😡", "👏", "🙏", "🎉", "🔥"];

  const markMessagesAsRead = useCallback(async () => {
    if (!chatId || !messages.length || !auth.currentUser) return;

    const unreadMessages = messages.filter(
      (msg) => msg && msg.id && !msg.isRead && msg.sender !== auth.currentUser.uid
    );

    if (!unreadMessages.length) return;

    try {
      await Promise.all(
        unreadMessages.map(async (msg) => {
          const messageRef = doc(db, "messages", chatId, "chatMessages", msg.id);
          const docSnap = await getDoc(messageRef);
          if (docSnap.exists() && !docSnap.data().isRead) {
            await updateDoc(messageRef, {
              isRead: true,
              readAt: new Date(),
            });
          }
        })
      );
      console.log(`Отмечено как прочитанное: ${unreadMessages.length} сообщений в чате ${chatId}`);
    } catch (error) {
      console.error("Ошибка при обновлении сообщений:", error);
    }
  }, [chatId, messages]);

  useEffect(() => {
    if (!auth.currentUser) return;

    const fetchUserData = async () => {
      try {
        const usersRef = collection(db, "users");
        const snapshot = await getDocs(usersRef);
        const data = {};
        snapshot.docs.forEach(doc => {
          const user = doc.data();
          data[doc.id] = {
            name: user.name || doc.id,
            avatar: user.avatars?.[0] || user.avatar || "",
          };
        });
        setUserData(data);
      } catch (error) {
        console.error("Ошибка загрузки данных пользователей:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (!q) return;

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedMessages = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          senderName: userData[data.sender]?.name || data.sender,
          senderAvatar: userData[data.sender]?.avatar || "",
        };
      });
      setMessages(updatedMessages);
      requestAnimationFrame(() => {
        const container = messagesContainerRef.current;
        if (container && !contextMenu) {
          container.scrollTop = container.scrollHeight;
        }
      });
    }, (error) => {
      console.error("Ошибка при подписке на сообщения:", error);
    });

    return () => unsubscribe();
  }, [chatId, contextMenu, userData]);

  useEffect(() => {
    const handleClickOutside = () => {
      setContextMenu(null);
      setShowForwardList(false);
      setShowEmojiPicker(null);
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (chatId && auth.currentUser) {
      markMessagesAsRead();
    }
  }, [chatId, messages, markMessagesAsRead]);

  const handleContextMenu = (e, msg) => {
    e.preventDefault();
    if (contextMenu) setContextMenu(null);
    else {
      setContextMenu({
        x: e.pageX,
        y: e.pageY,
        message: msg,
      });
    }
  };

  const handleTouchStart = (e, msg) => {
    const timer = setTimeout(() => {
      setContextMenu({
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
        message: msg,
      });
    }, 500);
    setLongPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (longPressTimer) clearTimeout(longPressTimer);
  };

  const handleTouchMove = () => {
    if (longPressTimer) clearTimeout(longPressTimer);
  };

  const handleDoubleClick = async (msg) => {
    const messageRef = doc(db, "messages", chatId, "chatMessages", msg.id);
    const isLiked = msg.likedBy && msg.likedBy.includes(auth.currentUser.uid);
    const updatedLikedBy = isLiked
      ? msg.likedBy.filter(uid => uid !== auth.currentUser.uid)
      : [...(msg.likedBy || []), auth.currentUser.uid];

    setLikedMessageId(msg.id);
    await updateDoc(messageRef, { likedBy: updatedLikedBy });

    setTimeout(() => {
      setLikedMessageId(null);
    }, 1000);
  };

  const handleEmojiClick = (e, msg) => {
    e.stopPropagation();
    setShowEmojiPicker({ x: e.pageX, y: e.pageY, message: msg });
  };

  const sendEmoji = async (emoji, msg) => {
    await addDoc(collection(db, "messages", chatId, "chatMessages"), {
      text: emoji,
      sender: auth.currentUser.uid,
      senderName: userData[auth.currentUser.uid]?.name || auth.currentUser.uid,
      senderAvatar: userData[auth.currentUser.uid]?.avatar || "",
      createdAt: new Date(),
      sentAt: new Date(),
      deliveredAt: new Date(),
      readAt: null,
      isRead: false,
      type: "text",
    });
    setShowEmojiPicker(null);
  };

  const handleEdit = async (messageId, newText) => {
    const messageRef = doc(db, "messages", chatId, "chatMessages", messageId);
    await updateDoc(messageRef, {
      text: newText,
      edited: true,
      editedAt: new Date(),
    });
    setEditingMessageId(null);
    setContextMenu(null);
  };

  const handleDelete = async (messageId) => {
    const messageRef = doc(db, "messages", chatId, "chatMessages", messageId);
    await updateDoc(messageRef, {
      deleted: true,
      text: "Сообщение удалено",
    });
    setContextMenu(null);
  };

  const handlePin = async (messageId, pinned) => {
    const messageRef = doc(db, "messages", chatId, "chatMessages", messageId);
    await updateDoc(messageRef, { pinned: !pinned });
    setContextMenu(null);
  };

  const handleForward = (message) => {
    setForwardMessage(message);
    setShowForwardList(true);
    setContextMenu(null);
  };

  const forwardToChat = async (targetChatId) => {
    if (!targetChatId) return;
    await addDoc(collection(db, "messages", targetChatId, "chatMessages"), {
      ...forwardMessage,
      forwarded: true,
      forwardedFrom: chatId,
      createdAt: new Date(),
      sentAt: new Date(),
      deliveredAt: new Date(),
      readAt: null,
      isRead: false,
    });
    setShowForwardList(false);
    dispatch(addNotification({ message: "Сообщение переслано" }));
  };

  const filteredMessages = messages.filter(msg =>
    !msg.deleted && (msg.text?.toLowerCase().includes(searchQuery.toLowerCase()) || msg.type === "audio")
  );
  const pinnedMessages = filteredMessages.filter(msg => msg.pinned);
  const regularMessages = filteredMessages.filter(msg => !msg.pinned);

  return (
    <div className="messages-container" ref={messagesContainerRef}>
      <div className="messages">
        {pinnedMessages.length > 0 && (
          <div className="pinned-messages">
            <h4>Закреплённые сообщения</h4>
            {pinnedMessages.map(msg => (
              <MessageItem
                key={msg.id}
                msg={msg}
                currentUserId={auth.currentUser?.uid}
                userData={userData}
                editingMessageId={editingMessageId}
                editedText={editedText}
                setEditingMessageId={setEditingMessageId}
                setEditedText={setEditedText}
                likedMessageId={likedMessageId}
                onContextMenu={handleContextMenu}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
                onDoubleClick={handleDoubleClick}
                onEmojiClick={handleEmojiClick}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handlePin={handlePin}
                handleForward={handleForward}
              />
            ))}
          </div>
        )}
        {regularMessages.map(msg => (
          <MessageItem
            key={msg.id}
            msg={msg}
            currentUserId={auth.currentUser?.uid}
            userData={userData}
            editingMessageId={editingMessageId}
            editedText={editedText}
            setEditingMessageId={setEditingMessageId}
            setEditedText={setEditedText}
            likedMessageId={likedMessageId}
            onContextMenu={handleContextMenu}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onDoubleClick={handleDoubleClick}
            onEmojiClick={handleEmojiClick}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handlePin={handlePin}
            handleForward={handleForward}
          />
        ))}
      </div>
      {contextMenu && (
        <div
          className="context-menu"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          {contextMenu.message.sender === auth.currentUser?.uid && (
            <>
              <button onClick={() => {
                setEditingMessageId(contextMenu.message.id);
                setEditedText(contextMenu.message.text);
                setContextMenu(null);
              }}>Редактировать</button>
              <button onClick={() => handleDelete(contextMenu.message.id)}>Удалить</button>
            </>
          )}
          <button onClick={() => handleForward(contextMenu.message)}>Переслать</button>
          <button onClick={() => handlePin(contextMenu.message.id, contextMenu.message.pinned)}>
            {contextMenu.message.pinned ? "Открепить" : "Закрепить"}
          </button>
        </div>
      )}
      {showForwardList && (
        <div className="forward-list" style={{ top: contextMenu?.y || 0, left: contextMenu?.x || 0 }}>
          <h4>Выберите чат для пересылки</h4>
          <ul>
            {chats.map(chat => (
              <li key={chat.id} onClick={() => forwardToChat(chat.id)}>
                {chat.users.filter(uid => uid !== auth.currentUser?.uid).join(", ") || "Чат"}
              </li>
            ))}
          </ul>
          <button onClick={() => setShowForwardList(false)}>Отмена</button>
        </div>
      )}
      {showEmojiPicker && (
        <div
          className="emoji-picker"
          style={{ top: showEmojiPicker.y, left: showEmojiPicker.x }}
        >
          {emojiList.map((emoji, index) => (
            <span
              key={index}
              className="emoji"
              onClick={() => sendEmoji(emoji, showEmojiPicker.message)}
            >
              {emoji}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default MessageList;