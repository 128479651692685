import React, { useState, useContext, useEffect,useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { auth } from '../../../firebase/firebase'; // Убедитесь, что импорт auth настроен правильно
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DatePicker.css';
import FlatForm from '../../Form/FlatForm/FlatForm';
import LoginForm from '../../AuthPage/AuthPage';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const DatePickerFlat = ({
                          onDatesChange,
                          startDate,
                          endDate,
                          onDaysCountChange,
                          price1day,
                          price2to3days,
                          price4to7days,
                          price8to15days,
                          priceForSelling,
                          priceForMonth,
                          objectID,
                          carName,
                          carCity,
                          carCompany,
                          propertyType,
                          carAddress,
                          isDisableLoginFormTempFlag = false
                        }) => {
  const { translations } = useContext(LocalizationContext);
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const today = new Date();
  const [selectedRange, setSelectedRange] = useState({
    startDate: startDate || today,
    endDate: endDate || today,
    key: 'selection',
  });
  const isFirstRender = useRef(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isFormVisible, setFormVisible] = useState(true);

  const handleClose = () => {
    setFormVisible(false);
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectedRange(selection);
    if (onDatesChange) {
      onDatesChange(selection.startDate, selection.endDate);
    }
    if (onDaysCountChange) {
      onDaysCountChange(getDaysCount());
    }
  };

  const getDayEnding = (days) => {
    if (days % 10 === 1 && days % 100 !== 11) {
      return 'день';
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
      return 'дня';
    } else {
      return 'дней';
    }
  };

  const getDaysCount = () => {
    const { startDate, endDate } = selectedRange;
    if (startDate && endDate) {
      const diffTime = Math.abs(endDate - startDate);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    }
    return 0;
  };

  const daysCount = getDaysCount();

  let pricePerDay = price1day;

  if (daysCount === 3) {
    pricePerDay = price2to3days;
  } else if (daysCount === 7) {
    pricePerDay = price4to7days;
  } else if (daysCount === 14) {
    pricePerDay = price8to15days;
  } else if (daysCount === 30) {
    pricePerDay = price8to15days;
  }

  const totalPrice = daysCount * pricePerDay;

  const handleToggleModal = () => {
    if (auth.currentUser || isDisableLoginFormTempFlag) {
      // Если пользователь авторизован, открыть FlatForm
      setIsModalOpen(true);
    } else {
      // Если пользователь не авторизован, открыть LoginForm
      setShowLoginForm(true);
    }
  };

  const handleLoginSuccess = () => {
    // Закрыть LoginForm и открыть FlatForm после успешного логина
    setShowLoginForm(false);
    setIsModalOpen(true);
  };
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (isFirstRender.current) {
                isFirstRender.current = false; // Первый рендер пропускаем
                return;
            }
            if (user) {
                // Если пользователь авторизован и логин-форма открыта, закрываем её
                setShowLoginForm(false);
                setIsModalOpen(true);
            }
        });
        return () => unsubscribe();
    }, []);
  return (
      <div className="datepicker-container">
        <div className="datepicker">
          <DateRangePicker
              ranges={[selectedRange]}
              onChange={handleSelect}
              minDate={today}
          />
        </div>

        <div className="total-price">
          {priceForSelling ? (
              <div className="price-value">
                {translations.datePicker.price}: {priceForSelling} $
              </div>
          ) : (
              <>
            {/*<span className="price-value">*/}
            {/*  {pricePerDay} <span className="per-day">{translations.datePicker.perDay}</span>*/}
            {/*</span>*/}
            {/*    <div className="price-value">*/}
            {/*      {daysCount} {getDayEnding(daysCount)}*/}
            {/*    </div>*/}
                <div className="price-value">
                  {translations.datePicker.price}: {priceForMonth} $
                </div>
              </>
          )}
        </div>

        <div className="disFlex BookMsgBtn datapickerFlats-Button-block">
          <button className="button l_btnFlat" onClick={handleToggleModal}>
            {translations.productListCars.liveView}
          </button>
          <button
              className="button r_btn"
              onClick={() => window.open('https://t.me/weeekendme', '_blank')}
          >
            {translations.datePicker.chat}
          </button>
        </div>

          {showLoginForm && (
              <div className="modal-overlay">
                  <div className="">

                      <LoginForm
                          onSuccess={handleLoginSuccess}
                          onClose={() =>
                          {setShowLoginForm(false);
                              setIsModalOpen(false);
                          }} />
                  </div>
              </div>
          )}

        {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <button className="modal-close" onClick={() => setIsModalOpen(false)}>
                  ✖
                </button>
                {isFormVisible && (

                    <FlatForm
                        onClose={handleClose}
                        objectId={objectID}
                        propertyType={propertyType}
                        carName={carName}
                        carCity={carCity}
                        carCompany={carCompany}
                        daysCount={getDaysCount()}
                        price={price1day}
                        carAddress={carAddress}
                        ranges={selectedRange}
                        startDate={selectedRange.startDate}
                        endDate={selectedRange.endDate}
                    />

                )}
                {!isFormVisible && <p>{translations.datePicker.formClosed}</p>}
              </div>
            </div>
        )}
      </div>
  );
};

export default DatePickerFlat;
