// src/redux/chatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    activeChatId: null,
    selectedUser: null, // Новое поле для выбранного пользователя
    chats: [],
    messages: {},
  },
  reducers: {
    setActiveChat(state, action) {
      state.activeChatId = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    updateChats(state, action) {
      state.chats = action.payload;
    },
    updateMessages(state, action) {
      const { chatId, messages } = action.payload;
      state.messages[chatId] = messages;
    },
  },
});

export const { setActiveChat, setSelectedUser, updateChats, updateMessages } = chatSlice.actions;
export default chatSlice.reducer;