import React, {useState, useEffect, useRef, useContext} from 'react';
import { auth } from '../../../../firebase/firebase'; // Adjust the path to your Firebase config
import CarForm from '../../../Form/CarForm/CarForm'; // Adjust the path as needed
import LoginForm from '../../../AuthPage/AuthPage'; // Adjust the path as needed
import { LocalizationContext } from "../../../../Services/localization/LocalizationContext";
import './CustomDatePicker.css';

const CustomDatePicker = ({
  onDatesChange,
  startDate,
  endDate,
  onDaysCountChange,
  price1day,
  price2to3days,
  price4to7days,
  price8to15days,
  price16to30days,
  price31plusdays,
  objectID,
  carName,
  carModel,
  carCompany,
  priceForSelling,
  isDisableLoginFormTempFlag = false,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate || null);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate || null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormVisible, setFormVisible] = useState(true);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [selectingEndDate, setSelectingEndDate] = useState(false);
  const [hoveredDate, setHoveredDate] = useState(null);
  const isFirstRender = useRef(true);
  const { translations } = useContext(LocalizationContext);


  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const days = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const startDayOfWeek = firstDay.getDay();

    for (let i = 0; i < startDayOfWeek; i++) days.push(null);
    for (let d = 1; d <= lastDay.getDate(); d++) days.push(new Date(year, month, d));
    return days;
  };

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const daysCurrentMonth = getDaysInMonth(currentMonth);

  const handleDateClick = (date) => {
    if (!date || date < today) return;

    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      setSelectedStartDate(date);
      setSelectedEndDate(null);
      setSelectingEndDate(true);
    } else if (selectingEndDate && date >= selectedStartDate) {
      setSelectedEndDate(date);
      setSelectingEndDate(false);
      updateDates(selectedStartDate, date);
    }
  };

  const handleDateHover = (date) => {
    if (selectingEndDate && date >= selectedStartDate) {
      setHoveredDate(date);
    }
  };

  const clearSelection = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectingEndDate(false);
    setHoveredDate(null);
    updateDates(null, null);
  };

  const updateDates = (start, end) => {
    onDatesChange?.(start, end);
    onDaysCountChange?.(getDaysCount(start, end));
  };

  const prevMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
  const nextMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));

  const getDaysCount = (start = selectedStartDate, end = selectedEndDate) => {
    if (start && end) {
      const diffTime = Math.abs(end - start);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    }
    return 0;
  };

  const daysCount = getDaysCount();

  let pricePerDay = price1day;
  if (daysCount >= 2 && daysCount <= 3) pricePerDay = price2to3days || price1day;
  else if (daysCount >= 4 && daysCount <= 7) pricePerDay = price4to7days || price1day;
  else if (daysCount >= 8 && daysCount <= 15) pricePerDay = price8to15days || price1day;
  else if (daysCount >= 16 && daysCount <= 30) pricePerDay = price16to30days || price1day;
  else if (daysCount > 30) pricePerDay = price31plusdays || price1day;

  const totalPrice = daysCount * pricePerDay;

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleToggleModal = () => {
    if (auth.currentUser || isDisableLoginFormTempFlag) {
      setIsModalOpen(true);
    } else {
      setShowLoginForm(true);
    }
  };

  const handleLoginSuccess = () => {
    setShowLoginForm(false);
    setIsModalOpen(true);
  };

  const handleClose = () => setFormVisible(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      if (user) {
        setShowLoginForm(false);
        setIsModalOpen(true);
      }
    });
    return () => unsubscribe();
  }, []);

  const formatDate = (date) =>
    date ? date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : translations.carDetail.notSelected;

  return (
    <div className="cdp-container">


      <div className="cdp-calendar">
        <div className="cdp-calendar-header">
          <button className="cdp-nav-btn" onClick={prevMonth}>‹</button>
          <span>{currentMonth.toLocaleString('en-US', { month: 'long', year: 'numeric' })}</span>
          <button className="cdp-nav-btn" onClick={nextMonth}>›</button>
        </div>
        <div className="cdp-selection-hint">
          {selectingEndDate ? 'Select end date' : translations.carDetail.startDate}
        </div>
        <div className="cdp-grid">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className="cdp-day-header">{day}</div>
          ))}
          {daysCurrentMonth.map((day, index) => (
            <div
              key={index}
              className={`cdp-day ${
                day
                  ? (day < today
                      ? 'disabled'
                      : (day.getTime() === selectedStartDate?.getTime()
                          ? 'selected-start'
                          : (day.getTime() === selectedEndDate?.getTime()
                              ? 'selected-end'
                              : ((selectedStartDate && !selectedEndDate && day > selectedStartDate && day <= (hoveredDate || selectedStartDate))
                                  ? 'in-range'
                                  : (selectedStartDate && selectedEndDate && day > selectedStartDate && day < selectedEndDate
                                      ? 'in-range'
                                      : '')))))
                  : ''
              }`}
              onClick={() => handleDateClick(day)}
              onMouseEnter={() => handleDateHover(day)}
            >
              {day ? day.getDate() : ''}
            </div>
          ))}
        </div>
        <button className="cdp-clear-btn" onClick={clearSelection}>{translations.carDetail.clearDates}</button>
      </div>
       <div className="cdp-date-range">
        <div className="cdp-date-box">
          <label>{translations.carDetail.startDate}</label>
          <span>{formatDate(selectedStartDate)}</span>
        </div>
        <div className="cdp-date-box">
          <label>{translations.carDetail.endDate}</label>
          <span>{formatDate(selectedEndDate)}</span>
        </div>
      </div>

      <div className="cdp-pricing">
        {priceForSelling ? (
          <p className="cdp-price">Price: ${priceForSelling}</p>
        ) : (
          <>
            <p className="cdp-price">${pricePerDay} / {translations.carDetail.day}</p>
            <p className="cdp-price">{daysCount} day{daysCount !== 1 ? 's' : ''}</p>
            <p className="cdp-price total">{translations.carDetail.total}: ${totalPrice}</p>
          </>
        )}
      </div>

      <div className="cdp-actions">
        <button className="cdp-book-btn" onClick={handleToggleModal}>
          {priceForSelling ? 'Test Drive' : translations.carDetail.bookNow}
        </button>
        <button
          className="cdp-chat-btn"
          onClick={() => window.open('https://t.me/weeekendme', '_blank')}
        >
          {translations.carDetail.chat}
        </button>
      </div>

      {showLoginForm && (
        <div className="cdp-modal-overlay">
          <div className="cdp-modal">
            <LoginForm
              onSuccess={handleLoginSuccess}
              onClose={() => {
                setShowLoginForm(false);
                setIsModalOpen(false);
              }}
            />
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="cdp-modal-overlay">
          <div className="cdp-modal">
            <button className="cdp-modal-close" onClick={toggleModal}>×</button>
            {isFormVisible ? (
              <CarForm
                onClose={handleClose}
                objectId={objectID}
                carName={carName}
                carModel={carModel}
                carCompany={carCompany}
                daysCount={daysCount}
                price={price1day}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
              />
            ) : (
              <p className="cdp-form-closed">Booking form closed</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;