import React, { useContext, useState } from 'react';
import './ProductListCarFilter.css';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const ProductListCarFilter = ({ onFilter }) => {
    const { translations } = useContext(LocalizationContext);
    // Массив с категориями автомобилей
    const carClasses = [
        "All",
        'Sedan',
        'SUV',
        'Minivan',
        'Coupe',
        'Convertible',
        'Hatchback',
        'Wagon',
        'Pickup',
        'Limousine',
        'Crossover',
        'Roadster',
        'Sports',
        'Minibus',
        'liftBack',
        'buggy',
        'microCar',

    ];

    // Состояние для выбранных классов автомобилей
    const [selectedClasses, setSelectedClasses] = useState([]);

    // Обработчик клика по классу автомобиля
    const handleClassClick = (carClass) => {
        if (carClass === "All") {
            // Если выбран "All", снимаем все фильтры
            setSelectedClasses([]);
            onFilter({ brands: [], priceSort: '', transmission: [], company: '', fuel: [], city: '', carClass: [] });
        } else {
            // Обновляем массив выбранных классов
            let updatedClasses = selectedClasses.includes(carClass)
                ? selectedClasses.filter(item => item !== carClass)
                : [...selectedClasses, carClass];

            setSelectedClasses(updatedClasses);
            onFilter({ carClass: updatedClasses, brands: [], priceSort: '', transmission: [], company: '', fuel: [], city: '' });
        }
    };

    return (
        <div className="car-class-container">
            {carClasses.map((carClass, index) => (
                <div
                    key={index}
                    className={`car-class-item ${selectedClasses.includes(carClass) ? 'selected' : ''} ${carClass === "All" ? 'all-button' : ''}`}
                    onClick={() => handleClassClick(carClass)}
                >
                    <div className='car-class-item-child'>
                        {translations.carMarks[carClass] || carClass} {/* Показываем локализованный текст или значение по умолчанию */}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductListCarFilter;
