import { useState, useRef } from "react";
import { db, auth, storage } from '../../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "../css/MessageInput.css";

const MessageInput = ({ chatId }) => {
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const emojiList = ["👍", "❤️", "😂", "😊", "😢", "😡", "👏", "🙏", "🎉", "🔥"];

  const sendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === "" || !chatId) return;

    let text = message;
    if (chatId.includes("WeeekendAsistans")) {
      if (message.startsWith("/")) {
        switch (message.toLowerCase()) {
          case "/start":
            text = "Привет! Я Grok, чем могу помочь?";
            break;
          case "/help":
            text = "Доступные команды: /start, /help, /info";
            break;
          case "/info":
            text = "Я создан xAI для помощи пользователям!";
            break;
          default:
            text = `Неизвестная команда: ${message}`;
        }
        await addDoc(collection(db, "messages", chatId, "chatMessages"), {
          text,
          sender: "WeeekendAsistans",
          createdAt: new Date(),
          sentAt: new Date(),
          deliveredAt: new Date(),
          readAt: null,
          isRead: false,
          type: "text",
        });
      } else {
        const grokResponse = `Ты написал: "${message}"`;
        await addDoc(collection(db, "messages", chatId, "chatMessages"), {
          text: grokResponse,
          sender: "WeeekendAsistans",
          createdAt: new Date(),
          sentAt: new Date(),
          deliveredAt: new Date(),
          readAt: null,
          isRead: false,
          type: "text",
        });
      }
    } else {
      await addDoc(collection(db, "messages", chatId, "chatMessages"), {
        text,
        sender: auth.currentUser?.uid || "anonymous",
        createdAt: new Date(),
        sentAt: new Date(),
        deliveredAt: new Date(),
        readAt: null,
        isRead: false,
        type: "text",
      });
    }

    setMessage("");
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        const audioFileName = `audio_${auth.currentUser?.uid}_${Date.now()}.webm`;
        const storageRef = ref(storage, `audio/${chatId}/${audioFileName}`);

        await uploadBytes(storageRef, audioBlob);
        const audioUrl = await getDownloadURL(storageRef);

        await addDoc(collection(db, "messages", chatId, "chatMessages"), {
          audioUrl,
          sender: auth.currentUser?.uid || "anonymous",
          createdAt: new Date(),
          sentAt: new Date(),
          deliveredAt: new Date(),
          readAt: null,
          isRead: false,
          type: "audio",
        });

        stream.getTracks().forEach(track => track.stop());
        setIsRecording(false);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Ошибка при записи аудио:", error);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
      mediaRecorderRef.current.stop();
    }
  };

  const handleEmojiClick = (e) => {
    e.preventDefault();
    setShowEmojiPicker(!showEmojiPicker);
  };

  const sendEmoji = async (emoji) => {
    if (!chatId) return;
    await addDoc(collection(db, "messages", chatId, "chatMessages"), {
      text: emoji,
      sender: auth.currentUser?.uid || "anonymous",
      createdAt: new Date(),
      sentAt: new Date(),
      deliveredAt: new Date(),
      readAt: null,
      isRead: false,
      type: "text",
    });
    setShowEmojiPicker(false);
  };

  return (
    <form onSubmit={sendMessage} className="message-input">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Введите сообщение..."
      />
      <button
        type="button"
        className="emoji-button"
        onClick={handleEmojiClick}
      >
        <svg viewBox="0 0 24 24" width="24" height="24">
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-9h2v2h-2v-2zm0-3h2v2h-2V8zm3 7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-3 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
            fill="currentColor"
          />
        </svg>
      </button>
      {message.trim() === "" ? (
        <button
          type="button"
          onClick={isRecording ? stopRecording : startRecording}
          className="mic-button"
        >
          {isRecording ? (
            <svg viewBox="0 0 24 24">
              <rect x="6" y="6" width="12" height="12" fill="currentColor" />
            </svg>
          ) : (
            <svg viewBox="0 0 24 24">
              <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5-3c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-2.08c3.39-.49 6-3.39 6-6.92h-2z" fill="currentColor" />
            </svg>
          )}
        </button>
      ) : (
        <button type="submit">
          <svg viewBox="0 0 24 24">
            <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" fill="currentColor" />
          </svg>
        </button>
      )}
      {showEmojiPicker && (
        <div className="emoji-picker">
          {emojiList.map((emoji, index) => (
            <span
              key={index}
              className="emoji"
              onClick={() => sendEmoji(emoji)}
            >
              {emoji}
            </span>
          ))}
        </div>
      )}
    </form>
  );
};

export default MessageInput;