import React, { useEffect, useState, useContext  } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { FaBluetoothB, FaHeadphones, FaSnowflake, FaUsb } from 'react-icons/fa';
import DatePicker from "./DatePicker/DatePicker";
import './DatePicker/DatePicker.css';
import './ProductListCars.css';
import './CategoryBanner.css'
import './CategoryBanner.css'
import { PiEngine } from "react-icons/pi";
import { IconManualGearbox } from '@tabler/icons-react';
import Slider from 'react-slick'; // Импортируем слайдер
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CategoryBar from "../CategoryBar/CategoryBarCategories/CategoryBar";
import ProductListCarFilter from "./ProductListCarFilter";
// Импортируем компонент фильтров

import SideFilters from './SideFilters'; // Импортируем компонент фильтров
import usb from './ProductListCarsImg/usb.svg'
import group from './ProductListCarsImg/Group.svg'
import snow from './ProductListCarsImg/snow-blowing 1.svg'
import vector from './ProductListCarsImg/Vector.svg'
import bluet from './ProductListCarsImg/Frame 7.svg'
import calendarsvg from './ProductListCarsImg/calendar-days (1) 1.svg'
import categoryBanner from '../img/car4.jpg';
import {CategoryContext} from "../../App";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import { GlobalFilterContext } from '../../Services/context/GlobalFilterContext';
import { useNavigate } from 'react-router-dom';
import LikeComponent from "../LikeComponent/LikeComponent";

const ProductListCars = () => {
    const { translations } = useContext(LocalizationContext);
    const { dealTypeFilter } = useContext(GlobalFilterContext);
    const [cars, setCars] = useState([]);
    const [openCarId, setOpenCarId] = useState(null); // Хранит ID машины, у которой открыт календарь
    const [filteredProducts, setFilteredProducts] = useState([]); // Фильтрованные автомобили
    const { activeCategories, setActiveCategories } = useContext(CategoryContext);
    const [products, setProducts] = useState([]); // Исходные данные

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const [pageNumbers, setPageNumbers] = useState([]);
    const navigate = useNavigate();

// Индексы для отображаемых элементов
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCars = filteredProducts.filter(car => car.dealType ? car.dealType === dealTypeFilter : true).slice(indexOfFirstItem, indexOfLastItem);
    useEffect(() => {
        const fetchCars = async () => {
            try {
                const carsCollection = collection(db, 'cars');
                const carSnapshot = await getDocs(carsCollection);
                const carList = carSnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(car => car.status === 'approved');
                setCars(carList);
                setFilteredProducts(carList); // Инициализируем фильтрованные машины
            } catch (error) {
                console.error("Error fetching cars: ", error);
            }
        };

        fetchCars();
    }, []);



    const renderFeatureIcon = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
        const isActive = feature === "Есть";
        const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };
        const inactiveStyle = { filter: 'grayscale(100%) brightness(50%)' };

        return (
            <div className="Bluetooth">
                <img
                    src={imgSrc}
                    style={isActive ? activeStyle : inactiveStyle}
                    alt={altText}
                />
                {/*{openCarId === carId &&  */}
                <div className="bluetooth-label">{featureLabel}</div>

            </div>
        );
    };
    useEffect(() => {
        const filteredItems = filteredProducts.filter(car => car.dealType ? car.dealType === dealTypeFilter : true);
        const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
        setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
        setCurrentPage(1); // Сбрасываем на первую страницу после фильтрации
    }, [filteredProducts, dealTypeFilter]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
          // Прокрутка страницы вверх

        const cardsContainer = document.querySelector('.category-banner');
        if (cardsContainer) {
        cardsContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        });
     }
    };

    // Обработка фильтров
    // const handleFilter = (filters) => {
    //     const { brands = [], priceSort = '', transmission = [], company = '', fuel = [], city = '', carClass = 'Все' } = filters;
    //     console.log("Received filters:", filters); // Проверка всех переданных фильтров, включая city
    //
    //     let filtered = cars.filter(car => {
    //         const matchesBrand = brands.length > 0 ? brands.includes(car.brand) : true;
    //         const matchesTransmission = transmission.length > 0
    //             ? car.transmission && transmission.some(trans => car.transmission.toLowerCase() === trans.toLowerCase())
    //             : true;
    //         const matchesCompany = company ? car.company && car.company.toLowerCase().includes(company.toLowerCase()) : true;
    //         const matchesFuel = fuel.length > 0
    //             ? car.fuel && fuel.some(fuelType => car.fuel.toLowerCase() === fuelType.toLowerCase())
    //             : true;
    //
    //         // Проверка соответствия города
    //         const matchesCity = city ? car.city && car.city.toLowerCase() === city.toLowerCase() : true;
    //
    //         // Проверка соответствия класса автомобиля
    //         const matchesCarClass = carClass.length === 0 ? true : carClass.includes(car.carClass);
    //         // Логи для отслеживания значений
    //         console.log(`Checking car: ${car.brand} ${car.model}`);
    //         console.log(`Car city: ${car.city}, Filter city: ${city}, Matches city: ${matchesCity}`);
    //         console.log(`Car class: ${car.carClass}, Filter class: ${carClass}, Matches class: ${matchesCarClass}`);
    //
    //         return matchesBrand && matchesTransmission && matchesCompany && matchesFuel && matchesCity && matchesCarClass;
    //     });
    //
    //     // Сортировка по цене
    //     if (priceSort === 'high') {
    //         filtered = filtered.sort((a, b) => b.price1day - a.price1day);
    //     } else if (priceSort === 'low') {
    //         filtered = filtered.sort((a, b) => a.price1day - b.price1day);
    //     }
    //
    //     setFilteredProducts(filtered);
    //     setCurrentPage(1); // Сбрасываем на первую страницу после изменения фильтров
    // };



    const handleFilter = (filters) => {
        const {
            brands = [],
            priceSort,
            transmission = [],
            company = '',
            fuel = [],
            city = '',
            carClass = []
        } = filters;
        console.log("Received filters:", filters); // Проверка всех переданных фильтров, включая city

        let filtered = cars.filter(car => {
            const matchesBrand = brands.length > 0 ? brands.includes(car.brand) : true;
            const matchesTransmission = transmission.length > 0
                ? car.transmission && transmission.some(trans => car.transmission.toLowerCase() === trans.toLowerCase())
                : true;
            const matchesCompany = company ? car.company && car.company.toLowerCase().includes(company.toLowerCase()) : true;
            const matchesFuel = fuel.length > 0
                ? car.fuel && fuel.some(fuelType => car.fuel.toLowerCase() === fuelType.toLowerCase())
                : true;

            // Проверка соответствия города
            const matchesCity = filters.pickupCity ? car.city && car.city.toLowerCase() === filters.pickupCity.toLowerCase() : true;

            // Логи для отслеживания значений
            console.log(`Checking car: ${car.brand} ${car.model}`);
            console.log(`Car city: ${car.city}, Filter city: ${city}, Matches city: ${matchesCity}`);
            const matchesCarClass = carClass.length === 0 ? true : carClass.includes(car.carClass);
            return matchesBrand && matchesTransmission && matchesCompany && matchesFuel && matchesCity && matchesCarClass;
        });

        // Сортировка по цене
        if (priceSort === 'high') {
            filtered = filtered.sort((a, b) => b.price1day - a.price1day);
        } else if (priceSort === 'low') {
            filtered = filtered.sort((a, b) => a.price1day - b.price1day);
        }
        setFilteredProducts(filtered);
        setCurrentPage(1); // Сбрасываем на первую страницу после изменения фильтров
    };

    const handleTitleClick = () => {
    navigate('/'); // Navigate to the home page
  };

    const handleCategoryChange = (selectedCategories) => {
        if (JSON.stringify(selectedCategories) !== JSON.stringify(activeCategories)) {
            setActiveCategories(selectedCategories);

            // Если категория "cars" выбрана, показываем все автомобили
            if (selectedCategories.includes('cars')) {
                setFilteredProducts(cars); // Показать весь список автомобилей
            } else {
                // В противном случае фильтруем по категории
                const filtered = cars.filter(car => selectedCategories.includes(car.category));
                setFilteredProducts(filtered);
            }
            setCurrentPage(1); // Сбрасываем на первую страницу
        }
    };
    const toggleDatePicker = (carId) => {
        setOpenCarId(prevId => (prevId === carId ? null : carId)); // Если уже открыт, закрыть, иначе открыть
    };

    // Настройки для слайдера
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleCarImageClick = (carId) => {
    navigate(`/car/${carId}`); // Navigate to the detailed car page
  };
    return (
        <div>
            <div className="category-banner" style={{backgroundImage: `url(${categoryBanner})`}}>
                <div className="banner-content-wrapper">
                    <div className="banner-title-section">
                        <div className="title-text" onClick={handleTitleClick} style={{ cursor: 'pointer' }}>
                            <h1 className="main-title">Weeekend</h1>
                            <h2 className="beta-tag">BETA 1.1</h2>
                        </div>
                        {/*<p className="banner-description">*/}
                        {/*    We are a one-stop platform for booking travel, renting accommodations, cars, water*/}
                        {/*    activities, and more.*/}
                        {/*    In one place, you can easily find the best offers for car rentals, homes, yachts, jet skis,*/}
                        {/*    and book exciting activities like parasailing!*/}
                        {/*</p>*/}
                    </div>
                    <div className="trademark-sign">®</div>
                </div>
            </div>
            <CategoryBar onCategoryChange={handleCategoryChange} isMapView={false} />



                <div>
                    <div className={'side-filters'}><SideFilters onFilter={handleFilter}/></div>
                    <ProductListCarFilter onFilter = { handleFilter }/>
                    {/* Добавляем фильтры */}
                    <div className="car-list">
                        {currentCars.map((car) => (
                            <div key={car.id} className="car-card">
                                <div className="company-name-overlay">
                                    <p>{car.company}</p>
                                </div>
                                <div className={'LikesButton'}>
                                    <LikeComponent objectId={car.id} like_collection='cars'/>
                                </div>
                                {/* Проверяем наличие imageUrls, если они есть, используем слайдер */}
                                {car.imageUrls && car.imageUrls.length > 0 ? (
                                    <Slider {...sliderSettings}>
                                        {/* Главное изображение */}
                                        <div onClick={() => handleCarImageClick(car.id)}>
                                            <img
                                                src={car.imageUrl}
                                                alt={`${car.brand} ${car.description}`}
                                                className="car-image"
                                                loading="lazy"
                                            />
                                        </div>
                                        {/* Дополнительные изображения */}
                                        {car.imageUrls.map((url, index) => (
                                            <div key={index} onClick={() => handleCarImageClick(car.id)}>
                                                <img
                                                    src={url}
                                                    alt={`${car.brand} image ${index}`}
                                                    className="car-image"
                                                    loading="lazy"
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                ) : (
                                    <img src={car.imageUrl} alt={`${car.brand} ${car.description}`}
                                         className="car-image"/>
                                )}
                                <div className="car-details">
                                    <div className="LeftRight">
                                        <div className={'disFlex brandvolume'}><h3
                                            className="right">{car.brand} {car.model} </h3> <p>{car.volume}L</p></div>

                                        <p>{car.transmission}</p>
                                    </div>
                                    <div className="car-details">
                                        <div className="disFlex elementsIcons IconGrid">
                                            {renderFeatureIcon(car.bluetooth, "Bluetooth", bluet, "Bluetooth", openCarId, car.id)}

                                            {renderFeatureIcon(car.usb, "USB", usb, "USB", openCarId, car.id)}

                                            {renderFeatureIcon(car.aux, "AUX", group, "AUX", openCarId, car.id)}

                                            {renderFeatureIcon(car.ac, "Conditioner", snow, "Conditioner", openCarId, car.id)}

                                            {renderFeatureIcon(car.usb, car.fuel, vector, "Fuel", openCarId, car.id)}

                                            <div className="fuelConsumption">
                                                <div className="disFlex">
                                                    <div className="Bluetooth">
                                                        <span className="fuelNumber">{car.fuelConsumption}</span>
                                                        <span className="fuelUnit">L/100km</span>
                                                        {/*{openCarId === car.id &&*/}
                                                        {/*<span className="fueltext"> Fuel Flow</span>*/}
                                                    </div>
                                                </div>
                                            </div>

                                            {renderFeatureIcon(car.usb, car.year, calendarsvg, "Year", openCarId, car.id)}
                                        </div>
                                    </div>


                                    {openCarId === car.id && (


                                        <DatePicker
                                            price1day={car.price1day}
                                            price2to3days={car.price2to3days}
                                            price4to7days={car.price4to7days}
                                            price8to15days={car.price8to15days}
                                            price16to30days={car.price16to30days}
                                            price31plusdays={car.price31plusdays}
                                            objectID={car.id}
                                            carName={car.brand}
                                            carModel={car.model}
                                            carCompany={car.company}
                                            priceForSelling={car.priceForSelling}
                                        />

                                    )}

                                    <div className="price-details">
                                        <div className="price-item">
                                            {/* Price details if needed */}
                                        </div>
                                    </div>
                                </div>
                                <div className={'button-container'}>
                                    <button className={`details-button ${openCarId === car.id ? 'collapsed' : ''}`}
                                            onClick={() => toggleDatePicker(car.id)}>
                                        {openCarId !== car.id && (
                                            <div className="car-detail">
                                                {car.priceForSelling ? (
                                                    // Если задана цена для продажи, отображаем только ее
                                                    <div>{car.priceForSelling}$</div>
                                                ) : (
                                                    // Если цена для продажи отсутствует, отображаем аренду
                                                    <div>
                                                        {translations.productListCars.from} {car.price1day}$
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {/* Добавляем черту separator для всех случаев (продажа и аренда) */}
                                        {openCarId !== car.id && (
                                            <span className="separator"> | </span> /* Черта отображается для продажи и аренды */
                                        )}

                                        <div className="car-detail">
                                            <div>
                                                {openCarId === car.id ? (
                                                    <span className="arrow-up">
                                                        <svg className="custom-arrow" width="32" height="10"
                                                             viewBox="0 0 32 10" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                             <path
                                                                 d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                                                 stroke="currentColor"
                                                                 stroke-opacity="1"
                                                                 stroke-width="2"
                                                                 stroke-linecap="round"/>
                                                         </svg>
                                                    </span>
                                                ) : (
                                                    car.priceForSelling
                                                        ? translations.productListCars.testDrive
                                                        : translations.productListCars.getNow
                                                )}
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                {openCarId !== car.id &&
                                    <div>
                                        <svg
                                            className="custom-arrow-down"
                                            width="32"
                                            height="10"
                                            viewBox="0 0 32 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                                stroke="currentColor"
                                                stroke-opacity="1"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                        </svg>

                                    </div>
                                }

                            </div>


                        ))}
                    </div>

                    <div className="pagination">
                        {pageNumbers.map((number) => (
                            <button
                                key={number}
                                onClick={() => handlePageChange(number)}
                                className={number === currentPage ? 'active' : ''}
                            >
                                {number}
                            </button>
                        ))}
                    </div>
                </div>

        </div>
    );
};

export default ProductListCars