import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth, storage } from '../../../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './css/ProfileHeader.css';

const ProfileHeader = ({
  profileData,
  auth,
  userId,
  isSubscribed,
  subscriptions,
  subscribers,
  selectedAvatarIndex,
  handleSubscribe,
  handleMessage,
  handleCall,
  handleShowSubscribers,
  handleShowSubscriptions,
  avatarBorderColor,
  isAvatarHighlighted,
  handleBorderColorChange,
  toggleHighlight,
  openImage,
  selectAvatar,
}) => {
  const [avatars, setAvatars] = useState(profileData.avatars || [profileData.avatar || 'https://placehold.co/100x100']);
  const [showMenu, setShowMenu] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  useEffect(() => {
    setAvatars(profileData.avatars || [profileData.avatar || 'https://placehold.co/100x100']);
  }, [profileData]);

  useEffect(() => {
    const saveAvatarSettings = async () => {
      if (auth.currentUser?.uid !== userId) return;
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        avatarBorderColor,
        isAvatarHighlighted,
      });
    };
    saveAvatarSettings();
  }, [avatarBorderColor, isAvatarHighlighted, userId]);

  useEffect(() => {
    const loadAvatarSettings = async () => {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        handleBorderColorChange(data.avatarBorderColor || '#007aff');
        toggleHighlight(data.isAvatarHighlighted ? () => setIsAvatarHighlighted(true) : () => setIsAvatarHighlighted(false));
      }
    };
    loadAvatarSettings();
  }, [userId]);

  const toggleMenu = () => {
    setShowMenu(prev => !prev);
    setIsDeleteMode(false);
  };

  const handleDeleteMode = () => {
    setIsDeleteMode(prev => !prev);
  };

  const handleDeleteAvatar = async (index) => {
    if (auth.currentUser?.uid !== userId || avatars.length <= 1) return;

    const updatedAvatars = avatars.filter((_, i) => i !== index);
    let newSelectedIndex = selectedAvatarIndex;

    if (index === selectedAvatarIndex) {
      newSelectedIndex = 0;
    } else if (index < selectedAvatarIndex) {
      newSelectedIndex -= 1;
    }

    setAvatars(updatedAvatars);
    selectAvatar(newSelectedIndex);

    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      avatars: updatedAvatars,
      selectedAvatarIndex: newSelectedIndex,
    });
  };

  const handleInstantAvatarUpload = async (e) => {
    const files = Array.from(e.target.files);
    if (!files.length || !auth.currentUser || auth.currentUser.uid !== userId) return;

    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `avatars/${userId}/${file.name}_${Date.now()}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    });

    const newAvatarUrls = await Promise.all(uploadPromises);
    const updatedAvatars = [...avatars, ...newAvatarUrls];
    const newSelectedIndex = updatedAvatars.length - 1;

    setAvatars(updatedAvatars);
    selectAvatar(newSelectedIndex);

    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      avatars: updatedAvatars,
      selectedAvatarIndex: newSelectedIndex,
    });
  };

  return (
    <div className="user-profile-header">
      <div className="header-top">
        <h1>{profileData.name || 'Без имени'}</h1>
        {auth.currentUser?.uid === userId && (
          <button className="menu-btn" onClick={toggleMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="5" r="2" fill="var(--text-color-primary)" />
              <circle cx="12" cy="12" r="2" fill="var(--text-color-primary)" />
              <circle cx="12" cy="19" r="2" fill="var(--text-color-primary)" />
            </svg>
          </button>
        )}
      </div>
      {showMenu && auth.currentUser?.uid === userId && (
        <div className="avatar-menu">
          <input
            type="color"
            value={avatarBorderColor}
            onChange={(e) => handleBorderColorChange(e.target.value)}
            title="Выбрать цвет обводки"
          />
          <button className="highlight-toggle-btn" onClick={toggleHighlight}>
            {isAvatarHighlighted ? 'Выключить' : 'Включить'}
          </button>
          <label className="add-avatar-btn">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleInstantAvatarUpload}
              style={{ display: 'none' }}
            />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4V20M4 12H20" stroke="var(--text-color-primary)" strokeWidth="2" />
            </svg>
          </label>
          <button className="delete-mode-btn" onClick={handleDeleteMode}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 6H5H21" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          <button className="close-menu-btn" onClick={toggleMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M6 6L18 18" stroke="var(--text-color-primary)" strokeWidth="2" />
            </svg>
          </button>
        </div>
      )}
      <div className="avatar-container">
        <img
          src={avatars[selectedAvatarIndex]}
          alt="Avatar"
          className="avatar"
          onClick={() => openImage(avatars[selectedAvatarIndex])}
          style={{
            borderColor: isAvatarHighlighted ? avatarBorderColor : 'var(--neutral-color-1)',
          }}
        />
        {avatars.length > 1 && (
          <div className="avatar-thumbnails">
            {avatars.map((url, index) => (
              <div key={index} className="avatar-thumbnail-wrapper">
                <img
                  src={url}
                  alt={`Avatar ${index}`}
                  className={`avatar-thumbnail ${index === selectedAvatarIndex ? 'selected' : ''}`}
                  onClick={() => selectAvatar(index)}
                />
                {isDeleteMode && auth.currentUser?.uid === userId && (
                  <button
                    className="delete-avatar-btn"
                    onClick={() => handleDeleteAvatar(index)}
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 6H5H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <p className="status">{profileData.status || 'Статус не указан'}</p>
      <div className="follow-stats">
        <span onClick={handleShowSubscribers} className="follow-link">{subscribers.length} Подписчиков</span>
        <span onClick={handleShowSubscriptions} className="follow-link">{subscriptions.length} Подписок</span>
      </div>
      <div className="profile-actions">
        {auth.currentUser?.uid !== userId && (
          <>
            <button onClick={handleSubscribe}>{isSubscribed ? 'Отписаться' : 'Подписаться'}</button>
            <button onClick={handleCall}>📞</button>
            <button onClick={handleMessage}>✉️</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;