import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useProfileData from './../../hooks/useProfileData';
import { db, auth, storage } from '../../../firebase/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, collection, onSnapshot, addDoc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { setActiveChat, setSelectedUser } from '../../../redux/chatSlice';
import Stories from '../../Profile/stories/Stories';
import ProfileHeader from './ProfileHeader';
import ProfileForm from './ProfileForm';
import ProfileInfo from './ProfileInfo';
import ProfileMedia from './ProfileMedia';
import ProfileWall from './ProfileWall';
import FullscreenImage from './FullScreenImage';
import FollowModal from './FollowModal';
import './css/UserProfileBase.css';
import { sendNotification } from './../../../Services/fcmService';

const UserProfile = () => {
  const { userId } = useParams();
  const { profileData, loading, updateProfile } = useProfileData(userId);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [media, setMedia] = useState([]);
  const [newMediaFiles, setNewMediaFiles] = useState([]);
  const [newAvatarFiles, setNewAvatarFiles] = useState([]);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(profileData?.selectedAvatarIndex || 0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [collectionType, setCollectionType] = useState('avatars');
  const [wallComments, setWallComments] = useState([]);
  const [newWallComment, setNewWallComment] = useState('');
  const [avatarBorderColor, setAvatarBorderColor] = useState('#007aff');
  const [isAvatarHighlighted, setIsAvatarHighlighted] = useState(false);
  const [showSubscribers, setShowSubscribers] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [theme, setTheme] = useState('white');
  const [stories, setStories] = useState([]); // Добавлено состояние для сторис
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.currentUser || !profileData) return;

    const currentUserDoc = doc(db, "users", auth.currentUser.uid);
    const targetUserDoc = doc(db, "users", userId);

    const unsubscribeCurrent = onSnapshot(currentUserDoc, (snap) => {
      setIsSubscribed(snap.data()?.subscriptions?.includes(userId) || false);
      setSubscriptions(snap.data()?.subscriptions || []);
    });

    const unsubscribeTarget = onSnapshot(targetUserDoc, (snap) => {
      const subs = snap.data()?.subscriptions || [];
      setSubscriptions(subs);
    });

    const unsubscribeUsers = onSnapshot(collection(db, "users"), (snap) => {
      const subscribersList = snap.docs
          .filter(doc => doc.data().subscriptions?.includes(userId))
          .map(doc => ({ uid: doc.id, ...doc.data() }));
      setSubscribers(subscribersList);
    });

    const postsRef = collection(db, "users", userId, "posts");
    const unsubscribePosts = onSnapshot(postsRef, (snap) => {
      const postsData = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        comments: doc.data().comments || [],
        likes: doc.data().likes || [],
      }));
      setPosts(postsData);
    });

    const mediaRef = collection(db, "users", userId, "media");
    const unsubscribeMedia = onSnapshot(mediaRef, (snap) => {
      const updatedMedia = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMedia(updatedMedia);
      if (collectionType === 'media' && selectedImage) {
        setImages(updatedMedia);
        const newIndex = updatedMedia.findIndex(item => item.url === selectedImage);
        setCurrentImageIndex(newIndex !== -1 ? newIndex : 0);
      }
    });

    const wallCommentsRef = collection(db, "users", userId, "wallComments");
    const unsubscribeWallComments = onSnapshot(wallCommentsRef, (snap) => {
      const commentsData = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWallComments(commentsData);
    });

    const storiesRef = collection(db, "users", userId, "stories");
    const unsubscribeStories = onSnapshot(storiesRef, (snap) => {
      const storiesData = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStories(storiesData);
    });

    setSelectedAvatarIndex(profileData.selectedAvatarIndex || 0);

    return () => {
      unsubscribeCurrent();
      unsubscribeUsers();
      unsubscribePosts();
      unsubscribeMedia();
      unsubscribeWallComments();
      unsubscribeStories();
      unsubscribeTarget();
    };
  }, [userId, profileData, collectionType, selectedImage]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setFormData(profileData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    await updateProfile(formData);
    setIsEditing(false);
  };

  const handlePostSubmit = async () => {
    if (!newPost.trim() || !auth.currentUser) return;
    await addDoc(collection(db, "users", userId, "posts"), {
      text: newPost,
      createdAt: new Date(),
      author: auth.currentUser.uid,
      comments: [],
      likes: [],
    });
    setNewPost('');
  };

  const handleWallCommentSubmit = async () => {
    if (!newWallComment.trim() || !auth.currentUser) return;
    await addDoc(collection(db, "users", userId, "wallComments"), {
      text: newWallComment,
      author: auth.currentUser.uid,
      createdAt: new Date(),
    });

    if (userId !== auth.currentUser.uid) {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const token = userDoc.data().fcmToken;
      const senderDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const senderName = senderDoc.data().name || auth.currentUser.uid;
      if (token) {
        await sendNotification(token, null, auth.currentUser.uid, senderName, `${senderName} оставил комментарий на вашей стене: ${newWallComment}`, 'wallComment');
      }
    }

    setNewWallComment('');
  };

  const handlePostDelete = async (postId) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      await deleteDoc(doc(db, "users", userId, "posts", postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handlePostEdit = async (postId, newText) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      await updateDoc(doc(db, "users", userId, "posts", postId), {
        text: newText,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error editing post:', error);
    }
  };

  const handleCommentDelete = async (postId, commentIndex) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      const postRef = doc(db, "users", userId, "posts", postId);
      const post = posts.find(p => p.id === postId);
      const commentToDelete = post.comments[commentIndex];
      await updateDoc(postRef, {
        comments: arrayRemove(commentToDelete)
      });
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleWallCommentDelete = async (commentId) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      await deleteDoc(doc(db, "users", userId, "wallComments", commentId));
    } catch (error) {
      console.error('Error deleting wall comment:', error);
    }
  };

  const handleStoryDelete = async (storyId) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      await deleteDoc(doc(db, "users", userId, "stories", storyId));
    } catch (error) {
      console.error('Error deleting story:', error);
    }
  };

  const handleStoryEdit = async (storyId, newText) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    try {
      await updateDoc(doc(db, "users", userId, "stories", storyId), {
        text: newText,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error editing story:', error);
    }
  };

  const handleSubscribe = async () => {
    const currentUserDoc = doc(db, "users", auth.currentUser.uid);
    if (isSubscribed) {
      await updateDoc(currentUserDoc, { subscriptions: arrayRemove(userId) });
      setIsSubscribed(false);
    } else {
      await updateDoc(currentUserDoc, { subscriptions: arrayUnion(userId) });
      setIsSubscribed(true);

      const userDoc = await getDoc(doc(db, 'users', userId));
      const token = userDoc.data().fcmToken;
      const senderDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const senderName = senderDoc.data().name || auth.currentUser.uid;
      if (token) {
        await sendNotification(token, null, auth.currentUser.uid, senderName, `${senderName} подписался на вас`, 'subscribe');
      }
    }
  };

  const handleMessage = async () => {
    const chatId = [auth.currentUser.uid, userId].sort().join("_");
    await setDoc(doc(db, "chats", chatId), {
      users: [auth.currentUser.uid, userId],
      pinned: false,
    }, { merge: true });

    const selectedAvatar = profileData.avatars?.[selectedAvatarIndex] || profileData.avatar;
    dispatch(setSelectedUser({ uid: userId, name: profileData.name, avatar: selectedAvatar }));
    dispatch(setActiveChat(chatId));
    navigate('/chat', { state: { chatId, selectedUser: { uid: userId, name: profileData.name, avatar: selectedAvatar } } });
  };

  const handleCall = () => {
    alert("Функция звонков находится в разработке");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCommentSubmit = async (postId, commentText) => {
    if (!commentText.trim() || !auth.currentUser) return;
    const postRef = doc(db, "users", userId, "posts", postId);
    await updateDoc(postRef, {
      comments: arrayUnion({
        text: commentText,
        author: auth.currentUser.uid,
        createdAt: new Date(),
      }),
    });

    if (userId !== auth.currentUser.uid) {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const token = userDoc.data().fcmToken;
      const senderDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const senderName = senderDoc.data().name || auth.currentUser.uid;
      if (token) {
        await sendNotification(token, null, auth.currentUser.uid, senderName, `${senderName} прокомментировал ваш пост: ${commentText}`, 'comment');
        await addDoc(collection(db, "users", userId, "notifications"), {
          type: 'comment',
          fromUserId: auth.currentUser.uid,
          contentId: postId,
          message: `${senderName} прокомментировал ваш пост: ${commentText}`,
          isRead: false,
          createdAt: new Date(),
        });
      }
    }
  };

  const handleLike = async (postId) => {
    if (!auth.currentUser) return;
    const postRef = doc(db, "users", userId, "posts", postId);
    const post = posts.find(p => p.id === postId);
    const hasLiked = post.likes.includes(auth.currentUser.uid);
    if (hasLiked) {
      await updateDoc(postRef, {
        likes: arrayRemove(auth.currentUser.uid),
      });
    } else {
      await updateDoc(postRef, {
        likes: arrayUnion(auth.currentUser.uid),
      });

      if (userId !== auth.currentUser.uid) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        const token = userDoc.data().fcmToken;
        const senderDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        const senderName = senderDoc.data().name || auth.currentUser.uid;
        if (token) {
          await sendNotification(token, null, auth.currentUser.uid, senderName, `${senderName} лайкнул ваш пост`, 'like');
          await addDoc(collection(db, "users", userId, "notifications"), {
            type: 'like',
            fromUserId: auth.currentUser.uid,
            contentId: postId,
            message: `${senderName} лайкнул ваш пост`,
            isRead: false,
            createdAt: new Date(),
          });
        }
      }
    }
  };

  const handleMediaUpload = async () => {
    if (!newMediaFiles.length || !auth.currentUser || auth.currentUser.uid !== userId) return;

    const uploadPromises = newMediaFiles.map(async (file) => {
      const storageRef = ref(storage, `media/${userId}/${file.name}_${Date.now()}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      await addDoc(collection(db, "users", userId, "media"), {
        url,
        createdAt: new Date(),
      });
    });

    await Promise.all(uploadPromises);
    setNewMediaFiles([]);
  };

  const handleAvatarUpload = async () => {
    if (!newAvatarFiles.length || !auth.currentUser || auth.currentUser.uid !== userId) return;

    const uploadPromises = newAvatarFiles.map(async (file) => {
      const storageRef = ref(storage, `avatars/${userId}/${file.name}_${Date.now()}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return url;
    });

    const newAvatarUrls = await Promise.all(uploadPromises);
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      avatars: arrayUnion(...newAvatarUrls),
      selectedAvatarIndex: (profileData.avatars?.length || 0) + newAvatarUrls.length - 1,
    });
    setNewAvatarFiles([]);
  };

  const handleFileChange = (e) => {
    setNewMediaFiles(Array.from(e.target.files));
  };

  const handleAvatarFileChange = (e) => {
    setNewAvatarFiles(Array.from(e.target.files));
  };

  const openImage = (url, index, images = profileData.avatars || [profileData.avatar], collectionType = 'avatars') => {
    setCurrentImageIndex(index !== -1 ? index : 0);
    setSelectedImage(url);
    setImages(images);
    setCollectionType(collectionType);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const selectAvatar = async (index) => {
    setSelectedAvatarIndex(index);
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, { selectedAvatarIndex: index });
  };

  const handleShowSubscribers = () => setShowSubscribers(true);
  const handleShowSubscriptions = () => setShowSubscriptions(true);
  const closeModal = () => {
    setShowSubscribers(false);
    setShowSubscriptions(false);
  };

  const handleBorderColorChange = (color) => {
    setAvatarBorderColor(color);
  };

  const toggleHighlight = () => {
    setIsAvatarHighlighted(prev => !prev);
  };

  const toggleTheme = () => {
    const themes = ['white', 'night-mode', 'whiteLand', 'pureDark'];
    const currentIndex = themes.indexOf(theme);
    const nextIndex = (currentIndex + 1) % themes.length;
    setTheme(themes[nextIndex]);
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (!profileData) return <div className="not-found">Пользователь не найден</div>;

  return (
      <div className={`user-profile-container ${theme}`}>
        <button className="back-button" onClick={handleBack}>←</button>
        <button className="theme-toggle-btn" onClick={toggleTheme}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3V5M12 19V21M4.22 4.22L5.64 5.64M18.36 18.36L19.78 19.78M3 12H5M19 12H21M4.22 19.78L5.64 18.36M18.36 5.64L19.78 4.22" stroke="var(--text-color-primary)" strokeWidth="2"/>
          </svg>
        </button>
        <div className="user-profile-content">
          <ProfileHeader
              profileData={profileData}
              auth={auth}
              userId={userId}
              isSubscribed={isSubscribed}
              subscriptions={subscriptions}
              subscribers={subscribers}
              selectedAvatarIndex={selectedAvatarIndex}
              newAvatarFiles={newAvatarFiles}
              handleSubscribe={handleSubscribe}
              handleMessage={handleMessage}
              handleCall={handleCall}
              handleAvatarFileChange={handleAvatarFileChange}
              handleAvatarUpload={handleAvatarUpload}
              openImage={(url) => openImage(url, (profileData.avatars || []).indexOf(url), profileData.avatars || [profileData.avatar], 'avatars')}
              selectAvatar={selectAvatar}
              handleShowSubscribers={handleShowSubscribers}
              handleShowSubscriptions={handleShowSubscriptions}
              avatarBorderColor={avatarBorderColor}
              isAvatarHighlighted={isAvatarHighlighted}
              handleBorderColorChange={handleBorderColorChange}
              toggleHighlight={toggleHighlight}
          />
          {isEditing && auth.currentUser?.uid === userId ? (
              <ProfileForm formData={formData} handleChange={handleChange} handleSave={handleSave} handleEditToggle={handleEditToggle} />
          ) : (
              <ProfileInfo profileData={profileData} />
          )}
          <ProfileMedia
              auth={auth}
              userId={userId}
              media={media}
              newMediaFiles={newMediaFiles}
              handleFileChange={handleFileChange}
              handleMediaUpload={handleMediaUpload}
              openImage={(url, index) => openImage(url, index, media, 'media')}
          />
          <Stories
              userId={userId}
              stories={stories}
              auth={auth}
              handleStoryDelete={handleStoryDelete}
              handleStoryEdit={handleStoryEdit}
          />
          <ProfileWall
              auth={auth}
              userId={userId}
              posts={posts}
              newPost={newPost}
              setNewPost={setNewPost}
              wallComments={wallComments}
              newWallComment={newWallComment}
              setNewWallComment={setNewWallComment}
              handlePostSubmit={handlePostSubmit}
              handleWallCommentSubmit={handleWallCommentSubmit}
              handleCommentSubmit={handleCommentSubmit}
              handleLike={handleLike}
              handlePostDelete={handlePostDelete}
              handlePostEdit={handlePostEdit}
              handleCommentDelete={handleCommentDelete}
              handleWallCommentDelete={handleWallCommentDelete}
          />
        </div>
        {selectedImage && (
            <FullscreenImage
                selectedImage={selectedImage}
                closeImage={closeImage}
                images={images}
                currentIndex={currentImageIndex}
                setCurrentIndex={setCurrentImageIndex}
                userId={userId}
                setSelectedImage={setSelectedImage}
                collectionType={collectionType}
            />
        )}
        {(showSubscribers || showSubscriptions) && (
            <FollowModal
                showSubscribers={showSubscribers}
                subscribers={subscribers}
                subscriptions={subscriptions}
                closeModal={closeModal}
                navigate={navigate}
            />
        )}
      </div>
  );
};

export default UserProfile;