import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection, addDoc, doc, setDoc, updateDoc, deleteDoc, getDocs, getDoc, query, where } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "weekend-736ef.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: "138327812477",
  appId: "1:138327812477:web:2a5641eacae1245cd68414",
  measurementId: "G-KPG96P9Z3E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const messaging = getMessaging(app);

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'ТВОЙ_VAPID_КЛЮЧ' // Вставь свой VAPID ключ из Firebase Console
      });
      console.log('FCM Token:', token);
      return token;
    }
  } catch (error) {
    console.error('Ошибка получения токена FCM:', error);
  }
};

// Получение сообщений в foreground
onMessage(messaging, (payload) => {
  console.log('Сообщение получено в foreground:', payload);
});

export {
  db, collection, addDoc, auth, doc, setDoc, updateDoc, deleteDoc, getDocs, getDoc,
  storage, provider, query, where, messaging, requestNotificationPermission
};