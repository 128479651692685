import './App.css';
import React, { useEffect, useState } from "react";
import { useTelegram } from "./Components/hooks/useTelegram";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "./Components/header/header";
import CarForm from "./Components/Form/CarForm/CarForm";
import Profile from "./Components/Profile/Profile";
import MyProfileForUsers from "./Components/Profile/MyProfileForUsers/MyProfileForUsers";
import NavBar from "./Components/NavBar/NavBar";
import Map from "./Components/Map/map";
import UnavailableCategoryPage from "./Components/UnavailableCategoryPage/UnavailableCategoryPage";
import PartnerForm from "./Components/Form/PartnerForm/PartnerForm";
import ProtectedRoute from "./ProtectedRoute";
import { LocalizationProvider } from "./Services/localization/LocalizationContext";
import { GlobalFilterProvider } from "./Services/context/GlobalFilterContext";
import ModerationPage from './Components/ModerationPage/ModerationPage';
import "flag-icons/css/flag-icons.min.css";
import { UserProvider } from "./Services/context/UserContext";
import ProductListCars from "./Components/ProductListCars/ProductListCars";
import ProductListFlats from "./Components/ProductListFlat/ProductListFlats";
import AddCars from "./Components/AddForm/AddCars/AddCars";
import Crm from "./Components/CRM/Crm";
import Home from "./Components/Home/Home";
import AppLoader from "./Components/Loader/AppLoader";
import SupportForm from './Components/Profile/SupportForm/SupportForm';
import Orders from "./Components/Orders/Orders";
import AuthPage from "./Components/AuthPage/AuthPage";
import FlatForm from "./Components/Form/FlatForm/FlatForm";
import CarDetailCard from './Components/ProductListCars/СarDetailCard/CarDetailCard';
import FlatDetailCard from './Components/ProductListFlat/FlatDetailCard/FlatDetailCard';
import Likespage from "./Components/LikesPage/Likespage";
import UserProfile from "./Components/Chat/Profile/UserProfile";
import Chat from "./Components/Chat/chat/ChatContainer";
import { AuthProvider, useAuth } from "./Services/context/AuthContext";
import { ChatNotificationProvider } from "./Services/context/ChatNotificationContext";
import { Provider, useDispatch } from 'react-redux';
import { store } from './redux/store';
import NotificationPopup from './Components/Chat/chat/notificationPopup/NotificationPopup';
import { initializeFCM } from './Services/fcmService';

export const CategoryContext = React.createContext();

const AppContent = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const { onToggleButton, tg } = useTelegram();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    tg.ready();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (currentUser) {
      initializeFCM(dispatch);
    }
  }, [currentUser, dispatch]);

  if (loading || isLoading) {
    return <AppLoader />;
  }

  return (
    <div className="App">
      <Header />
      <main className="main-content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/chat" element={
            <ProtectedRoute allowedRoles={['user', 'partner', 'admin']}>
              <Chat />
            </ProtectedRoute>
          } />
          <Route path="/product-list-cars" element={<ProductListCars />} />
          <Route path="/car/:carId" element={<CarDetailCard />} />
          <Route path="/flat/:flatId" element={<FlatDetailCard />} />
          <Route path="/product-list-flats" element={<ProductListFlats />} />
          <Route path="/product-list-hotel" element={<ProductListFlats />} />
          <Route path="/product-list-house" element={<ProductListFlats />} />
          <Route path="/product-list-villa" element={<ProductListFlats />} />
          <Route path="/we" element={<CarForm />} />
          <Route path="/iei" element={<FlatForm />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={
            <ProtectedRoute allowedRoles={['user', 'partner', 'admin']}>
              <Profile />
            </ProtectedRoute>
          } />
          <Route path="/my-profile/:userId" element={<MyProfileForUsers />} />
          <Route path="/map" element={<Map />} />
          <Route path="/unavailable-category" element={<UnavailableCategoryPage />} />
          <Route path="/become-partner" element={<PartnerForm />} />
          <Route path="/my-likes" element={<Likespage />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
          <Route path="/moderation" element={
            <ProtectedRoute allowedRoles={['partner', 'admin']}>
              <ModerationPage />
            </ProtectedRoute>
          } />
          <Route path="/CRM" element={
            <ProtectedRoute allowedRoles={['partner', 'admin']}>
              <Crm />
            </ProtectedRoute>
          } />
          <Route path="/orders" element={<Orders />} />
          <Route path="/support" element={<SupportForm />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="*" element={<Home />} /> {/* Изменено с AuthPage на Home для неавторизованных */}
        </Routes>
        <NotificationPopup />
      </main>
      <NavBar />
    </div>
  );
};

function App() {
  const [activeCategories, setActiveCategories] = useState(() => {
    const storedCategories = sessionStorage.getItem('activeCategories');
    return storedCategories ? JSON.parse(storedCategories) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('activeCategories', JSON.stringify(activeCategories));
  }, [activeCategories]);

  return (
    <Provider store={store}>
      <AuthProvider>
        <ChatNotificationProvider>
          <UserProvider>
            <LocalizationProvider>
              <GlobalFilterProvider>
                <CategoryContext.Provider value={{ activeCategories, setActiveCategories }}>
                  <AppContent />
                </CategoryContext.Provider>
              </GlobalFilterProvider>
            </LocalizationProvider>
          </UserProvider>
        </ChatNotificationProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;