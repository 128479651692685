// src/Components/Chat/Group/GroupCreate.jsx
import { useState, useEffect } from "react";
import { db, auth } from "../../../firebase/firebase";
import { collection, addDoc, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import "../css/GroupStyles.css";

const GroupCreate = ({ onGroupCreated, onClose }) => {
  const [groupName, setGroupName] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [groupPhoto, setGroupPhoto] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!auth.currentUser) {
        setLoadingUsers(false);
        return;
      }

      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "!=", auth.currentUser.uid));
        const snapshot = await getDocs(q);
        const userList = snapshot.docs.map(doc => ({
          uid: doc.id,
          name: doc.data().name,
        }));
        setUsers(userList);
        setFilteredUsers(userList);
        setLoadingUsers(false);
      } catch (error) {
        console.error("Ошибка загрузки пользователей:", error);
        setLoadingUsers(false);
      }
    };

    fetchUsers();
  }, []);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setGroupPhoto(URL.createObjectURL(file));
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(query)));
  };

  const handleUserToggle = (uid) => {
    if (selectedUsers.includes(uid)) {
      setSelectedUsers(selectedUsers.filter(id => id !== uid));
    } else {
      setSelectedUsers([...selectedUsers, uid]);
    }
  };

  const createGroup = async (e) => {
    e.preventDefault();
    if (!groupName.trim() || !auth.currentUser) return;

    const groupMembers = [auth.currentUser.uid, ...selectedUsers];
    const groupData = {
      name: groupName,
      creator: auth.currentUser.uid,
      privacy,
      members: groupMembers,
      createdAt: new Date(),
      photoURL: groupPhoto || "",
    };

    const groupRef = await addDoc(collection(db, "groups"), groupData);
    const chatId = `${auth.currentUser.uid}_${groupRef.id}`;
    await setDoc(doc(db, "chats", chatId), {
      users: groupMembers,
      isGroup: true,
      groupId: groupRef.id,
      pinned: true, // Закрепляем навсегда
      createdAt: new Date(),
    });

    onGroupCreated({ id: groupRef.id, name: groupName, privacy });
    onClose();
  };

  return (
    <div className="group-create-modal">
      <h2>Создать новую группу</h2>
      <form onSubmit={createGroup}>
        <div className="form-group">
          <label htmlFor="groupName">Название группы:</label>
          <input
            id="groupName"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Введите название группы"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="groupPhoto">Фото группы:</label>
          <input
            id="groupPhoto"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
          {groupPhoto && <img src={groupPhoto} alt="Preview" className="group-photo-preview" />}
        </div>
        <div className="form-group">
          <label htmlFor="privacy">Тип группы:</label>
          <select
            id="privacy"
            value={privacy}
            onChange={(e) => setPrivacy(e.target.value)}
          >
            <option value="public">Публичная</option>
            <option value="private">Приватная</option>
          </select>
        </div>
        <div className="user-selection">
          <h3>Пригласить пользователей</h3>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Поиск пользователей..."
            className="user-search"
          />
          {loadingUsers ? (
            <p>Загрузка пользователей...</p>
          ) : filteredUsers.length === 0 ? (
            <p>Пользователи не найдены.</p>
          ) : (
            <ul>
              {filteredUsers.map(user => (
                <li key={user.uid}>
                  <span>{user.name}</span>
                  <button
                    type="button"
                    className={selectedUsers.includes(user.uid) ? "remove-btn" : "add-btn"}
                    onClick={() => handleUserToggle(user.uid)}
                  >
                    {selectedUsers.includes(user.uid) ? "−" : "+"}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="modal-actions">
          <button type="button" onClick={onClose} className="cancel-btn">
            Отмена
          </button>
          <button type="submit" disabled={!auth.currentUser || loadingUsers}>
            Создать
          </button>
        </div>
      </form>
    </div>
  );
};

export default GroupCreate;