import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from './firebase/firebase'; // Убедись, что путь корректен

const ProtectedRoute = ({ allowedRoles, children }) => {
    const [currentUser, setCurrentUser] = useState(auth.currentUser);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    // Подписываемся на изменения авторизации
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            if (!user) {
                setLoading(false); // Если пользователь не авторизован, сразу завершаем загрузку
            }
        });
        return () => unsubscribe();
    }, []);

    // Загружаем роль пользователя из Firestore
    useEffect(() => {
        const fetchUserRole = async () => {
            if (currentUser) {
                try {
                    const docRef = doc(db, 'users', currentUser.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setRole(docSnap.data().role || 'user'); // Устанавливаем роль по умолчанию 'user', если её нет
                    } else {
                        setRole('user'); // Если документа нет, считаем пользователя обычным юзером
                    }
                } catch (error) {
                    console.error('Ошибка получения роли пользователя:', error);
                    setRole('user'); // В случае ошибки тоже устанавливаем 'user'
                }
            }
            setLoading(false);
        };

        fetchUserRole();
    }, [currentUser]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    // Если пользователь не авторизован, перенаправляем на /login
    if (!currentUser) {
        return <Navigate to="/login" replace />;
    }

    // Если роль пользователя не входит в список разрешённых, показываем ошибку
    if (!allowedRoles.includes(role)) {
        return (
            <div className={'centring error-forbiden'}>
                Ошибка прав доступа: у вас недостаточно прав для просмотра этой страницы.
            </div>
        );
    }

    // Если всё в порядке, рендерим дочерний компонент
    return children;
};

export default ProtectedRoute;