import React, { useState, useEffect, useRef } from 'react';
import './AvatarGame.css';

const AvatarGame = ({ onAvatarClick, currentAvatar, userName }) => {
  const [gameStep, setGameStep] = useState(0);
  const [gameMessage, setGameMessage] = useState('');
  const [isGameActive, setIsGameActive] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [gameLevel, setGameLevel] = useState(0);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isGifLoaded, setIsGifLoaded] = useState(false);
  const [gameVariant, setGameVariant] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false); // Track interaction state
  const clickTimeoutRef = useRef(null);
  const messageRef = useRef(null);

  const gameStepsInitial = [
    {
      message: `Привет, ${userName}! Твой аватар хочет отправиться в приключение. Кликни на него, чтобы начать!`,
      nextStep: 1,
    },
    {
      message: 'Аватар ожил! Он просит тебя найти волшебный предмет. Кликни еще раз, чтобы помочь ему.',
      nextStep: 2,
    },
    {
      message: 'Ты нашел магический кристалл! Аватар светится от радости. Кликни, чтобы использовать кристалл💎.',
      nextStep: 3,
    },
    {
      message: 'Победа! Твой аватар стал сильнее и благодарит тебя. Кликни, чтобы завершить квест.',
      nextStep: 0,
      reset: true,
    },
  ];

  const gameStepsVariant = [
    {
      message: `Привет снова, ${userName}! Аватар жаждет нового испытания. Кликни, чтобы отправиться в путь!`,
      nextStep: 1,
    },
    {
      message: 'Ты нашел древний лес! Аватар ищет магический цветок. Кликни, чтобы продолжить.',
      nextStep: 2,
    },
    {
      message: 'Цветок найден! Аватар оживает в новом сиянии. Кликни, чтобы завершить ритуал🌸.',
      nextStep: 3,
    },
    {
      message: 'Успех! Аватар обрел новые силы. Кликни, чтобы закончить приключение.',
      nextStep: 0,
      reset: true,
    },
  ];

  const gifDuration = 1000;

  useEffect(() => {
    const img = new Image();
    img.src = currentAvatar;
    img.onload = () => setIsGifLoaded(true);
  }, [currentAvatar]);

  const handleSingleClick = () => {
    if (!isGifLoaded) return;

    clearTimeout(clickTimeoutRef.current);
    setIsInteracting(true); // Avatar moves in front
    clickTimeoutRef.current = setTimeout(() => {
      onAvatarClick();
      setClickCount((prev) => prev + 1);

      const avatar = document.querySelector('.game-avatar');
      if (avatar) {
        const actionIndex = clickCount % 3;
        if (actionIndex === 0) {
          avatar.classList.add('jump');
          setTimeout(() => avatar.classList.remove('jump'), gifDuration);
        } else if (actionIndex === 1) {
          avatar.classList.add('spin');
          setTimeout(() => avatar.classList.remove('spin'), gifDuration);
        } else if (actionIndex === 2) {
          avatar.classList.add('pulse');
          setTimeout(() => avatar.classList.remove('pulse'), gifDuration);
        }

        if (isGameActive && isMessageVisible) {
          const currentSteps = gameVariant === 0 ? gameStepsInitial : gameStepsVariant;
          const currentStep = currentSteps[gameStep];
          setGameMessage(currentStep.message);

          if (currentStep.reset) {
            setIsGameActive(false);
            setGameCompleted(true);
            setGameLevel((prev) => prev + 1);
            setGameStep(0);
            setClickCount(0);
            setGameMessage(`Игра "Crystal Quest" пройдена! Уровень ${gameLevel + 1} достигнут.`);
          } else {
            setGameStep(currentStep.nextStep);
          }

          avatar.classList.add('jump');
          setTimeout(() => avatar.classList.remove('jump'), gifDuration);
        }
      }
    }, 300);
  };

  const handleDoubleClick = () => {
    if (!isGifLoaded) return;

    clearTimeout(clickTimeoutRef.current);
    setIsInteracting(true); // Avatar moves in front
    const avatar = document.querySelector('.game-avatar');
    if (avatar) {
      avatar.classList.add('press');
      setTimeout(() => {
        avatar.classList.remove('press');
        if (!isMessageVisible) {
          setGameMessage('');
          setIsMessageVisible(true);
        }
      }, 200);
    }
  };

  const startGame = () => {
    setIsGameActive(true);
    setGameStep(0);
    setClickCount(0);
    setGameMessage((gameVariant === 0 ? gameStepsInitial : gameStepsVariant)[0].message);
  };

  const closeGame = (e) => {
    if (!isMessageVisible || (e.target !== null && (e.target === messageRef.current || messageRef.current.contains(e.target)))) return;
    const avatar = document.querySelector('.game-avatar');
    if (avatar) {
      avatar.classList.add('press');
      setTimeout(() => {
        avatar.classList.remove('press');
        setIsMessageVisible(false);
        setIsGameActive(false);
        setGameStep(0);
        setClickCount(0);
        setGameMessage('');
        setIsInteracting(false); // Avatar returns behind role
      }, 200);
    }
  };

  const handleReplay = () => {
    setGameVariant(1);
    startGame();
  };

  return (
    <div className="avatar-game-container" onClick={closeGame}>
      <img
        src={currentAvatar}
        alt="Avatar"
        className={`game-avatar ${isGameActive && gameStep === 3 ? 'avatar-glowing' : ''} ${isInteracting ? 'active' : ''}`}
        onClick={handleSingleClick}
        onDoubleClick={handleDoubleClick}
      />
      {isMessageVisible && (
        <div className="game-message" ref={messageRef}>
          {!isGameActive && !gameCompleted && (
            <div className="game-badge" onClick={startGame}>
              <span className="badge-icon">✨</span>
              <span className="badge-title">Crystal Quest</span>
            </div>
          )}
          {gameMessage && <p>{gameMessage}</p>}
          {gameCompleted && (
            <div className="game-badge" onClick={handleReplay}>
              <span className="badge-icon">✨</span>
              <span className="badge-title">Crystal Quest</span>
              <span className="badge-level">Уровень {gameLevel}</span>
            </div>
          )}
          {isMessageVisible && (
            <span className="close-button" onClick={() => closeGame({ target: null })}>
              ✕
            </span>
          )}
          {gameCompleted && <span className="grok-signature">Grok’s Touch</span>}
        </div>
      )}
    </div>
  );
};

export default AvatarGame;