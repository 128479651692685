import React, {useContext, useEffect, useState} from 'react';
import { auth, db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import CarCard from "../Cards/CarCard/CarCard";
import FlatCard from "../Cards/FlatCard/FlatCard";
import OrderFlat from "../Orders/OrderCards/OrderFlat";
import OrderCar from "../Orders/OrderCards/OrderCar";
import './Orders.css'
import {LocalizationContext} from "../../Services/localization/LocalizationContext";
import { useNavigate } from 'react-router-dom';
import BackButton from "../button/BackBotton/BackButton";
const Orders = () => {
    const [orders, setOrders] = useState([]); // Хранение данных о заказанных машинах
    const [loading, setLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(null); // Состояние ошибки
    const { translations } = useContext(LocalizationContext);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUserOrders = async () => {
            try {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('User is not logged in');
                }

                // Получаем ссылку на документ пользователя
                const userRef = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    const userOrders = userData.UserOrders || [];

                    // Фетч данных по каждому ID из массива UserOrders
                    const carsData = await Promise.all(
                        userOrders.map(async (orderId) => {
                            let itemData = null;
                            let itemRef = doc(db, 'cars', orderId); // Проверяем коллекцию 'cars'
                            let itemSnap = await getDoc(itemRef);

                            if (itemSnap.exists()) {
                                itemData = { id: orderId, type: 'car', ...itemSnap.data() }; // Добавляем тип 'car'
                            } else {
                                console.warn(`Item with ID ${orderId} not found in 'cars', checking 'flats'.`);
                                itemRef = doc(db, 'flats', orderId); // Проверяем коллекцию 'flats'
                                itemSnap = await getDoc(itemRef);

                                if (itemSnap.exists()) {
                                    itemData = { id: orderId, type: 'flat', ...itemSnap.data() }; // Добавляем тип 'flat'
                                } else {
                                    console.warn(`Item with ID ${orderId} not found in both collections.`);
                                }
                            }

                            return itemData;
                        })
                    );

// Фильтруем только успешные фетчи
                    setOrders(carsData.filter((car) => car !== null));


                    // Фильтруем только успешные фетчи
                    setOrders(carsData.filter((car) => car !== null));
                } else {
                    console.warn('User document does not exist.');
                }
            } catch (err) {
                console.error('Error fetching user orders:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserOrders();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    const currentUser = auth.currentUser;
    return (
        <div className='ORDERS'>
            <h2 className={'OrdersTitle'}>Your Orders</h2>
            <BackButton translations={translations} navigate={navigate} />
            <div className="orders-list">
                {orders.length > 0 ? (
                    orders.map((order) => {
                        if (order.type === 'car') {
                            return <OrderCar
                                class={'cards'}
                                key={order.id}
                                car={order}
                                currentUser={currentUser}/>;
                        } else if (order.type === 'flat') {
                            return <OrderFlat
                                class={'cards'}
                                key={order.id}
                                flat={order}
                                currentUser={currentUser}/>;
                        } else {
                            return null; // На случай, если тип не определен
                        }
                    })
                ) : (
                    <p>No orders found.</p>
                )}
            </div>

        </div>
    );
};

export default Orders;
