import { useState, useEffect } from 'react';
import { db, auth } from '../../firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const useProfileData = (userId) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      const uid = userId || auth.currentUser?.uid;
      if (!uid) {
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfileData(docSnap.data());
        }
      } catch (error) {
        console.error("Ошибка загрузки профиля:", error);
      }
      setLoading(false);
    };

    fetchProfileData();
  }, [userId]);

  const updateProfile = async (newData) => {
    if (!auth.currentUser) return;
    try {
      const docRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(docRef, newData);
      setProfileData(prev => ({ ...prev, ...newData }));
    } catch (error) {
      console.error("Ошибка обновления профиля:", error);
    }
  };

  return { profileData, loading, updateProfile };
};

export default useProfileData;