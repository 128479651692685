import React, { useState, useEffect, useContext } from 'react';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import ImageUpload from "../../ImageUpload/ImageUpload";
import AddMap from "../../AddMap/AddMap";

import './EditCarModal.css';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import ChangeImage from "../ChangeImage";
import MultiplieChangeImage from "../MultiplieChangeImage";
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';

const EditCarModal = ({ car, isOpen, onClose, reloadCars }) => {
    const { translations } = useContext(LocalizationContext);
    const [carData, setCarData] = useState(car);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCarData(car);
    }, [car]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCarData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCarData(prevData => ({
            ...prevData,
            [name]: checked ? "Есть" : "Нет"
        }));
    };

    // Функция для загрузки файлов в Firebase Storage
    const uploadFile = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Прогресс загрузки (можно добавить индикатор загрузки)
                },
                (error) => {
                    console.error("Ошибка загрузки файла: ", error);
                    reject(error);
                },
                () => {
                    // Получаем URL после успешной загрузки
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    }).catch(reject);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const firestore = getFirestore();
        const carDocRef = doc(firestore, 'flats', carData.id);

        try {
            let updatedCarData = { ...carData };

            // Загрузка одного изображения, если оно есть
            if (carData.singleImage) {
                const singleImageUrl = await uploadFile(carData.singleImage);
                updatedCarData.singleImageUrl = singleImageUrl; // Обновляем URL изображения
            }

            // Загрузка нескольких изображений, если они есть
            if (carData.multipleImages && carData.multipleImages.length > 0) {
                const multipleImagesUrls = await Promise.all(
                    carData.multipleImages.map(file => uploadFile(file))
                );
                updatedCarData.multipleImagesUrls = multipleImagesUrls; // Обновляем URL-ы изображений
            }

            delete updatedCarData.singleImage;
            delete updatedCarData.multipleImages;

            updatedCarData.status = 'moderation'; // Устанавливаем статус "moderation"

            // Обновляем документ автомобиля в Firestore
            await updateDoc(carDocRef, updatedCarData);
            await reloadCars(); // Перезагружаем список автомобилей

            onClose(updatedCarData); // Закрываем модальное окно и передаем обновленные данные

        } catch (error) {
            console.error("Ошибка при обновлении автомобиля: ", error);
        } finally {
            setLoading(false);
        }
    };



    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className={'close-buttonWrapper'} style={{width: '100%'}}>
                    <button className={'close-button'} onClick={(event) => onClose()}>×</button>
                </div>
                <h2>{translations.editCarModal.editCarTitle}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.companyPlaceholder}
                            name="company"
                            value={carData.company || ''}
                            onChange={handleChange}
                        />
                        <select
                            name="city"
                            value={carData.city || ''}
                            onChange={handleChange}
                        >
                            <option disabled>{translations.editCarModal.cityPlaceholder}</option>
                            <option>{translations.editCarModal.tbilisi}</option>
                            <option>{translations.editCarModal.batumi}</option>
                        </select>
                    </div>

                    <div className="disFlex">
                        <select
                            name="dealtype"
                            value={carData.dealType || ''}
                            onChange={handleChange}
                        >
                            <option value="">Тип сделки</option>
                            <option value="аренда">Аренда</option>
                            <option value="продажа">Продажа</option>
                            <option value="посуточно">Посуточно</option>
                        </select>
                        <select
                            name="dealtype"
                            value={carData.propertyType || ''}
                            onChange={handleChange}
                        >
                            <option value="">Тип недвижимости</option>
                            <option value="Квартира">Квартира</option>
                            <option value="Частный дом">Частный дом</option>
                            <option value="Вилла">Вилла</option>
                            <option value="Отель">Отель</option>
                            <option value="Коммерческое помещение">Коммерческое помещение</option>
                            <option value="Участок">Участок</option>
                            <option value="Коворкинг">Коворкинг</option>
                        </select>
                    </div>

                    <div className="disFlex">

                        <select
                            name="dealtype"
                            value={carData.condition || ''}
                            onChange={handleChange}
                        >
                            <option value="">Состояние</option>
                            <option value="Новое">Новое</option>
                            <option value="Вторичное">Вторичное</option>
                            <option value="Черный каркас">Черный каркас</option>
                            <option value="Белый каркас">Белый каркас</option>
                        </select>
                        <input
                            type="number"
                            // placeholder={translations.editCarModal.volumePlaceholder}
                            name="volume"
                            value={carData.area || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="disFlex">
                        <input
                            type="number"
                            // placeholder={translations.editCarModal.volumePlaceholder}
                            name="rooms"
                            value={carData.rooms || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="number"
                            // placeholder={translations.editCarModal.volumePlaceholder}
                            name="floor"
                            value={carData.floor || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={'disFlex'}>
                        <input
                            type="number"
                            // placeholder={translations.editCarModal.volumePlaceholder}
                            name="bathrooms"
                            value={carData.bathrooms || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="number"
                            // placeholder={translations.editCarModal.volumePlaceholder}
                            name="bedrooms"
                            value={carData.bedrooms || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="disFlex">

                        <label>
                            <input
                                type="checkbox"
                                name="petsAllowed"
                                checked={carData.petsAllowed === true}
                                onChange={handleCheckboxChange}
                            />
                            petsAllowed
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="hasElevator"
                                checked={carData.hasElevator === true}
                                onChange={handleCheckboxChange}
                            />
                            hasElevator
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="hasHeating"
                                checked={carData.hasHeating === true}
                                onChange={handleCheckboxChange}
                            />
                            hasHeating
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="hasGas"
                                checked={carData.hasGas === true}
                                onChange={handleCheckboxChange}
                            />
                            hasGas
                        </label>
                    </div>



                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price1dayPlaceholder}
                            name="pricePerDay"
                            value={carData.pricePerDay || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price2to3daysPlaceholder}
                            name="pricePer3Days"
                            value={carData.pricePer3Days || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price4to7daysPlaceholder}
                            name="pricePerWeek"
                            value={carData.pricePerWeek || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price8to15daysPlaceholder}
                            name="pricePer14Days"
                            value={carData.pricePer14Days || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="disFlex">
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    placeholder={translations.editCarModal.price16to30daysPlaceholder}*/}
                        {/*    name="price16to30days"*/}
                        {/*    value={carData.price16to30days || ''}*/}
                        {/*    onChange={handleChange}*/}
                        {/*/>*/}
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price31daysPlaceholder}
                            name="pricePerMonth"
                            value={carData.pricePerMonth || ''}
                            onChange={handleChange}
                        />
                    </div>



                    <ChangeImage
                        carId={carData.id} // Передаем ID автомобиля
                        currentImageUrl={carData.imageUrl} // Передаем текущий URL изображения
                        onImageUrlChange={(newUrl) => setCarData(prevData => ({
                            ...prevData,
                            imageUrl: newUrl
                        }))} // Обновляем URL изображения в данных автомобиля
                    />
                    <MultiplieChangeImage
                        carId={carData.id} // Передаем ID автомобиля
                        currentImageUrls={carData.imageUrls || []} // Передаем текущие URL изображений
                        onImageUrlsChange={(newUrls) => setCarData(prevData => ({
                            ...prevData,
                            imageUrls: newUrls
                        }))} // Обновляем URL-ы изображений в данных автомобиля
                    />


                    {/*<ImageUpload*/}
                    {/*    onSingleImageChange={(image) => setCarData(prevData => ({ ...prevData, singleImage: image }))}*/}
                    {/*    onMultipleImagesChange={(files) => setCarData(prevData => ({ ...prevData, multipleImages: Array.from(files) }))}*/}
                    {/*/>*/}
                    <AddMap
                        onMapDataChange={(coordinates) => setCarData(prevData => ({
                            ...prevData,
                            mapCoordinates: coordinates
                        }))}
                    />

                    <button type="submit" disabled={loading} className={'saveEdit'}>
                        {loading ? translations.editCarModal.loading : translations.editCarModal.saveButton}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditCarModal;
