import './PartnerForm.css';
import {useState, useContext} from "react";
import { LocalizationContext} from "../../../Services/localization/LocalizationContext";
import { auth, db, doc, setDoc } from "../../../firebase/firebase";

const PartnerForm = () => {
    const { translations } = useContext(LocalizationContext);
    const [formState, setFormState] = useState({
        name: '',
        phone: '',
        description: '',
        loading: false,
        successMessage: '',
        errorMessage: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [id]: value }
        ));
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState(prevState => ({ ...prevState, loading: true }));

    try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            setFormState({
                ...formState,
                loading: false,
                errorMessage: translations.partnerForm.notLoggedInError
            });
            return;
        }

        const userRef = doc(db, "users", currentUser.uid);

        // Сохраняем заявку в Firebase
        await setDoc(userRef, {
            name: formState.name,
            phone: formState.phone,
            description: formState.description,
            status: "pending" // Указываем статус заявки
        }, { merge: true });

        // Уведомление в Telegram
        const telegramBotToken = '7356584757:AAFMITZXblh8k-FsOJdUK4yr62sUmAxG4gw'; // Укажите токен вашего бота
        const chatId = '-1002135710194'; // Укажите ваш ID чата или группы
        const message = `
🔔 Новый запрос на партнёрство:
👤 Имя: ${formState.name}
📞 Телефон: ${formState.phone}
📝 Описание: ${formState.description}
        `;
        await fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                chat_id: chatId,
                text: message
            })
        });

        setFormState({
            name: '',
            phone: '',
            description: '',
            loading: false,
            successMessage: translations.partnerForm.successMessage,
            errorMessage: ''
        });
    } catch (error) {
        console.error("Error submitting form: ", error);
        setFormState(prevState => ({
            ...prevState,
            loading: false,
            errorMessage: translations.partnerForm.errorMessage
        }));
    }
};


    return (
        <div className="partner-form-container">
            <h2>🤝{translations.partnerForm.title}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">{translations.partnerForm.name}</label>
                    <input
                        type="text"
                        id="name"
                        value={formState.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">{translations.partnerForm.phone}</label>
                    <input
                        type="tel"
                        id="phone"
                        value={formState.phone}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">{translations.partnerForm.description}</label>
                    <textarea
                        id="description"
                        value={formState.description}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="submit" className="form-submit-button">
                    {formState.loading ? translations.partnerForm.submitting : translations.partnerForm.submitButton}
                </button>
            </form>
            {formState.successMessage && <p className="success-message">{formState.successMessage}</p>}
            {formState.errorMessage && <p className="error-message">{formState.errorMessage}</p>}
        </div>
    );
};

export default PartnerForm;
