import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth, storage } from "../../../firebase/firebase";
import { doc, updateDoc, arrayRemove, arrayUnion, collection, getDocs, onSnapshot } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Avatar from "../Shared/Avatar";
import "../css/GroupHeader.css";

const GroupHeader = ({ group, onBack }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [members, setMembers] = useState([]);
  const [moderators, setModerators] = useState([]);
  const [isCreator, setIsCreator] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [groupPhoto, setGroupPhoto] = useState(group.photoURL); // Локальное состояние для фото
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!group?.id) {
      console.log("Group ID is missing:", group);
      setLoading(false);
      return;
    }

    const groupRef = doc(db, "groups", group.id);
    const unsubscribe = onSnapshot(groupRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const groupData = docSnapshot.data();
        const memberIds = groupData.members || [];
        setModerators(groupData.moderators || []);
        setGroupPhoto(groupData.photoURL || ""); // Обновляем фото в реальном времени
        setIsCreator(groupData.creator === auth.currentUser.uid);

        const usersRef = collection(db, "users");
        getDocs(usersRef).then((snapshot) => {
          const memberList = snapshot.docs
            .filter(doc => memberIds.includes(doc.id))
            .map(doc => ({ uid: doc.id, ...doc.data() }));
          const userList = snapshot.docs
            .filter(doc => doc.id !== auth.currentUser.uid)
            .map(doc => ({ uid: doc.id, ...doc.data() }));
          setMembers(memberList);
          setAllUsers(userList);
          setFilteredUsers(userList);
          setLoading(false);
        }).catch(error => {
          console.error("Ошибка загрузки пользователей:", error);
          setLoading(false);
        });
      } else {
        console.log("Группа не найдена");
        setLoading(false);
      }
    }, (error) => {
      console.error("Ошибка подписки на группу:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [group]);

  const handleAvatarClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => {
    setShowOptions(false);
  };

  const handleLeaveGroup = async () => {
    const groupRef = doc(db, "groups", group.id);
    await updateDoc(groupRef, {
      members: arrayRemove(auth.currentUser.uid),
      moderators: arrayRemove(auth.currentUser.uid),
    });
    onBack();
  };

  const handleRemoveUser = async (userId) => {
    if (!isCreator) return;
    const groupRef = doc(db, "groups", group.id);
    await updateDoc(groupRef, {
      members: arrayRemove(userId),
      moderators: arrayRemove(userId),
    });
  };

  const handleAddUser = async (userId) => {
    if (!isCreator || group.members.includes(userId)) return;
    const groupRef = doc(db, "groups", group.id);
    await updateDoc(groupRef, {
      members: arrayUnion(userId),
    });
  };

  const handleChangePhoto = async () => {
    if (!photoFile || !isCreator) return;
    const storageRef = ref(storage, `groupPhotos/${group.id}`);
    await uploadBytes(storageRef, photoFile);
    const photoURL = await getDownloadURL(storageRef);
    const groupRef = doc(db, "groups", group.id);
    await updateDoc(groupRef, { photoURL });
    setPhotoFile(null);
    // Фото обновится автоматически через onSnapshot
  };

  const handleToggleModerator = async (userId) => {
    if (!isCreator) return;
    const groupRef = doc(db, "groups", group.id);
    const isModerator = moderators.includes(userId);
    await updateDoc(groupRef, {
      moderators: isModerator ? arrayRemove(userId) : arrayUnion(userId),
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredUsers(
      allUsers.filter(
        user => user.name.toLowerCase().includes(query) && !group.members.includes(user.uid)
      )
    );
  };

  const handleMemberClick = (memberId) => {
    navigate(`/profile/${memberId}`);
  };

  return (
    <div className="group-header">
      <button className="back-btn" onClick={onBack}>⬅️</button>
      <div className="group-info">
        <Avatar
          avatar={groupPhoto} // Используем локальное состояние
          userId={group.id}
          size="40px"
          onClick={handleAvatarClick}
          isGroup={true}
        />
        <div className="group-details">
          <h2>{group.name}</h2>
          <span>{loading ? "Загрузка..." : `${members.length} участников`}</span>
        </div>
      </div>
      {showOptions && (
        <div className="group-options">
          <button className="close-btn" onClick={handleCloseOptions}>✖</button>
          <div className="members-list">
            <h3>Участники</h3>
            {loading ? (
              <p>Загрузка участников...</p>
            ) : members.length === 0 ? (
              <p>Нет участников</p>
            ) : (
              <ul>
                {members.map(member => (
                  <li key={member.uid}>
                    <div className="member-info">
                      <Avatar avatar={member.avatar} userId={member.uid} size="24px" />
                      <span
                        className="member-name"
                        onClick={() => handleMemberClick(member.uid)}
                      >
                        {member.name}
                        {member.uid === group.creator && " (Создатель)"}
                        {moderators.includes(member.uid) && " (Модератор)"}
                      </span>
                    </div>
                    <div className="member-actions">
                      {isCreator && member.uid !== auth.currentUser.uid && (
                        <>
                          <button onClick={() => handleRemoveUser(member.uid)}>Удалить</button>
                          <button onClick={() => handleToggleModerator(member.uid)}>
                            {moderators.includes(member.uid) ? "Снять модератора" : "Сделать модератором"}
                          </button>
                        </>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {isCreator && (
            <div className="add-user">
              <h3>Добавить участника</h3>
              <input
                type="text"
                placeholder="Поиск пользователей..."
                value={searchQuery}
                onChange={handleSearch}
                className="user-search"
              />
              {filteredUsers.length > 0 && (
                <ul>
                  {filteredUsers.map(user => (
                    <li key={user.uid}>
                      <div className="member-info">
                        <Avatar avatar={user.avatar} userId={user.uid} size="24px" />
                        {user.name}
                      </div>
                      <button onClick={() => handleAddUser(user.uid)}>+</button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {!isCreator && (
            <button onClick={handleLeaveGroup}>Выйти из группы</button>
          )}
          {isCreator && (
            <div className="photo-change">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setPhotoFile(e.target.files[0])}
              />
              <button onClick={handleChangePhoto} disabled={!photoFile}>
                Сменить фото
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GroupHeader;