import React, {useContext, useEffect, useRef, useState} from 'react';
import authImg from '../forlogin.jpeg'
import './LoginComponent.css'
import { LocalizationContext } from "../../../Services/localization/LocalizationContext";
import { Eye, EyeOff } from 'lucide-react'; // Иконки для глазика
import { signInWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { useLocation, Link } from "react-router-dom";



const LoginComponent = () => {
    const { translations } = useContext(LocalizationContext);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const inputContainerRef = useRef(null);

    const location = useLocation();
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'day-mode'); // Начальное значение темы

    const [formData, setFormData] = useState({
        identifier: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const handleThemeChange = (e) => {
            setTheme(e.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);
        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);


    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            await signInWithEmailAndPassword(auth, formData.identifier, formData.password);
            console.log("User logged in successfully");
        } catch (error) {
            console.error("Login error: ", error.message);
            setErrorMessage(translations.loginForm.invalidCredentials || "Invalid credentials. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            console.log("User logged in with Google successfully");
        } catch (error) {
            console.error("Google login error: ", error.message);
            setErrorMessage("Failed to login with Google. Please try again.");
        }
    };

    const handleAppleSignIn = async () => {
        const provider = new OAuthProvider('apple.com');
        try {
            await signInWithPopup(auth, provider);
            console.log("User logged in with Apple successfully");
        } catch (error) {
            console.error("Apple login error: ", error.message);
            setErrorMessage("Failed to login with Apple. Please try again.");
        }
    };
    const handleGoogleLogin = async () => {
        const result = await signInWithPopup(authentication, googleProvider);
        const user = result.user;
        await handleAuthResult(user);
    };

    return (
        <div className="LoginComponent">
            <div className={'signup-login-container'}>
                <div className={'signup-login-image'}>
                    <img src={authImg} alt="" />
                </div>

                <div className={'signup-login-details'}>

                    <h1 className={'signup-login-title'}>{translations.loginForm.loginToYourAccount}</h1>
                    <button className={'google-login-btn'} onClick={handleGoogleSignIn}>
                        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 89.97 91.8">

                            <path className="cls-111"
                                  d="M120,76.1a57.41,57.41,0,0,0-.8-9.3H75.9V84.5h24.8a20.86,20.86,0,0,1-9.2,13.9l14.8,11.5C115,101.8,120,90,120,76.1Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-112"
                                  d="M75.9,120.9c12.4,0,22.8-4.1,30.4-11.1L91.5,98.4a27.35,27.35,0,0,1-15.6,4.4c-12,0-22.1-8.1-25.8-18.9L34.9,95.6A45.85,45.85,0,0,0,75.9,120.9Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-113"
                                  d="M50.1,83.8a27.88,27.88,0,0,1,0-17.6L34.9,54.4a46,46,0,0,0,0,41.2Z"
                                  transform="translate(-30.03 -29.1)"/>
                            <path className="cls-114"
                                  d="M75.9,47.3a25,25,0,0,1,17.6,6.9L106.6,41A44.17,44.17,0,0,0,75.9,29.1a45.85,45.85,0,0,0-41,25.3L50.1,66.2A27.46,27.46,0,0,1,75.9,47.3Z"
                                  transform="translate(-30.03 -29.1)"/>
                        </svg>
                        {translations.loginForm.logInGoogle}
                    </button>
                    <form onSubmit={handleSubmit} className={'signup-login-details-container'}>

                        <div className="auth-page-phone-info">
                            {translations.loginForm.emailOrPhoneOrLogin}
                        </div>

                        <input
                            className={'signup-login-input'}
                            type="text"
                            id="identifier"

                            value={formData.identifier}
                            onChange={handleInputChange}
                            required
                            placeholder={translations.loginForm.emailOrPhoneOrLogin}
                        />

                        <div className="password-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="signup-login-input"
                                id="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.loginForm.password}
                            />
                            <button
                                type="button"
                                className="toggle-password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                            </button>
                        </div>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <button type="submit" className="submit-button" disabled={loading}>
                            {loading ? "Logging in..." : translations.loginForm.loginButton}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;