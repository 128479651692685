import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db } from "./../../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { UserContext } from '../../../Services/context/UserContext';
import { LocalizationContext } from '../../../Services/localization/LocalizationContext';
import Stories from '../stories/Stories'; // Обновлён путь
import '../Profile.css'; // Используем те же стили, что и в Profile

const MyProfileForUsers = () => {
  const { userData } = useContext(UserContext);
  const { translations } = useContext(LocalizationContext);
  const { userId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!userId) {
        setProfileData(userData);
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfileData(docSnap.data());
        }
      } catch (error) {
        console.error("Ошибка загрузки профиля:", error);
      }
      setLoading(false);
    };
    fetchProfileData();
  }, [userId, userData]);

  if (loading) {
    return <p>{translations?.loading || 'Loading...'}</p>;
  }

  if (!profileData) {
    return <div>Пользователь не найден</div>;
  }

  const profileTranslations = translations?.profilePage || {};

  return (
    <div className="profile-container">
      <div className="profile-grid">
        <div className="name-text">
          <h2>{profileData.name || 'Без имени'}</h2>
        </div>
        <div className="profile-avatar">
          <img
            src={profileData.avatar || 'https://via.placeholder.com/40'}
            alt="Avatar"
            className="avatar"
          />
          <div className="profile-role">
            <div className="profile-role-container">
              <p>{profileTranslations.role || 'Role'}: {profileData.role || profileTranslations.user || 'User'}</p>
            </div>
          </div>
        </div>
        <div className="profile-info">
          <div className="profile-input-item-container">
            <div className="profile-input-item">
              <img src={emailIcon} alt="Email Icon" className="input-icon"/>
              <p>{profileTranslations.email || 'Email'}: {profileData.email}</p>
            </div>
            <div className="profile-input-item">
              <img src={surnameIcon} alt="Surname Icon" className="input-icon"/>
              <p>{profileTranslations.surname || 'Surname'}: {profileData.surname}</p>
            </div>
            <div className="profile-input-item">
              <img src={bioIcon} alt="Bio Icon" className="input-icon"/>
              <p>{profileTranslations.bio || 'Bio'}: {profileData.bio || profileTranslations.noBioAvailable || 'No bio available'}</p>
            </div>
          </div>
        </div>
        <Stories userId={userId || auth.currentUser?.uid} />
      </div>
    </div>
  );
};

export default MyProfileForUsers;