import React, { createContext, useState, useEffect } from 'react';
import { auth, db, doc, getDoc } from "../../firebase/firebase";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        name: '',
        surname: '',
        email: '',
        bio: '',
        avatar: '',
        role: '',
        status: ''
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;

            if (currentUser) {
                const docRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                }
            }
            setLoading(false);
        };

        fetchUserData();
    }, []);

    return (
        <UserContext.Provider value={{ userData, setUserData, loading }}>
            {children}
        </UserContext.Provider>
    );
};
