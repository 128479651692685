import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import { messaging, requestNotificationPermission } from '../firebase/firebase';
import { db, auth } from '../firebase/firebase';
import { doc, updateDoc } from 'firebase/firestore';

export const initializeFCM = (dispatch) => {
  const messagingInstance = getMessaging();

  requestNotificationPermission().then((token) => {
    if (token) {
      console.log('Токен для отправки на сервер:', token);
      // Сохраняем токен в Firestore для текущего пользователя
      if (auth.currentUser) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        updateDoc(userRef, { fcmToken: token }).catch(error => {
          console.error('Ошибка сохранения FCM токена:', error);
        });
      }
    }
  }).catch(error => {
    console.error('Ошибка получения токена:', error);
  });

  onMessage(messagingInstance, (payload) => {
    const { notification, data } = payload;
    dispatch({
      type: 'notifications/addNotification',
      payload: {
        id: payload.messageId || Date.now().toString(),
        sender: data?.sender || notification.title,
        senderName: data?.senderName || notification.title,
        text: notification.body,
        chatId: data?.chatId,
        type: data?.type || 'message',
      },
    });
    if (Notification.permission === 'granted') {
      new Notification(notification.title, {
        body: `${notification.body} (Чат: ${data?.chatId || 'Неизвестный'})`,
        icon: '/firebase-logo.png',
        data: { chatId: data?.chatId },
      });
    }
  });
};

// Функция для отправки уведомлений (для сервера или локального теста)
export const sendNotification = async (token, chatId, sender, senderName, text, type = 'message') => {
  const message = {
    notification: {
      title: senderName || sender,
      body: text,
    },
    data: {
      chatId,
      sender,
      senderName,
      type,
      id: Date.now().toString(),
    },
    token,
  };
  console.log('Отправка уведомления:', message);
  // Здесь должна быть серверная часть через admin.messaging().send(message)
};