import { useState } from "react";
import ChatHeader from "./ChatHeader";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import useDebounce from "../../../Components/hooks/useDebaunce";
import "../css/ChatStyles.css";

const ChatWindow = ({ chatId, selectedUser, onBack, chats }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  if (!chatId || !selectedUser || !selectedUser.uid) {
    console.warn("ChatWindow: Некорректные данные", { chatId, selectedUser });
    return <div className="chat-placeholder">Выберите чат</div>;
  }

  return (
    <div className="chat-window">
      <ChatHeader user={selectedUser} onBack={onBack} />
      <div className="message-search">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Поиск сообщений..."
        />
      </div>
      <MessageList chatId={chatId} searchQuery={debouncedSearchQuery} chats={chats} />
      <MessageInput chatId={chatId} />
    </div>
  );
};

export default ChatWindow;