import { useEffect, useState, useContext } from 'react';
import { db, collection, getDocs, query, where } from '../../firebase/firebase';
import { CategoryContext } from '../../App';
import { GlobalFilterContext } from '../../Services/context/GlobalFilterContext';

const usePopups = () => {
    const { dealTypeFilter } = useContext(GlobalFilterContext);
    const { activeCategories } = useContext(CategoryContext);
    const [popups, setPopups] = useState([]);

    useEffect(() => {
        if (!activeCategories || activeCategories.length === 0) {

            setPopups([]);
            return;
        }

        let isMounted = true;

        const fetchPopups = async () => {
            try {
                let popupData = [];
                for (const item of activeCategories) {
                    const dbQ = query(
                        collection(db, item),
                        where("status", "==", "approved"),
                        where("dealType", "in", [null, dealTypeFilter])
                    );

                    const querySnapshot = await getDocs(dbQ);

                    const responseData = querySnapshot.docs
                        .map(doc => ({
                            id: doc.id,
                            categoryType: item,
                            ...doc.data()
                        }))
                        .filter(categoryItem => {
                            const location = categoryItem.location || {};
                            return location.lng && location.lat;
                        });

                    popupData = [...popupData, ...responseData];

                }

                if (isMounted) {
                    setPopups((prevPopups) => {
                        if (JSON.stringify(prevPopups) === JSON.stringify(popupData)) {

                            return prevPopups;
                        }
                        return popupData;
                    });
                }
            } catch (error) {
                if (isMounted) {

                }
            }
        };

        fetchPopups();

        return () => {
            isMounted = false;
        };
    }, [activeCategories, dealTypeFilter]);

    return popups;
};

export default usePopups;
