import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    unreadCount: 0,
    notifications: [],
  },
  reducers: {
    addNotification(state, action) {
      state.notifications.push(action.payload);
      state.unreadCount += 1;
    },
    clearNotification(state, action) {
      state.notifications = state.notifications.filter(n => n.id !== action.payload);
      state.unreadCount = Math.max(0, state.unreadCount - 1);
    },
    resetNotifications(state) {
      state.notifications = [];
      state.unreadCount = 0;
    },
  },
});

export const { addNotification, clearNotification, resetNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;