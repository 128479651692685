import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase'; // Adjust the path to your Firebase config
import CustomDatePicker from '../СarDetailCard/CustomDatePicker/CustomDatePicker';
import './CarDetailCard.css';
import { LocalizationContext } from "../../../Services/localization/LocalizationContext";
import BackButton from '../../button/BackBotton/BackButton'; // Исправлен путь и название папки
import LikeComponent from "../../LikeComponent/LikeComponent";
import ShareComponent from "../../ShareComponent/ShareComponent";


const CarDetailCard = () => {
  const { carId } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullscreenIndex, setFullscreenIndex] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [daysCount, setDaysCount] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const galleryRef = useRef(null);
  const { translations } = useContext(LocalizationContext);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const carDoc = doc(db, 'cars', carId);
        const carSnapshot = await getDoc(carDoc);
        if (carSnapshot.exists()) {
          setCar({ id: carSnapshot.id, ...carSnapshot.data() });
        } else {
          console.error('Car not found');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching car:', error);
        setLoading(false);
      }
    };
    fetchCar();
  }, [carId]);

  const handleNativeShare = () => {
    const shareData = {
      title: `${car.brand} ${car.model}`,
      text: `Check out this ${car.brand} ${car.model} on our site!`,
      url: window.location.href,
    };
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const shareToTwitter = () => {
    const text = `Check out this ${car.brand} ${car.model}!`;
    const url = window.location.href;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`, '_blank');
  };

  const shareToFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  const shareToWhatsApp = () => {
    const text = `Check out this ${car.brand} ${car.model}: ${window.location.href}`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
  };

  const shareToTelegram = () => {
    const text = `Check out this ${car.brand} ${car.model}: ${window.location.href}`;
    window.open(`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(text)}`, '_blank');
  };

  const openFullscreen = (index) => {
    setFullscreenIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenIndex(null);
  };

  const handlePrevImage = () => {
    const imageUrls = [car.imageUrl, ...(car.imageUrls || [])];
    setFullscreenIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
    setMainImageIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    const imageUrls = [car.imageUrl, ...(car.imageUrls || [])];
    setFullscreenIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
    setMainImageIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDaysCountChange = (count) => {
    setDaysCount(count);
  };

  const handleSwipeStart = (e) => {
    const touch = e.touches ? e.touches[0] : e;
    galleryRef.current.startX = touch.clientX;
  };

  const handleSwipeMove = (e) => {
    if (!galleryRef.current.startX) return;
    const touch = e.touches ? e.touches[0] : e;
    const diffX = galleryRef.current.startX - touch.clientX;
    if (Math.abs(diffX) > 50) {
      const imageUrls = [car.imageUrl, ...(car.imageUrls || [])];
      if (diffX > 0) {
        setMainImageIndex((prev) => (prev === imageUrls.length - 1 ? 0 : prev + 1));
      } else {
        setMainImageIndex((prev) => (prev === 0 ? imageUrls.length - 1 : prev - 1));
      }
      galleryRef.current.startX = null;
    }
  };

  const handleSwipeEnd = () => {
    galleryRef.current.startX = null;
  };

  if (loading) {
    return <div className="cdc-loading">Loading...</div>;
  }

  if (!car) {
    return <div className="cdc-not-found">Car not found</div>;
  }

  const imageUrls = [car.imageUrl, ...(car.imageUrls || [])];

  return (

    <div className="cdc-app-container">

      <main className="cdc-main">
        <BackButton translations={translations} navigate={navigate} />
        <div className="cdc-gallery-container">
          <h1 className="cdc-title">{car.brand} {car.model}</h1>
          <div
            className="cdc-main-image-container"
            ref={galleryRef}
            onMouseDown={handleSwipeStart}
            onMouseMove={handleSwipeMove}
            onMouseUp={handleSwipeEnd}
            onTouchStart={handleSwipeStart}
            onTouchMove={handleSwipeMove}
            onTouchEnd={handleSwipeEnd}
          >
            <img
              src={imageUrls[mainImageIndex]}
              alt={`${car.brand} main image`}
              className="cdc-main-image"
              onClick={() => openFullscreen(mainImageIndex)}
            />
            <div className={'LikesButton'}>
              <LikeComponent objectId={car.id} like_collection='cars'/>
            </div>
          </div>
          <div className="cdc-thumbnail-carousel">
            {imageUrls.map((url, index) => (
              <div
                key={index}
                className={`cdc-thumbnail-item ${index === mainImageIndex ? 'active' : ''}`}
                onClick={() => setMainImageIndex(index)}
              >
                <img
                  src={url}
                  alt={`${car.brand} thumbnail ${index}`}
                  className="cdc-thumbnail-img"
                />
              </div>
            ))}
          </div>
        </div>
        <section className="cdc-details">
          <ShareComponent title={translations.share || 'Share'} />
          <h2>{translations.carDetail.vehicleDetails}</h2>
          <div className="cdc-info-p">
            <p><strong>{translations.carDetail.company}:</strong> {car.company}</p>
            <p><strong>{translations.carDetail.brand}:</strong> {car.brand}</p>
            <p><strong>{translations.carDetail.model}:</strong> {car.model}</p>
            <p><strong>{translations.carDetail.carClass}:</strong> {car.carClass}</p>
            <p><strong>{translations.carDetail.volume}:</strong> {car.volume}L</p>
            <p><strong>{translations.carDetail.transmission}:</strong> {car.transmission}</p>
            <p><strong>{translations.carDetail.fuel}:</strong> {car.fuel}</p>
            <p><strong>{translations.carDetail.fuelConsumption}:</strong> {car.fuelConsumption} L/100km</p>
            <p><strong>{translations.carDetail.year}:</strong> {car.year}</p>
          </div>
          <div className="vehicle-description">
            <h2>{translations.carDetail.description}</h2>
            <p>{car.description || translations.carDetail.noDescriptionAvailable}</p>
          </div>
          <div className="cdc-info-p2">
            <p><strong>{translations.carDetail.deposit}:</strong> {car.deposit || translations.carDetail.no}</p>
            <p><strong>{translations.carDetail.priceOneDay}:</strong> ${car.price1day}</p>
            <p><strong>{translations.carDetail.priceThreeDay}:</strong> ${car.price2to3days}</p>
            <p><strong>{translations.carDetail.priceFourSevenDay}:</strong> ${car.price4to7days}</p>
            <p><strong>{translations.carDetail.priceEightFifteenDay}:</strong> ${car.price8to15days}</p>
            <p><strong>{translations.carDetail.priceThirtyDay}:</strong> ${car.price31days}</p>
            {car.priceForSelling && (
              <p><strong>Price for Selling:</strong> ${car.priceForSelling}</p>
            )}
          </div>
          <div className="cdc-booking-section">
            <h2>{translations.carDetail.bookCar}</h2>
            <CustomDatePicker
              onDatesChange={handleDatesChange}
              startDate={startDate}
              endDate={endDate}
              onDaysCountChange={handleDaysCountChange}
              price1day={car.price1day}
              price2to3days={car.price2to3days || car.price1day}
              price4to7days={car.price4to7days || car.price1day}
              price8to15days={car.price8to15days || car.price1day}
              price16to30days={car.price16to30days || car.price1day}
              price31plusdays={car.price31plusdays || car.price1day}
              objectID={car.id}
              carName={car.brand}
              carModel={car.model}
              carCompany={car.company}
              priceForSelling={car.priceForSelling}
            />
          </div>
        </section>
      </main>
      {fullscreenIndex !== null && (
        <div className="cdc-fullscreen-overlay" onClick={closeFullscreen}>
          <button
            className="cdc-fullscreen-arrow cdc-fullscreen-arrow-left"
            onClick={(e) => { e.stopPropagation(); handlePrevImage(); }}
          >
            ‹
          </button>
          <img
            src={imageUrls[fullscreenIndex]}
            alt="Fullscreen view"
            className="cdc-fullscreen-img"
          />
          <button
            className="cdc-fullscreen-arrow cdc-fullscreen-arrow-right"
            onClick={(e) => { e.stopPropagation(); handleNextImage(); }}
          >
            ›
          </button>
          <button className="cdc-fullscreen-close-btn" onClick={closeFullscreen}>
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default CarDetailCard;