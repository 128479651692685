import { useState } from 'react'; // Добавляем импорт useState
import { NavLink, useNavigate } from 'react-router-dom';
import './NavBar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { mapIcon } from '../img/mapIcon';
import { homeIcon } from '../img/homeIcon';
import { categoryIcon } from '../img/categoryIcon';
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { useAuth } from "../../Services/context/AuthContext";
import { useSelector } from 'react-redux';

const NavBar = () => {
  const { currentUser, logout } = useAuth();
  const unreadCount = useSelector((state) => state.notifications.unreadCount);
  const [activeButton, setActiveButton] = useState('home');
  const navigate = useNavigate();

  const handleProfileClick = () => {
    setActiveButton('profile');
  };

  const handleLogout = () => {
    logout().then(() => {
      console.log("NavBar: Пользователь вышел из системы");
      navigate('/login');
    });
  };

  return (
    <div className="nav-bar">
      <NavLink
        to="/map"
        className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
        onClick={() => setActiveButton('map')}
      >
        <span dangerouslySetInnerHTML={{ __html: mapIcon }} />
      </NavLink>
      <NavLink
        to="/chat"
        className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
        onClick={() => setActiveButton('chat')}
      >
        <svg
          className="chat-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 11.5a8.38 8.38 0 0 1-12.5 7.5L3 21l2-5.5a8.5 8.5 0 1 1 16-4z"></path>
        </svg>
        {unreadCount > 0 && (
          <span className="unread-indicator">{unreadCount}</span>
        )}
      </NavLink>
      <NavLink
        to="/home"
        className={({ isActive }) => `nav-button ${isActive ? 'active' : ''} center`}
        onClick={() => setActiveButton('home')}
      >
        <span dangerouslySetInnerHTML={{ __html: homeIcon }} />
      </NavLink>
      <NavLink
        to="/product-list-cars"
        className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
        onClick={() => setActiveButton('category')}
      >
        <span dangerouslySetInnerHTML={{ __html: categoryIcon }} />
      </NavLink>
      <NavLink
        to={currentUser ? "/profile" : "/login"}
        className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
        onClick={currentUser ? handleProfileClick : undefined}
      >
        {currentUser ? (
          <ProfileIcon onClick={handleProfileClick} />
        ) : (
          <span className="login-icon">Войти</span>
        )}
      </NavLink>

    </div>
  );
};

export default NavBar;