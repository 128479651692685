import { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot, collection, setDoc, Timestamp } from 'firebase/firestore';
import LikeComponent from '../../LikeComponent/LikeComponent';
import './css/FullScreenImage.css';
import './css/Comments.css';

const FullscreenImage = ({ selectedImage, closeImage, images, currentIndex, setCurrentIndex, userId, setSelectedImage, collectionType }) => {
  const [comment, setComment] = useState('');
  const [likes, setLikes] = useState([]);
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState({});
  const [showComments, setShowComments] = useState(false);
  const [showLikers, setShowLikers] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [showEmojiGrid, setShowEmojiGrid] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [collapsedReplies, setCollapsedReplies] = useState({});

  const emojiGrid = ['😊', '😂', '❤️', '👍', '😍', '😢', '😡', '✨', '🎉', '🔥'];
  const previewCommentsLimit = 3;

  useEffect(() => {
    const imageDocRef = doc(db, "users", userId, collectionType === 'avatars' ? "avatars" : "media", `${currentIndex}`);

    const initializeDoc = async () => {
      try {
        await setDoc(imageDocRef, { likes: [], comments: [] }, { merge: true });
      } catch (error) {
        console.error("Error initializing document:", error);
      }
    };

    const unsubscribe = onSnapshot(imageDocRef, (snap) => {
      if (snap.exists()) {
        setLikes(snap.data().likes || []);
        setComments(snap.data().comments || []);
      } else {
        initializeDoc();
      }
    }, (error) => {
      console.error("Error fetching image data:", error);
      initializeDoc();
    });

    const usersRef = collection(db, "users");
    const unsubscribeUsers = onSnapshot(usersRef, (snap) => {
      const usersData = {};
      snap.docs.forEach(doc => {
        usersData[doc.id] = { name: doc.data().name || 'Unknown', avatar: doc.data().avatar || 'https://placehold.co/24x24' };
      });
      setUsers(usersData);
    });

    return () => {
      unsubscribe();
      unsubscribeUsers();
    };
  }, [userId, currentIndex, collectionType]);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (distance > minSwipeDistance && currentIndex < images.length - 1) {
      handleNextImage();
    } else if (distance < -minSwipeDistance && currentIndex > 0) {
      handlePrevImage();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const handleNextImage = () => {
    if (currentIndex < images.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      setSelectedImage(images[nextIndex].url || images[nextIndex]); // Учитываем структуру объекта media
    }
  };

  const handlePrevImage = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      setSelectedImage(images[prevIndex].url || images[prevIndex]); // Учитываем структуру объекта media
    }
  };

  const handleDoubleTap = () => {
    const imageDocRef = doc(db, "users", userId, collectionType === 'avatars' ? "avatars" : "media", `${currentIndex}`);
    const hasLiked = likes.includes(auth.currentUser?.uid);
    if (hasLiked) {
      updateDoc(imageDocRef, { likes: arrayRemove(auth.currentUser.uid) });
    } else {
      updateDoc(imageDocRef, { likes: arrayUnion(auth.currentUser.uid) });
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim() || !auth.currentUser) return;

    const newComment = {
      text: comment,
      author: auth.currentUser.uid,
      createdAt: Timestamp.fromDate(new Date()),
      replyTo: replyTo ? replyTo.id : null,
      likes: [],
      id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
    };

    if (!newComment.text || !newComment.author || !newComment.createdAt || !newComment.id) {
      console.error("Invalid comment data:", newComment);
      return;
    }

    const imageDocRef = doc(db, "users", userId, collectionType === 'avatars' ? "avatars" : "media", `${currentIndex}`);
    try {
      await updateDoc(imageDocRef, { comments: arrayUnion(newComment) });
    } catch (error) {
      console.error("Error adding comment:", error);
      try {
        await setDoc(imageDocRef, { likes: likes || [], comments: [newComment] }, { merge: true });
      } catch (setError) {
        console.error("Error setting document:", setError);
      }
    }
    setComment('');
    setReplyTo(null);
    setShowEmojiGrid(false);
  };

  const handleReply = (comment) => {
    setReplyTo(comment);
    setShowComments(true);
  };

  const handleEmojiSelect = (emoji) => {
    setComment(comment + emoji);
    setShowEmojiGrid(false);
  };

  const handleCommentLike = async (commentIndex) => {
    const imageDocRef = doc(db, "users", userId, collectionType === 'avatars' ? "avatars" : "media", `${currentIndex}`);
    const updatedComments = [...comments];
    const comment = updatedComments[commentIndex];
    const hasLiked = comment.likes?.includes(auth.currentUser.uid);

    if (hasLiked) {
      comment.likes = comment.likes.filter(uid => uid !== auth.currentUser.uid);
    } else {
      comment.likes = comment.likes ? [...comment.likes, auth.currentUser.uid] : [auth.currentUser.uid];
    }

    try {
      await updateDoc(imageDocRef, { comments: updatedComments });
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const toggleComments = () => setShowComments(!showComments);
  const toggleLikers = () => setShowLikers(!showLikers);
  const toggleReplies = (commentId) => {
    setCollapsedReplies(prev => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const renderComments = (parentId = null, level = 0, isPreview = false) => {
    const filteredComments = comments.filter(c => c.replyTo === parentId);
    const commentsToShow = isPreview ? filteredComments.slice(0, previewCommentsLimit) : filteredComments;

    return commentsToShow.map((c, index) => (
      <div key={c.id || index} className="fullscreen-comment" style={{ marginLeft: `${level * 20}px` }}>
        <img src={users[c.author]?.avatar || 'https://placehold.co/24x24'} alt="Avatar" className="comment-avatar" onClick={() => window.location.href = `/profile/${c.author}`} />
        <div>
          <p onClick={() => window.location.href = `/profile/${c.author}`}>
            <strong>{users[c.author]?.name || c.author}</strong>: {c.text}
          </p>
          <span>{new Date(c.createdAt.toDate()).toLocaleString()}</span>
          {c.replyTo && (
            <p className="reply-to">В ответ на @{users[comments.find(cmt => cmt.id === c.replyTo)?.author]?.name || comments.find(cmt => cmt.id === c.replyTo)?.author}</p>
          )}
          <div className="comment-actions">
            <button onClick={() => handleReply(c)}>Ответить</button>
            <button onClick={() => handleCommentLike(comments.indexOf(c))} className={c.likes?.includes(auth.currentUser?.uid) ? 'liked' : ''}>
              ♥ {c.likes?.length || 0}
            </button>
            {comments.some(reply => reply.replyTo === c.id) && !isPreview && (
              <button onClick={() => toggleReplies(c.id)}>
                {collapsedReplies[c.id] ? 'Показать ответы' : 'Скрыть ответы'}
              </button>
            )}
          </div>
          {!collapsedReplies[c.id] && !isPreview && renderComments(c.id, level + 1)}
        </div>
      </div>
    ));
  };

  return (
    <div className="fullscreen-image" onClick={closeImage}>
      <button className="close-button" onClick={closeImage}>✕</button>
      {!showComments ? (
        <>
          <button
            className="prev-button"
            onClick={(e) => { e.stopPropagation(); handlePrevImage(); }}
            disabled={currentIndex === 0}
          >
            ❮
          </button>
          <img
            src={selectedImage}
            alt="Fullscreen"
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={handleDoubleTap}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          />
          <button
            className="next-button"
            onClick={(e) => { e.stopPropagation(); handleNextImage(); }}
            disabled={currentIndex === images.length - 1 || !images[currentIndex + 1]}
          >
            ❯
          </button>
          <div className="fullscreen-like" onClick={(e) => e.stopPropagation()}>
            <LikeComponent objectId={`${currentIndex}`} like_collection={`users/${userId}/${collectionType}`} />
          </div>
          <div className="fullscreen-actions" onClick={(e) => e.stopPropagation()}>
            <span className="likes-count" onClick={toggleLikers}>{likes.length} лайков</span>
            <span className="comments-count" onClick={toggleComments}>
              Комментарии ({comments.length})
            </span>
            {comments.length > 0 && (
              <div className="comment-preview">
                {renderComments(null, 0, true)}
              </div>
            )}
          </div>
          {showLikers && (
            <div className="fullscreen-likers" onClick={(e) => e.stopPropagation()}>
              <h3>Лайкнули ({likes.length})</h3>
              <div className="likers-list">
                {likes.map((uid, index) => (
                  <div key={index} className="liker-item" onClick={() => window.location.href = `/profile/${uid}`}>
                    <img src={users[uid]?.avatar || 'https://placehold.co/24x24'} alt="Avatar" className="liker-avatar" />
                    <span>{users[uid]?.name || uid}</span>
                  </div>
                ))}
              </div>
              <button className="close-likers" onClick={toggleLikers}>✕</button>
            </div>
          )}
        </>
      ) : (
        <div className="fullscreen-comments-container active" onClick={(e) => e.stopPropagation()}>
          <button className="back-button" onClick={toggleComments}>← Назад</button>
          <div className="fullscreen-comments">
            {renderComments()}
          </div>
          <form onSubmit={handleCommentSubmit} className="comment-form">
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={replyTo ? `Ответить @${users[replyTo.author]?.name || replyTo.author}` : "Комментарий..."}
            />
            <button type="button" onClick={() => setShowEmojiGrid(!showEmojiGrid)}>😊</button>
            <button type="submit">Отправить</button>
          </form>
          {showEmojiGrid && (
            <div className="emoji-grid">
              {emojiGrid.map((emoji, index) => (
                <span key={index} onClick={() => handleEmojiSelect(emoji)}>{emoji}</span>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FullscreenImage;