import React, {useContext, useEffect, useState} from 'react';
import DatePicker from '../../ProductListFlat/DatePicker/DatePickerFlat';
import Slider from 'react-slick'; // Импортируем слайдер
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../ProductListCars/DatePicker/DatePicker.css';
import './FlatCard.css';
import rooms from "../../ProductListFlat/ProductListCarsImg/rooms.svg";
import floor from "../../ProductListFlat/ProductListCarsImg/floor.svg";
import bedroom from "../../ProductListFlat/ProductListCarsImg/bedrooms.svg";
import bathrooms from "../../ProductListFlat/ProductListCarsImg/bathrooms.svg";
import {LocalizationContext} from "../../../Services/localization/LocalizationContext";
import view from "../../ProductListFlat/ProductListCarsImg/view.svg";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../firebase/firebase";

// Настройки для слайдера
const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};


const renderFeatureIconUp = (feature, featureLabel, imgSrc, altText, openCarId, carId) => {
    const activeStyle = { filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)' };

    return (
        <div className="Bluetooth">
            <img
                src={imgSrc}
                style={activeStyle}
                alt={altText}
            />
            {/*<div className="bluetooth-label">{featureLabel}</div>*/}
        </div>
    );
};


const OrderFlat = ({ flat ,currentUser}) => {
    const { translations } = useContext(LocalizationContext);
    const [isOpenCard, setIsOpenCard] = useState(false);
    const flatCardClasses = `flat-card ${isOpenCard ? 'card-opened': ''}`;
    const [orders, setOrders] = useState([]);
    const fetchOrders = async () => {
        const ordersCollection = collection(db, "orders");
        const ordersQuery = query(
            ordersCollection,
            where("objectId", "==", flat.id),       // ID машины
            where("userId", "==", currentUser.uid) // Используем только uid пользователя
        );

        const querySnapshot = await getDocs(ordersQuery);
        const fetchedOrders = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        setOrders(fetchedOrders);
    };
    useEffect(() => {
        if (isOpenCard) {
            fetchOrders();
        }
    }, [isOpenCard]);
    const formatDate = (dateString) => {
        if (!dateString) return "Invalid date";

        // Добавляем текущий год к строке "MM/DD"
        const currentYear = new Date().getFullYear();
        const fullDateString = `${dateString}/${currentYear}`;

        // Создаем объект Date
        const date = new Date(fullDateString);

        // Проверяем корректность объекта Date
        if (isNaN(date)) return "Invalid date";

        // Форматируем в локальном стиле (или укажи нужный формат)
        return date.toLocaleDateString(); // Например, "01/17/2025"
    };





    return (
        <div key={flat.id} className={flatCardClasses}>
            <div className="company-name-overlay">
                <p>{flat.company}</p>
            </div>
            {/* Проверяем наличие imageUrls и то, открыт ли календарь */}

                <Slider {...sliderSettings}>
                    {/* Главное изображение */}
                    <div>
                        <img
                            src={flat.imageUrl}
                            alt={`${flat.city} ${flat.description}`}
                            className="flat-image"
                            loading={"lazy"}
                        />
                    </div>
                    {/* Дополнительные изображения */}
                    {flat.imageUrls.map((url, index) => (
                        <div key={index}>
                            <img
                                src={url}
                                alt={`${flat.city} image ${index}`}
                                className="flat-image"
                                loading={"lazy"}
                            />
                        </div>
                    ))}
                </Slider>


            <div className="flat-card-details">
                <div className={'disFlex brandvolume flatCard-title-block'}>
                    <h3 className="right"> {flat.propertyType} в {flat.city}</h3>
                    <p>{flat.dealType}</p>
                </div>
                <div className="flat-details">
                    <div className="LeftRight">

                    </div>

                    {!isOpenCard && (
                        <div className="disFlex elementsIcons IconGrid IconGridFlats">
                            <div className={'disGrid flat-icon-item'}>
                                {renderFeatureIconUp(flat.rooms, '', rooms, "Rooms", isOpenCard)}
                                <span className="fuelUnit">{flat.rooms}</span>
                            </div>

                            <div className={'disGrid flat-icon-item'}>
                                {renderFeatureIconUp(flat.floor, '', floor, "Floor", isOpenCard)}
                                <span className="fuelUnit">{flat.floor}</span>
                            </div>

                            <div className={'disGrid flat-icon-item'}>
                                {renderFeatureIconUp(flat.bedrooms, flat.bedrooms, bedroom, "Bedrooms", isOpenCard)}
                                <span className="fuelUnit">{flat.bedrooms}</span>
                            </div>

                            <div className={'disGrid flat-icon-item'}>
                                {renderFeatureIconUp(flat.bathrooms, '', bathrooms, "Bathrooms", isOpenCard)}
                                <span className="fuelUnit">{flat.bathrooms}</span>
                            </div>

                            <div className={'disGrid flat-icon-item'}>
                                {renderFeatureIconUp(flat.view, '', view, "View", isOpenCard)}
                                <span className="fuelUnit">{flat.view}</span>
                            </div>


                        </div>
                    )}
                </div>
            </div>
            {isOpenCard && (
                <div className="order-history">
                    <h4>Orders:</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            {/* Column for numbering */}
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Order Date</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map((order, index) => (
                            <tr key={order.id}>
                                <td>{index + 1}</td>
                                {/* Row number */}
                                <td>{formatDate(order.startDate)}</td>
                                <td>{formatDate(order.endDate)}</td>
                                <td>{formatDate(order.orderDate)}</td>
                                <td>{order.orderStatus}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
            )}
            <div className="price-details">
                <div className="price-item">
                    {/* Price details if needed */}
                </div>
            </div>

            <div className={'button-container'}>
                <button
                    className={`details-button ${isOpenCard ? 'collapsed' : ''}`}
                    onClick={() => {
                        setIsOpenCard(!isOpenCard);
                    }}
                >
                    {!isOpenCard && (
                        <div className="flat-detail">
                            {flat.priceForSelling ? (
                                // Если задана цена для продажи, отображаем только ее
                                <div>{flat.priceForSelling}$</div>
                            ) : (
                                // Если цена для продажи отсутствует, отображаем аренду
                                <div>From {flat.pricePerDay}$</div>
                            )}
                        </div>
                    )}

                    {/* Добавляем черту separator для всех случаев (продажа и аренда) */}
                    {!isOpenCard && (
                        <span className="separator"> | </span> /* Черта отображается для продажи и аренды */
                    )}

                    <div className="flat-detail">
                        <div>
                            {isOpenCard ? (
                                <span className="arrow-up">
                        <svg
                            className="custom-arrow"
                            width="32"
                            height="10"
                            viewBox="0 0 32 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                                stroke="currentColor"
                                strokeOpacity="1"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </span>
                            ) : (
                                translations.productListCars.liveView
                            )}
                        </div>
                    </div>
                </button>
            </div>

            {/* Черта separator отображается всегда */}
            {!isOpenCard && (
                <div className="date-icon">
                    <svg
                        className="custom-arrow-down"
                        width="32"
                        height="10"
                        viewBox="0 0 32 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M31 7L17.8974 2.63246C16.6658 2.22192 15.3342 2.22192 14.1026 2.63246L1 7"
                            stroke="currentColor"
                            strokeOpacity="1"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            )}


        </div>
    );

};

export default OrderFlat;