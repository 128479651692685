// src/Services/context/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("AuthProvider: Инициализация состояния авторизации");
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("AuthProvider: Состояние пользователя:", user);
      setCurrentUser(user);
      setLoading(false);
    }, (error) => {
      console.error("AuthProvider: Ошибка в onAuthStateChanged:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    return auth.signOut().then(() => {
      console.log("AuthProvider: Выход из системы выполнен");
      setCurrentUser(null); // Принудительно обновляем состояние
    }).catch((error) => {
      console.error("AuthProvider: Ошибка при выходе:", error);
    });
  };

  return (
    <AuthContext.Provider value={{ currentUser, loading, logout }}>
      {loading ? <div>Загрузка...</div> : children}
    </AuthContext.Provider>
  );
};