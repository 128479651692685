import React, {useState, useEffect, useContext} from 'react';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase';
import './LikesPage.css';
import FlatCard from '../Cards/FlatCard/FlatCard';
import CarCard from '../Cards/CarCard/CarCard';
import {LocalizationContext} from "../../Services/localization/LocalizationContext";
import {useNavigate} from "react-router-dom";
import BackButton from "../button/BackBotton/BackButton";

const LikesPage = () => {
    const [likedFlats, setLikedFlats] = useState([]);
    const [likedCars, setLikedCars] = useState([]);
     const { translations } = useContext(LocalizationContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const {transactions} = useContext(LocalizationContext);
    useEffect(() => {
        const fetchLikedItems = async () => {
            try {
                setLoading(true);
                setError(null);

                const user = auth.currentUser;
                if (!user) throw new Error('User is not authenticated');

                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (!userDoc.exists()) throw new Error('User data not found');

                const likes = userDoc.data().likes || [];
                if (likes.length === 0) {
                    setLoading(false);
                    return;
                }

                // Фильтруем ID для безопасности (Firebase поддерживает максимум 10 элементов в 'in')
                const chunkSize = 10;
                const chunkedLikes = [];
                for (let i = 0; i < likes.length; i += chunkSize) {
                    chunkedLikes.push(likes.slice(i, i + chunkSize));
                }

                const fetchData = async (collectionName, likesArray) => {
                    const results = [];
                    for (const likesChunk of likesArray) {
                        const q = query(collection(db, collectionName), where('__name__', 'in', likesChunk));
                        const snapshot = await getDocs(q);
                        results.push(...snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                    }
                    return results;
                };

                const [cars, flats] = await Promise.all([
                    fetchData('cars', chunkedLikes),
                    fetchData('flats', chunkedLikes),
                ]);

                setLikedCars(cars);
                setLikedFlats(flats);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLikedItems();
    }, []);

    return (
        <div className="likes-list-component">

            <div className="likes-title">Your likes here</div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <div className="likes-list">
                    {likedCars.length === 0 && likedFlats.length === 0 ? (
                        <p>No liked items yet.</p>
                    ) : (
                        <>
                            {likedCars.map(car => car && <CarCard key={car.id} car={car}/>)}
                            {likedFlats.map(flat => flat && <FlatCard key={flat.id} flat={flat}/>)}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default LikesPage;
