import { useState, useEffect } from "react";
import { db } from '../../../firebase/firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../css/ChatHeader.css";

const ChatHeader = ({ user, onBack }) => {
  const [isOnline, setIsOnline] = useState(false);
  const [grokSettings, setGrokSettings] = useState({ tone: "friendly" });
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.uid) {
      setIsOnline(false);
      return;
    }

    const userRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setIsOnline(docSnapshot.data().online || false);
      } else {
        setIsOnline(false);
      }
    }, (error) => {
      console.error("Ошибка загрузки статуса пользователя:", error);
      setIsOnline(false);
    });

    return () => unsubscribe();
  }, [user]);

  const handleToneChange = (e) => {
    setGrokSettings({ ...grokSettings, tone: e.target.value });
  };

  const handleAvatarClick = () => {
    if (user && user.uid) {
      navigate(`/profile/${user.uid}`);
    }
  };

  if (!user || !user.uid) {
    return <div className="chat-header">Выберите чат</div>;
  }

  return (
    <div className="chat-header">
      <div className="chat-header-left" onClick={handleAvatarClick} style={{ cursor: "pointer" }}>
        <img
          src={user.avatar || "https://via.placeholder.com/40"}
          alt={user.name || "User"}
          className="avatar"
        />
        <div>
          <h2>{user.name || "Неизвестный пользователь"}</h2>
          <span className={`status ${isOnline ? 'online' : 'offline'}`}>
            {isOnline ? "онлайн" : "оффлайн"}
          </span>
        </div>
      </div>
      {user.uid === "WeeekendAsistans" && (
        <select value={grokSettings.tone} onChange={handleToneChange}>
          <option value="friendly">Дружелюбный</option>
          <option value="formal">Формальный</option>
          <option value="humorous">Юмористический</option>
        </select>
      )}
      <button onClick={onBack}>Назад</button>
    </div>
  );
};

export default ChatHeader;