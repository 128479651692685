import { useState } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebase/firebase';
import LikeComponent from '../../LikeComponent/LikeComponent';
import './css/UserProfileMedia.css';

const ProfileMedia = ({ auth, userId, media, newMediaFiles, handleFileChange, handleMediaUpload, openImage }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleDeleteMedia = async (mediaId) => {
    if (!auth.currentUser || auth.currentUser.uid !== userId) return;
    const mediaDocRef = doc(db, "users", userId, "media", mediaId);
    try {
      await deleteDoc(mediaDocRef);
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  return (
    <div className="user-profile-media">
      <div className="media-header">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21 15L16 10L5 21" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        {auth.currentUser?.uid === userId && (
          <button className="edit-media-btn" onClick={() => setIsEditing(!isEditing)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M18.5 2.5C18.8978 2.10217 19.4374 1.87868 20 1.87868C20.5626 1.87868 21.1022 2.10217 21.5 2.5C21.8978 2.89783 22.1213 3.43739 22.1213 4C22.1213 4.56261 21.8978 5.10217 21.5 5.5L12 15L8 16L9 12L18.5 2.5Z" stroke="var(--text-color-primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        )}
      </div>
      {auth.currentUser?.uid === userId && (
        <div className="media-upload">
          <input type="file" accept="image/*" multiple onChange={handleFileChange} />
          <button onClick={handleMediaUpload} disabled={!newMediaFiles.length}>Загрузить</button>
        </div>
      )}
      <div className="media-grid">
        {media.map((item, index) => (
          <div key={item.id} className="media-item-container">
            <img
              src={item.url}
              alt="Media"
              className="media-item"
              onClick={() => openImage(item.url, index, media, 'media')}
            />
            <div className="media-likes">
              <LikeComponent objectId={item.id} like_collection={`users/${userId}/media`} />
            </div>
            {isEditing && auth.currentUser?.uid === userId && (
              <button className="delete-media-btn" onClick={() => handleDeleteMedia(item.id)}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 6H5H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            )}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileMedia;