import './css/UserProfileBase.css';

const FollowModal = ({ showSubscribers, subscribers, subscriptions, closeModal, navigate }) => {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{showSubscribers ? 'Подписчики' : 'Подписки'}</h2>
        <div className="follow-list">
          {(showSubscribers ? subscribers : subscriptions).map((user, index) => (
            <div key={index} className="follow-item" onClick={() => navigate(`/profile/${showSubscribers ? user.uid : user}`)}>
              <img src={user.avatar || 'https://placehold.co/50x50'} alt="User" className="follow-avatar" />
              <span>{user.name || user}</span>
            </div>
          ))}
        </div>
        <button className="modal-close-btn" onClick={closeModal}>✕</button>
      </div>
    </div>
  );
};

export default FollowModal;