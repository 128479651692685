import './css/UserProfileBase.css';

const ProfileForm = ({ formData, handleChange, handleSave, handleEditToggle }) => {
  return (
    <div className="user-profile-form">
      <input name="name" value={formData.name || ''} onChange={handleChange} placeholder="Имя" />
      <input name="status" value={formData.status || ''} onChange={handleChange} placeholder="Статус" />
      <textarea name="bio" value={formData.bio || ''} onChange={handleChange} placeholder="О себе" />
      <div className="form-actions">
        <button onClick={handleSave}>✓</button>
        <button onClick={handleEditToggle}>✕</button>
      </div>
    </div>
  );
};

export default ProfileForm;